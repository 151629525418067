import { format } from "date-fns/format";
import { fr, enUS, es, it, nl, de } from "date-fns/locale";
import { t } from "i18next";

import { ConnectorPlug, Currency } from "../../operations";

import { getTimePortion } from "./calc";
import { getCurrentLanguage } from "./locale";
import { round } from "./round";

const locales = { de, en: enUS, es, fr, it, nl };
const currentLanguage = getCurrentLanguage();
const locale = locales[currentLanguage];

const DURATIONS = [1800, 2700, 3600, 4500, 5400, 6300, 7200, 8100, 9000, 9900, 10800, 11700, 12600, 13500, 14400];

export const getDurations = () =>
  DURATIONS.map((duration) => ({ label: formatDuration(duration), value: duration.toString() }));

export function formatCurrency({
  value,
  currency = Currency.Eur,
  fractionDigits,
}: {
  currency?: Currency;
  fractionDigits?: number;
  value: number;
}) {
  return t(`format.currency.${currency}`, {
    formatParams: {
      val:
        fractionDigits !== undefined
          ? { maximumFractionDigits: fractionDigits }
          : { maximumFractionDigits: 2, minimumFractionDigits: 2 },
    },
    ns: "common",
    val: value / 100,
  });
}

export function formatEnergy(value: number, maximumFractionDigits = 1) {
  return t("format.energy.kilowatts", {
    energy: value / 1000,
    formatParams: { energy: { maximumFractionDigits } },
    ns: "common",
  });
}

export function formatPower(value: number, maximumFractionDigits = 0) {
  return t("format.power.kilowatts", {
    formatParams: { power: { maximumFractionDigits } },
    ns: "common",
    power: value / 1000,
  });
}

export function formatPercent(value: number, maximumFractionDigits = 1) {
  return t("format.percent.base", {
    formatParams: { val: { maximumFractionDigits } },
    ns: "common",
    val: value,
  });
}

export function formatDuration(duration: number, formatType = "m 'min'"): string {
  const { hours, minutes, seconds } = getTimePortion(duration);
  const type = hours ? "H'h'mm" : formatType;
  return format(new Date(2021, 1, 1, hours, minutes, seconds), type, { locale });
}

export function formatConnectorPlugLabel(plug: ConnectorPlug): string {
  if (Object.values(ConnectorPlug).includes(plug)) {
    return t(`connector.${plug}`, { ns: "common" });
  } else return t(`connector.unknown`, { ns: "common" });
}

export function removeSpaces(value: string | undefined): string | undefined {
  if (!value) return value;
  return value.replace(/\s+/g, "");
}

export const formatDistance = (distance: number) => {
  if (distance < 50) {
    return t("format.distance.nearestStation", { ns: "common" });
  }

  if (distance < 1000) {
    return t("format.distance.meters", {
      distance: Math.round(distance / 50) * 50,
      formatParams: { distance: { maximumFractionDigits: 0 } },
      ns: "common",
    });
  }

  return t("format.distance.kilometers", {
    distance: distance / 1000,
    formatParams: { distance: { maximumFractionDigits: distance > 10000 ? 0 : 1 } },
    ns: "common",
  });
};

export const formatConnectorSubLabel = (plugs: ConnectorPlug[], usableBatteryCapacity: number): string => {
  let subLabel = t("format.energy.kilowatts", {
    energy: usableBatteryCapacity / 1000,
    formatParams: { energy: { maximumFractionDigits: 0 } },
    ns: "common",
  });
  let formattedPlugs = "";
  for (const plug of plugs) {
    if (formattedPlugs.length > 0) {
      formattedPlugs += ", ";
    }
    formattedPlugs += formatConnectorPlugLabel(plug);
  }

  if (formattedPlugs.length > 0) {
    subLabel += ` · ${formattedPlugs}`;
  }

  return subLabel;
};

export function formatNumberWithSpaces(number: number) {
  const numStr = Math.floor(number).toString();

  let integerPart = numStr.split(".")[0];
  const decimalPart = numStr.split(".")[1];

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

  return formattedNumber;
}

export function formatNumber(value: number, fractionDigitsBelowOne = 1) {
  if (value < 0) return 0;
  if (value === 0) return 0;

  const fractionDigits = value < 1 ? fractionDigitsBelowOne : 0;
  return round(value, fractionDigits);
}
