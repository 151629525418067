import { Footer, FullPage } from "components";
import { useBasePath, useMixpanel } from "hooks";
import { initMixpanel } from "libs";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { version } from "utils";

const ANONYMOUS_PAGES = ["/unsubscribe"];
const POSSIBLE_LANDING_PAGES = ["/webviews/referral", "/welcome", "/badge", "/invoice"];
const LIGHT_FOOTER_PAGES = ["/user-info/confirm-email", "/webviews/referral", "/welcome", "/badge", "/invoice"];

const Layout = () => {
  const basePath = useBasePath() || "";

  const anonymousPage = ANONYMOUS_PAGES.includes(basePath);
  const landingPage = POSSIBLE_LANDING_PAGES.includes(basePath);
  const withLightFooter = LIGHT_FOOTER_PAGES.includes(basePath);

  const setMixpanel = useMixpanel((state) => state.setMixpanel);
  const mixpanelTokenFromEnv = import.meta.env.VITE_MIXPANEL_TOKEN;
  const isDev = import.meta.env.DEV;

  useEffect(() => {
    const mixpanel = initMixpanel(mixpanelTokenFromEnv, "electra-webapp", isDev);
    setMixpanel(mixpanel, { app_version: version, product_name: "webapp-b2c" });
  }, [setMixpanel, mixpanelTokenFromEnv, isDev]);

  if (anonymousPage) {
    return (
      <div className="md:bg-main absolute inset-0 flex flex-col md:bg-cover">
        <main className="flex max-h-screen min-h-full flex-col overflow-y-auto">
          <Outlet />
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <>
      {!landingPage && <div className="md:bg-main absolute inset-0 md:bg-cover md:opacity-[.24] md:blur-[125px]"></div>}
      <FullPage withLightFooter={withLightFooter}>
        <Outlet />
      </FullPage>
    </>
  );
};

export default Layout;
