import classNames from "classnames";
import BusinessLogo from "images/business-logo.svg?react";
import { FunctionComponent, ReactElement, ReactNode } from "react";

interface Props {
  business?: boolean;
  children?: never;
  className?: string;
  debug?: boolean;
  floating?: boolean;
  left?: ReactNode;
  right?: ReactNode;
  secondary?: ReactNode;
  showMain?: boolean;
  stretchContentOnLargeScreen?: boolean;
  title?: string | ReactElement | undefined;
  transparent?: boolean;
}

const CenteredNavbar: FunctionComponent<Props> = ({
  className,
  debug = false,
  floating = false,
  left,
  right,
  secondary,
  showMain = true,
  stretchContentOnLargeScreen = false,
  title,
  transparent = false,
  business = false,
}) => {
  return (
    <nav
      className={classNames(
        "pt-0-safe-fix w-full select-none text-neutral-900 md:pt-4 lg:pt-8",
        { "bg-white": !transparent, shadow: floating },
        className
      )}
    >
      <div
        className={classNames("px-4-safe flex h-11", {
          "items-center justify-between": business,
          "items-stretch gap-2": !business,
          "md:max-w-screen-lg": !stretchContentOnLargeScreen,
        })}
      >
        <div
          className={classNames("flex items-center justify-start gap-4", {
            "bg-blue-100": debug,
            "flex-1 lg:hidden": business || !left,
          })}
        >
          {left}
        </div>

        <div
          className={classNames(
            "flex flex-initial flex-col items-start justify-center overflow-hidden transition-opacity",
            {
              "bg-blue-100": debug,
              "opacity-0": !showMain,
              "text-business-sm": secondary,
              "text-lg": !secondary,
            }
          )}
        >
          {!!business ? (
            <BusinessLogo className="h-11 text-neutral-700" />
          ) : (
            <div className="truncate font-medium">{title}</div>
          )}

          {secondary ?? null}
        </div>

        <div
          className={classNames("flex flex-1 items-center justify-end gap-4", {
            "bg-blue-100": debug,
          })}
        >
          {right}
        </div>
      </div>
    </nav>
  );
};

export default CenteredNavbar;
