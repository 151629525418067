import classNames from "classnames";
import { useField } from "formik";
import {
  ChangeEventHandler,
  ComponentProps,
  forwardRef,
  PointerEventHandler,
  ReactNode,
  useCallback,
  useMemo,
} from "react";

import FieldError from "../../atoms/FieldError";
import Slider from "../../atoms/Slider";

import { FieldWrapperProps } from "./FieldWrapper";

export interface Props extends FieldWrapperProps {
  children?: never;
  desiredValue?: number;
  labelAfterValue?: string | undefined;
  labelBeforeValue?: string | undefined;
  sliderProps?: ComponentProps<typeof Slider>;
  suffix?:
    | {
        after: ReactNode;
        beforeOrEqual: ReactNode;
      }
    | undefined;
  validate?: (value: boolean) => string | undefined;
}

const SliderPercentField = forwardRef<HTMLInputElement, Props>(
  ({ className, desiredValue = 80, sliderProps, name, suffix, validate }, ref) => {
    const [field, { error }, { setValue }] = useField<number>({ name, validate });

    const handlePointerDown: PointerEventHandler<HTMLElement> = useCallback((event) => {
      if (event) {
        event.stopPropagation();
      }
    }, []);

    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        if (!event) return;

        setValue(Number.parseFloat(event.target.value));
      },
      [setValue]
    );

    const computedSuffix = useMemo(() => {
      if (!suffix) return undefined;

      if (field.value <= desiredValue) return suffix.beforeOrEqual;

      return suffix.after;
    }, [desiredValue, field.value, suffix]);

    return (
      <div className={className}>
        <div className="flex flex-row font-normal items-baseline justify-between text-neutral-600">
          <div className="flex flex-row gap-1 items-baseline">
            <div className="font-light text-xl">{field.value}%</div>
            <div className="text-label-sm">{computedSuffix}</div>
          </div>
          <div>100%</div>
        </div>
        <div className="h-4 leading-none relative">
          <div
            className={classNames("absolute border-0 h-2 outline-none rounded-full w-2", {
              "bg-neutral-300": field.value < 80,
              "bg-neutral-900": field.value >= 80,
            })}
            style={{ left: `calc(${desiredValue}% - 1rem / 2 - 1px)`, top: `calc(1rem / 4 + 1px)` }}
          />
          <Slider
            className="absolute top-2"
            ref={ref}
            value={field.value}
            onChange={handleChange}
            onPointerDown={handlePointerDown}
            {...sliderProps}
          />
        </div>
        {error ? <FieldError name={name} /> : null}
      </div>
    );
  }
);

export default SliderPercentField;
