import { notifyWarningOnBugsnag } from "@webapps/shared/libs/src/bugsnag";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";
import { getUserErrorMessageAndTitle } from "utils";

export const useErrorHandling = (error?: Error) => {
  const { t } = useTranslation();
  const routeError = useRouteError();
  const errorToUse = error || routeError;
  const { message, title } = getUserErrorMessageAndTitle(errorToUse);
  const errorMessage = message
    ? t("api.errorWithMessage", { error: message, ns: "common" })
    : t("api.occuredError", { ns: "common" });

  useEffect(() => {
    notifyWarningOnBugsnag(errorToUse);
  }, [errorToUse]);

  return { errorMessage, title };
};
