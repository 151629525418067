import * as Factory from "factory.ts";
import { Station, StationStatus } from "operations";

export const stationFactory = Factory.Sync.makeFactory<Pick<Station, "__typename" | "id" | "name" | "status">>({
  __typename: "Station",
  id: "fake-station-id",
  name: "Paris - Mortier",
  status: StationStatus.Opened,
});

export const stationFactoryWithAnonymousOccupied = Factory.Sync.makeFactory<
  Pick<Station, "__typename" | "id" | "name" | "status">
>({
  __typename: "Station",
  id: "fake-station-id-with-anonymous-occupied",
  name: "Les Ulis - Hôtel Mercure",
  status: StationStatus.Opened,
});

export const stationFactoryClosed = Factory.Sync.makeFactory<Pick<Station, "__typename" | "id" | "name" | "status">>({
  __typename: "Station",
  id: "fake-closed-station-id",
  name: "Bordeaux - Novotel Bordeaux Lac",
  status: StationStatus.Closed,
});
