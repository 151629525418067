import classNames from "classnames";
import { BottomSheetWrapper, Header, CenteredNavbar } from "components";
import { ComponentProps, FunctionComponent, ReactNode, useEffect, useRef, useState } from "react";

export type Props = Pick<ComponentProps<typeof Header>, "size" | "subTitle"> &
  Pick<ComponentProps<typeof CenteredNavbar>, "title"> & {
    bottom?: ReactNode;
    bottomFloating?: boolean;
    bottomSheet?: ReactNode;
    bottomWidthLimitation?: boolean;
    children?: ReactNode;
    navLeft?: ComponentProps<typeof CenteredNavbar>["left"];
    navRight?: ComponentProps<typeof CenteredNavbar>["right"];
    rawContent?: boolean;
    showHeader?: boolean;
    showNavbar?: boolean;
    verticalScroll?: boolean;
  };

const CenteredPage: FunctionComponent<Props> = ({
  bottom,
  bottomFloating,
  bottomWidthLimitation = true,
  children,
  rawContent = false,
  size,
  subTitle,
  title,
  verticalScroll = true,
  navLeft,
  navRight,
  bottomSheet,
  showHeader = true,
  showNavbar = true,
}) => {
  const headerElementRef = useRef<HTMLDivElement | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const rootElementRef = useRef<HTMLDivElement | null>(null);

  const [bottomFloatingOnScroll, setBottomFloatingOnScroll] = useState(false);

  useEffect(() => {
    const headerElement = headerElementRef.current;
    const rootElement = rootElementRef.current;
    const lastElement = lastElementRef.current;

    if (!rootElement || !(headerElement && lastElement)) return;

    const options = {
      root: rootElement,
      rootMargin: "0px",
      threshold: [0],
    };

    const observer = new IntersectionObserver((entries, _observer) => {
      entries.forEach((entry) => {
        if (lastElement && entry.target === lastElement) {
          setBottomFloatingOnScroll(!entry.isIntersecting);
        }
      });
    }, options);

    if (headerElement) observer.observe(headerElement);
    if (lastElement) observer.observe(lastElement);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div className="absolute inset-0 flex flex-col pt-4 lg:left-1/2 lg:top-0 lg:max-w-2xl lg:-translate-x-1/2 lg:transform lg:pt-0">
        {showNavbar && (
          <CenteredNavbar
            className={classNames("z-20 flex-none")}
            left={navLeft}
            right={navRight}
            showMain={true}
            business
          />
        )}
        <div
          className={classNames("flex flex-1 flex-col", {
            "overflow-y-auto": verticalScroll,
            "overflow-y-hidden": !verticalScroll,
          })}
        >
          {showHeader && (
            <div className={classNames("flex-none bg-white pt-4 lg:pt-6 xl:z-0")}>
              <Header size={size} subTitle={subTitle} title={title as string} fontWeight="light" />
            </div>
          )}

          <div
            className={classNames("md:pb-17 relative flex flex-1 flex-col overflow-x-hidden bg-white", {
              "pb-2": !rawContent && bottom,
              "pb-2-safe": !rawContent && !bottom,
              "px-4-safe pt-2": !rawContent,
            })}
          >
            {children}
          </div>
        </div>

        {!!bottom && (
          <div
            className={classNames("px-4-safe pb-4-safe md:mb-15 flex flex-none flex-col bg-white pt-4", {
              "-shadow-md z-10": bottomFloating || bottomFloatingOnScroll,
            })}
          >
            <div
              className={classNames("flex w-full flex-col gap-4 self-center", {
                "max-w-sm": bottomWidthLimitation,
              })}
            >
              {bottom}
            </div>
          </div>
        )}
      </div>
      <BottomSheetWrapper sheet={bottomSheet} />
    </>
  );
};

export default CenteredPage;
