import classNames from "classnames";
import { ReactNode } from "react";

import { Footer } from "../../organisms";

const FullPage = ({
  children,
  withLightFooter,
  className,
}: {
  children: ReactNode;
  className?: string;
  withLightFooter: boolean;
}) => {
  return (
    <div className="absolute inset-0 flex flex-col">
      <div className="flex max-h-screen min-h-full flex-col overflow-y-auto">
        <main className={classNames("flex flex-grow flex-col", className)}>{children}</main>
        <Footer lightMode={withLightFooter} />
      </div>
    </div>
  );
};

export default FullPage;
