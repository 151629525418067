import * as Factory from "factory.ts";
import { RemoteChargePage_RemoteChargeFragment } from "operations";

import { MOCK_USER } from "../constants";

import { remoteChargeConnectorFactory } from "./connectorFactory";
import { actionStepFactory, chargingStepFactory, completedStepFactory, waitStepFactory } from "./stepFactory";

const charge = Factory.Sync.makeFactory<{ __typename: "Charge"; id: string }>({
  __typename: "Charge",
  id: MOCK_USER.chargeId,
});

export const remoteChargeFactoryActionStep = Factory.Sync.makeFactory<RemoteChargePage_RemoteChargeFragment>({
  __typename: "RemoteCharge",
  cards: [],
  charge: charge.build(),
  connector: remoteChargeConnectorFactory.build(),
  id: MOCK_USER.remoteChargeId,
  step: actionStepFactory.build(),
});

export const remoteChargeFactoryChargingStep = Factory.Sync.makeFactory<RemoteChargePage_RemoteChargeFragment>({
  __typename: "RemoteCharge",
  cards: [],
  charge: charge.build(),
  connector: remoteChargeConnectorFactory.build(),
  id: MOCK_USER.remoteChargeId,
  step: chargingStepFactory.build(),
});

export const remoteChargeFactoryCompletedStep = Factory.Sync.makeFactory<RemoteChargePage_RemoteChargeFragment>({
  __typename: "RemoteCharge",
  cards: [],
  charge: charge.build(),
  connector: remoteChargeConnectorFactory.build(),
  id: MOCK_USER.remoteChargeId,
  step: completedStepFactory.build(),
});

export const remoteChargeFactoryWaitStep = Factory.Sync.makeFactory<RemoteChargePage_RemoteChargeFragment>({
  __typename: "RemoteCharge",
  cards: [],
  charge: charge.build(),
  connector: remoteChargeConnectorFactory.build(),
  id: MOCK_USER.remoteChargeId,
  step: waitStepFactory.build(),
});
