import chargePointPageHandlers from "../pages/ChargePointPage/handlers";
import checkCardPageHandlers from "../pages/CheckCardPage/handlers";
import paymentMethodPageHandlers from "../pages/PaymentMethodPage/handlers";
import remoteChargePageHandlers from "../pages/RemoteChargePage/handlers";
import supportPageHandlers from "../pages/SupportPage/handlers";
import unsubscribePageHandlers from "../pages/UnsubscribePage/handlers";

const allHandlers = [
  chargePointPageHandlers,
  checkCardPageHandlers,
  paymentMethodPageHandlers,
  remoteChargePageHandlers,
  supportPageHandlers,
  unsubscribePageHandlers,
];

export const appHandlers = allHandlers.map((handler) => handler.base).flat();
export const resumeSessionWithSessionStorageHandlers = [
  chargePointPageHandlers,
  checkCardPageHandlers,
  paymentMethodPageHandlers,
  remoteChargePageHandlers,
]
  .map((handler) => handler.resumeSessionWithSessionStorage)
  .flat();

export const resumeSessionWithoutSessionStorageHandlers = [
  chargePointPageHandlers,
  checkCardPageHandlers,
  paymentMethodPageHandlers,
  remoteChargePageHandlers,
]
  .map((handler) => handler.resumeSessionWithoutSessionStorage)
  .flat();
