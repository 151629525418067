import { supportSectionsFactory } from "@webapps/shared/components/mocks/factories/supportSectionsFactory";
import { SupportPageQuery, SupportPageQueryVariables } from "operations";
import { getQueryErrorHandler, getQueryLoadingHandler, handleAuthenticatedMockedOperation } from "utils";

const base = [
  handleAuthenticatedMockedOperation<SupportPageQuery, SupportPageQueryVariables>("SupportSections", {
    supportSections: supportSectionsFactory,
  }),
];

const supportPageHandlers = {
  base,
  error: [getQueryErrorHandler<SupportPageQuery, SupportPageQueryVariables>("SupportSections")],
  loading: [getQueryLoadingHandler<SupportPageQuery, SupportPageQueryVariables>("SupportSections")],
  resumeSessionWithSessionStorage: base,
  resumeSessionWithoutSessionStorage: base,
};

export default supportPageHandlers;
