import { PageName, ButtonName } from "@webapps/shared/libs/src/mixpanel";
import { differenceInMilliseconds } from "date-fns/differenceInMilliseconds";
import { Dict, Mixpanel } from "mixpanel-browser";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type MixpanelProductName = "webapp-b2c" | "webapp-b2b" | "webapp-partners" | "webapp-webviews";
type StaticProperties = { app_version: string; product_name?: MixpanelProductName };
type DynamicProperties = { language: string; session_token: string | null };
export type MixpanelProperties = Dict & DynamicProperties;
const isStorybook = import.meta.env.STORYBOOK;

interface MixpanelState {
  lastPageViewTracked: { date: Date; pageName: string } | undefined;
  mixpanel: Mixpanel | undefined;
  setMixpanel: (mixpanel: Mixpanel, staticProperties: StaticProperties) => void;
  staticProperties: StaticProperties | undefined;
  trackButtonClicked: (buttonName: ButtonName, properties: MixpanelProperties | undefined) => void;
  trackPageView: (pageName: PageName, properties: MixpanelProperties | undefined) => void;
}

export const useMixpanel = create<MixpanelState>()(
  devtools((set, get) => ({
    lastPageViewTracked: undefined,
    mixpanel: undefined,
    setMixpanel: (mixpanel, staticProperties) => {
      if (isStorybook) return;
      set(() => ({ mixpanel, staticProperties }));
    },
    staticProperties: undefined,
    trackButtonClicked: (buttonName: ButtonName, properties: MixpanelProperties | undefined) => {
      if (isStorybook) return;
      const { mixpanel, staticProperties } = get();

      mixpanel?.track("button_clicked", {
        button_name: buttonName,
        ...staticProperties,
        ...properties,
      });
    },
    trackPageView: (pageName: PageName, properties: MixpanelProperties | undefined) => {
      if (isStorybook) return;
      const { lastPageViewTracked, mixpanel, staticProperties } = get();

      if (!mixpanel) return;

      if (lastPageViewTracked?.pageName === pageName) {
        const diff = differenceInMilliseconds(new Date(), lastPageViewTracked.date);
        if (diff <= 500) return;
      }

      set(() => ({ lastPageViewTracked: { date: new Date(), pageName } }));

      mixpanel.track("page_viewed", {
        page_name: pageName,
        ...staticProperties,
        ...properties,
      });
    },
  }))
);
