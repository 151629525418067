import { useState, useCallback, useLayoutEffect } from "react";

export interface Dimensions {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}

export function getDimensionObject<T extends HTMLElement = HTMLDivElement>(node: T): Dimensions {
  const rect = node.getBoundingClientRect();

  return {
    bottom: rect.bottom,
    height: rect.height,
    left: "y" in rect ? rect.y : (rect as DOMRect).left,
    right: rect.right,
    top: "x" in rect ? rect.x : (rect as DOMRect).top,
    width: rect.width,
    x: "x" in rect ? rect.x : (rect as DOMRect).left,
    y: "y" in rect ? rect.y : (rect as DOMRect).top,
  };
}

type Props =
  | {
      defaults?: Partial<Dimensions>;
    }
  | undefined;

type ReturnValue<T extends HTMLElement = HTMLDivElement> = [(node: T) => void, Dimensions];

export function useDimensions<T extends HTMLElement = HTMLDivElement>({ defaults = {} }: Props = {}): ReturnValue<T> {
  const [dimensions, setDimensions] = useState<Dimensions>({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
    ...defaults,
  });
  const [node, setNode] = useState<T>();

  const ref = useCallback((node: T) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (!node) {
      return;
    }

    const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject<T>(node)));
    measure();

    window.addEventListener("resize", measure);
    return () => {
      window.removeEventListener("resize", measure);
    };
  }, [node]);

  return [ref, dimensions];
}
