import { ApolloProvider } from "@apollo/client";
import React, { Suspense } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";

import Layout from "../components/templates/Layout";
import useClient from "../hooks/useClient";
import ErrorPage from "../pages/ErrorPage";

const App = ({ withDefaultRoute = false }: { withDefaultRoute?: boolean }) => {
  const client = useClient();
  const HomePage = React.lazy(() => import("../pages/HomePage"));
  const AnonymousAccessPage = React.lazy(() => import("../pages/AnonymousAccessPage"));
  const PaymentMethodPage = React.lazy(() => import("../pages/PaymentMethodPage"));
  const RemoteChargePage = React.lazy(() => import("../pages/RemoteChargePage"));
  const EmailConfirmationPage = React.lazy(() => import("../pages/EmailConfirmationPage"));
  const ChargePointPage = React.lazy(() => import("../pages/ChargePointPage"));
  const CheckCardPage = React.lazy(() => import("../pages/CheckCardPage"));
  const SupportPage = React.lazy(() => import("../pages/SupportPage"));
  const SupportChatPage = React.lazy(() => import("../pages/SupportChatPage"));
  const UnsubscribePage = React.lazy(() => import("../pages/UnsubscribePage"));
  const NotFoundPage = React.lazy(() => import("../pages/NotFoundPage"));
  const ReferralPage = React.lazy(() => import("../pages/ReferralPage"));
  const PartnerWelcomePage = React.lazy(() => import("../pages/PartnerWelcomePage"));
  const BadgePage = React.lazy(() => import("../pages/BadgePage"));
  const Invoice = React.lazy(() => import("../pages/InvoicePage"));

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
        <Route index element={<HomePage />} />
        <Route element={<AnonymousAccessPage />} path="anonymous-access/:accessId" />
        <Route element={<PaymentMethodPage />} path="payment-method" />
        <Route element={<RemoteChargePage />} path="remote-charge" />
        <Route element={<ChargePointPage />} path="qr/:chargePointPublicCode" />
        <Route element={<CheckCardPage />} path="check-card/:connectorId" />
        <Route element={<SupportPage />} path="support" />
        <Route element={<SupportChatPage />} path="support/chat" />
        <Route element={<UnsubscribePage />} path="unsubscribe" />
        <Route element={<EmailConfirmationPage />} path="user-info/confirm-email/:confirmationToken" />
        <Route path="webviews">
          <Route element={<ReferralPage />} path="referral" />
        </Route>
        <Route element={<PartnerWelcomePage />} path="welcome/:partnerKey" />
        <Route element={<BadgePage />} path="badge" />
        <Route element={<Invoice />} path="invoice/" />
        <Route
          path="*"
          element={
            withDefaultRoute ? <Navigate to="/qr/FR*ELC*CABC2" state={{ playAnimation: false }} /> : <NotFoundPage />
          }
        />
      </Route>
    )
  );

  return (
    <Suspense>
      <ApolloProvider client={client}>
        <RouterProvider router={router} />
      </ApolloProvider>
    </Suspense>
  );
};

export default App;
