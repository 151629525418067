import { Button, CenteredPage, Page } from "components";
import { FunctionComponent, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  business?: boolean;
  canReloadPage?: boolean;
  centered?: boolean;
  children?: never;
  error: string;
  navLeft?: ReactNode;
  textColor?: string;
  title?: string;
}

const ErrorScreen: FunctionComponent<Props> = ({
  canReloadPage = false,
  error,
  navLeft,
  title,
  textColor = "text-red-600",
  centered = false,
  business = false,
}) => {
  const { t } = useTranslation();
  const bottom = canReloadPage ? (
    <Button
      onClick={() => window.location.reload()}
      kind="primary"
      label={t("errorScreen.cta", { ns: "common" })}
      size="medium"
    />
  ) : null;
  const PageComponent = centered ? CenteredPage : Page;
  const commonProps = {
    bottom,
    navLeft,
    showNavbar: business || centered,
    title: title ?? t("errorScreen.pageTitle", { ns: "common" }),
  };

  const props = centered ? { ...commonProps, showLogo: false } : { ...commonProps, business };
  const content = <div className={`${textColor}`}>{error}</div>;
  return <PageComponent {...props}>{content}</PageComponent>;
};

export default ErrorScreen;
