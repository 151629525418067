import classNames from "classnames";
import { FunctionComponent, ReactElement, SVGProps, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import ArrowRightIcon from "../../../images/icons/ri/arrow-right.svg?react";
import LoaderIcon from "../../../images/icons/ri/loading.svg?react";

interface Props {
  children?: ReactElement;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
  kind?: "primary" | "negative" | "secondary" | "tertiary";
  label?: string | JSX.Element;
  loading?: boolean;
  next?: boolean;
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  testId?: string;
  trailingIcon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
  type?: "button" | "submit" | "reset";
}

const Button: FunctionComponent<Props> = ({
  className,
  disabled,
  fullWidth = true,
  href,
  icon: Icon,
  kind = "primary",
  label,
  loading = false,
  next = false,
  onClick,
  size = "large",
  type,
  testId,
  trailingIcon: TrailingIcon,
  children,
}) => {
  const navigate = useNavigate();
  const [touching, setTouching] = useState(false);

  const onButtonClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else if (href) {
      navigate(href);
    }
  }, [navigate, href, onClick]);

  const onTouchStart = useCallback(() => setTouching(true), []);
  const onTouchEnd = useCallback(() => setTouching(false), []);

  const visuallyDisabled = disabled && !loading;
  const loadingOrVisuallyDisabledState = visuallyDisabled || loading;
  const defaultState = !touching && !visuallyDisabled && !loading;
  const touchingState = touching && !visuallyDisabled && !loading;

  const trailingIcon = useMemo(() => {
    if (!next && !TrailingIcon) return;

    return (
      <span className="pointer-events-none flex flex-1 items-center justify-end">
        {next && (
          <ArrowRightIcon className={classNames({ "h-4 w-4": size === "small", "h-6 w-6": size !== "small" })} />
        )}
        {!!TrailingIcon && !next && (
          <TrailingIcon className={classNames({ "h-4 w-4": size === "small", "h-6 w-6": size !== "small" })} />
        )}
      </span>
    );
  }, [TrailingIcon, next, size]);

  return (
    <button
      className={classNames(
        "flex max-w-full select-none items-center justify-center gap-2 overflow-hidden rounded-full p-3 font-medium leading-none transition-all",
        {
          "bg-neutral-100 text-neutral-300": loadingOrVisuallyDisabledState && kind === "primary",
          "bg-neutral-white border border-neutral-100 text-neutral-600": defaultState && kind === "secondary",
          "bg-neutral-white border border-neutral-700 text-neutral-700": touchingState && kind === "secondary",
          "bg-white text-neutral-300": loadingOrVisuallyDisabledState && kind === "tertiary",
          "bg-white text-neutral-600": defaultState && kind === "tertiary",
          "bg-white text-neutral-700": touchingState && kind === "tertiary",
          "border border-neutral-100 bg-white text-neutral-100": loadingOrVisuallyDisabledState && kind === "secondary",
          "border border-neutral-100 bg-white text-red-500": defaultState && kind === "negative",
          "border border-neutral-700 bg-neutral-700 text-white ": touchingState && kind === "primary",
          "border border-red-300 bg-white text-red-300": loadingOrVisuallyDisabledState && kind === "negative",
          "border border-red-600 bg-white text-red-600": touchingState && kind === "negative",
          "cursor-not-allowed": visuallyDisabled,
          "cursor-pointer": !visuallyDisabled,
          "text-base-light h-12": size === "medium",
          "text-base-light h-14": size === "large",
          "text-brand-600 border border-neutral-900 bg-neutral-700": defaultState && kind === "primary",
          "text-label-sm h-8": size === "small",
          "w-full": fullWidth && (size === "medium" || size === "large"),
        },
        className
      )}
      disabled={disabled}
      onClick={onButtonClick}
      type={type}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      data-testid={testId}
    >
      {loading ? (
        <LoaderIcon className="h-6 w-6 animate-spin" />
      ) : (
        <>
          {next && <span className="pointer-events-none -ml-2 flex-1" />}
          {!!Icon && (
            <Icon
              className={classNames("pointer-events-none flex-none", {
                "h-4 w-4": size === "small",
                "h-6 w-6": size !== "small",
              })}
            />
          )}
          {!!label && <span className="pointer-events-none flex-shrink">{label}</span>}
          {trailingIcon}
          {children}
        </>
      )}
    </button>
  );
};

export default Button;
