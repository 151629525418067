import { useField } from "formik";
import { ComponentProps, forwardRef } from "react";

import Select, { SelectItem } from "../Select";

import FieldWrapper, { FieldWrapperProps } from "./FieldWrapper";

interface Props extends FieldWrapperProps {
  children?: never;
  includeBlank?: boolean;
  items: SelectItem[];
  selectProps?: Omit<ComponentProps<typeof Select>, "items">;
  validate?: (value: string) => string | undefined;
}

const SelectField = forwardRef<HTMLSelectElement, Props>(
  ({ className, includeBlank, items, label, name, selectProps, validate }, ref) => {
    const [field, { error }] = useField({ name, validate });

    return (
      <FieldWrapper className={className} label={label} name={name}>
        <Select
          ref={ref}
          includeBlank={includeBlank}
          items={items}
          state={error ? "invalid" : "none"}
          {...field}
          {...selectProps}
        />
      </FieldWrapper>
    );
  }
);

export default SelectField;
