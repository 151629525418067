import { useField } from "formik";
import { ComponentProps, forwardRef, useCallback } from "react";

import Checkbox, { CheckboxAction } from "../../atoms/Checkbox";
import FieldError from "../../atoms/FieldError";
import FieldLabel from "../../atoms/FieldLabel";

import { FieldWrapperProps } from "./FieldWrapper";

export interface Props extends FieldWrapperProps {
  checkboxProps?: Omit<ComponentProps<typeof Checkbox>, "label" | "onClick">;
  children?: never;
  validate?: (value: boolean) => string | undefined;
}

const CheckboxField = forwardRef<HTMLInputElement, Props>(
  ({ className, checkboxProps, label, name, validate }, ref) => {
    const [field, { error }, { setValue }] = useField<boolean>({ name, validate });

    const handleClick = useCallback(() => {
      setValue(!field.value);
    }, [setValue, field.value]);

    return (
      <div className={className}>
        <label className="flex gap-2 items-start">
          <CheckboxAction ref={ref} checked={field.value} onClick={handleClick} {...checkboxProps} />
          {label ? <FieldLabel className="flex-1" size="medium" label={label} /> : null}
        </label>
        {error ? <FieldError name={name} /> : null}
      </div>
    );
  }
);

export default CheckboxField;
