import classNames from "classnames";
import { ChangeEventHandler, forwardRef, InputHTMLAttributes, MouseEventHandler, useCallback } from "react";

import LoaderIcon from "../../../images/icons/ri/loading.svg?react";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  children?: never;
  size?: "small" | "medium" | "large";
}

const RadioButton = forwardRef<HTMLInputElement, Props>(
  ({ checked = false, className, disabled = false, size = "large", ...rest }, ref) => {
    return (
      <input
        ref={ref}
        checked={checked}
        className={classNames(
          "form-radio cursor-pointer select-none border-2 border-neutral-600 text-neutral-900 checked:border-0 focus:shadow-none focus:ring-0 focus:ring-offset-0 disabled:cursor-auto disabled:border-neutral-300 disabled:text-neutral-600",
          {
            "h-4 w-4": size === "small",
            "w-4.5 h-4.5": size === "medium",
            "w-5.5 h-5.5": size === "large",
          },
          className
        )}
        disabled={disabled}
        type="radio"
        {...rest}
      ></input>
    );
  }
);

export default RadioButton;

interface RadioButtonActionProps extends Omit<Props, "onClick"> {
  loading?: boolean;
  onClick?: (value: string) => void;
}

export const RadioButtonAction = forwardRef<HTMLInputElement, RadioButtonActionProps>(
  ({ checked = false, disabled = false, loading = false, onClick, ...rest }, ref) => {
    const handleClick: MouseEventHandler<HTMLInputElement> = useCallback((event) => {
      if (event) {
        // Stop propagation to avoid twice changes of value
        // (once from listitem and once from radio button)
        event.stopPropagation();
      }
    }, []);

    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        if (onClick) onClick(event.target.value);
      },
      [onClick]
    );

    if (loading) {
      return <LoaderIcon className="h-6 w-6 animate-spin" />;
    }

    return (
      <RadioButton
        ref={ref}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        onClick={handleClick}
        {...rest}
      />
    );
  }
);
