import classNames from "classnames";
import { useField } from "formik";
import { ComponentProps, forwardRef } from "react";

import Input from "../Input";
import Select, { SelectItem } from "../Select";

import FieldWrapper, { FieldWrapperProps } from "./FieldWrapper";

export interface Props extends FieldWrapperProps {
  callingCode?: string;
  children?: never;
  inputProps?: ComponentProps<typeof Input>;
  items: SelectItem[];
  onClear?: () => void;
  prefixName: string;
  selectProps?: Omit<ComponentProps<typeof Select>, "includeBlank" | "items">;
  selectWidth?: string;
  serverError?: boolean;
  validate?: (value: string) => string | undefined;
  variant?: "small" | "medium";
}

const PhoneNumberField = forwardRef<HTMLInputElement, Props>(
  (
    {
      inputProps,
      label,
      callingCode,
      name,
      prefixName,
      validate,
      items,
      onClear,
      selectProps,
      serverError = false,
      variant = "medium",
    },
    ref
  ) => {
    const [field, { error, initialValue, touched }, { setValue }] = useField<string>({ name, validate });
    const [prefixField, { error: prefixError }] = useField<string>({ name: prefixName });

    const handleClear = () => {
      if (onClear) {
        onClear();
      } else setValue((inputProps?.initialValue as string) ?? initialValue ?? "", false);
    };

    return (
      <FieldWrapper label={label} name={name} disabled={inputProps?.disabled} variant={variant}>
        <div className="flex gap-2">
          <div
            className={classNames("flex-none", {
              "w-[100px]": variant === "medium",
              "w-[60px]": variant === "small",
            })}
          >
            <Select
              includeBlank={false}
              items={items}
              state={prefixError ? "invalid" : "none"}
              {...prefixField}
              {...selectProps}
              displayedValue={callingCode}
              variant={variant}
            />
          </div>

          <Input
            ref={ref}
            state={(error && touched) || serverError ? "invalid" : "none"}
            onClear={handleClear}
            {...field}
            {...inputProps}
            serverError={serverError}
            variant={variant}
          />
        </div>
      </FieldWrapper>
    );
  }
);

export default PhoneNumberField;
