import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import { getBugsnagError, version } from "utils";

export const initBugsnag = (apiKey: string, env: string) => {
  const isStorybook = import.meta.env.STORYBOOK;
  if (!apiKey) {
    throw new Error("[Env error] Missing Bugsnag publicKey");
  }
  if (isStorybook) return undefined;

  Bugsnag.start({
    apiKey,
    appVersion: version,
    enabledReleaseStages: ["production", "staging"],
    plugins: [new BugsnagPluginReact()],
    releaseStage: env,
  });
  BugsnagPerformance.start({
    apiKey,
    appVersion: version,
    releaseStage: env,
  });

  return Bugsnag.getPlugin("react")?.createErrorBoundary(React);
};

export const notifyWarningOnBugsnag = (error: unknown, severity?: "info" | "warning" | "error") => {
  const bugsnagError = getBugsnagError(error);
  Bugsnag.notify(bugsnagError, (event) => {
    event.severity = severity ?? "error";
  });
};
