import classNames from "classnames";
import { FunctionComponent, SVGProps, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { noop } from "utils";

import ArrowLeftIcon from "../../../images/icons/ri/arrow-left.svg?react";
import ChevronLeftIcon from "../../../images/icons/ri/chevron-left.svg?react";
import CloseIcon from "../../../images/icons/ri/close.svg?react";
import SupportIcon from "../../../images/icons/ri/help-question-circle.svg?react";
import MenuIcon from "../../../images/icons/ri/menu.svg?react";
import AnchorOrDiv from "../../atoms/AnchorOrDiv";

export type ActionProps = {
  children?: never;
  className?: string;
  debug?: boolean;
  disabled?: boolean;
  horizontalPadding?: "small" | "medium";
  href?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
  iconSize?: "small" | "medium";
  inheritedTextColor?: boolean;
  label?: string;
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  target?: string;
  testId?: string;
  title?: string;
  withBackground?: boolean;
};

const Action: FunctionComponent<ActionProps> = ({
  className,
  debug = false,
  disabled = false,
  horizontalPadding = "small",
  href,
  icon: Icon,
  iconSize = "medium",
  inheritedTextColor = false,
  label,
  onClick: givenOnClick,
  size = "medium",
  title,
  target,
  testId,
  withBackground = true,
}) => {
  const onClick = useMemo(
    () => (disabled ? noop : givenOnClick ? () => givenOnClick() : undefined),
    [disabled, givenOnClick]
  );

  return (
    <AnchorOrDiv
      className={classNames(
        "flex select-none items-center justify-center",
        {
          "bg-red-100 bg-opacity-80": debug,
          "cursor-not-allowed opacity-50": disabled,
          "cursor-pointer": !disabled,
          "text-neutral-700": !inheritedTextColor,
        },
        className
      )}
      href={href}
      onClick={onClick}
      title={title}
      target={target}
      data-testid={testId}
    >
      <span
        className={classNames(
          "inline-flex items-center justify-center gap-1 overflow-hidden transition-opacity hover:opacity-80",
          {
            "h-10 min-w-[2.5rem]": size === "medium",
            "h-12 min-w-[3rem]": size === "large",
            "px-2": horizontalPadding === "small",
            "px-4": horizontalPadding === "medium",
            "rounded-xl border border-white bg-white py-2 shadow-sm": size !== "small" && withBackground,
          }
        )}
      >
        {label ? <span className="text-label-sm font-normal">{label}</span> : null}
        {Icon ? (
          <Icon
            className={classNames({
              "h-4 w-4": iconSize === "small",
              "h-6 w-6": iconSize === "medium",
            })}
          />
        ) : null}
      </span>
    </AnchorOrDiv>
  );
};

export default Action;

export const BackAction: FunctionComponent<Omit<ActionProps, "icon" | "title">> = (props) => {
  const { t } = useTranslation();
  return <Action icon={ChevronLeftIcon} title={t("action.back", { ns: "common" })} {...props} />;
};

export const BackToHomeAction: FunctionComponent<Omit<ActionProps, "href" | "icon" | "onClick" | "title">> = (
  props
) => {
  const { t } = useTranslation();
  return <Action icon={ArrowLeftIcon} href="/" title={t("action.back", { ns: "common" })} {...props} />;
};

export const CloseAction: FunctionComponent<Omit<ActionProps, "icon" | "title">> = (props) => {
  const { t } = useTranslation();
  return <Action icon={CloseIcon} title={t("action.close", { ns: "common" })} {...props} />;
};

export const CloseToHomeAction: FunctionComponent<Omit<ActionProps, "href" | "icon" | "onClick" | "title">> = (
  props
) => {
  const { t } = useTranslation();
  return <Action icon={CloseIcon} href="/" title={t("action.close", { ns: "common" })} {...props} />;
};

export const CloseToBackAction: FunctionComponent<
  Omit<ActionProps, "href" | "icon" | "label" | "onClick" | "title">
> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Action
      icon={ChevronLeftIcon}
      onClick={() => navigate(-1)}
      title={t("action.close", { ns: "common" })}
      {...props}
    />
  );
};

export const MenuAction: FunctionComponent<Omit<ActionProps, "icon" | "title">> = (props) => {
  const { t } = useTranslation();
  return <Action icon={MenuIcon} title={t("action.menu", { ns: "common" })} {...props} />;
};

export const SupportAction: FunctionComponent<Omit<ActionProps, "href" | "icon" | "onClick" | "title">> = (props) => {
  const { t } = useTranslation();
  return (
    <Action
      horizontalPadding="medium"
      label={t("action.help", { ns: "common" })}
      title={t("action.help", { ns: "common" })}
      href="/support"
      {...props}
    />
  );
};

export const ConnectorSupportAction: FunctionComponent<Omit<ActionProps, "href" | "icon" | "onClick" | "title">> = (
  props
) => {
  const { t } = useTranslation();
  return (
    <Action
      icon={SupportIcon}
      label={t("action.connector.label", { ns: "common" })}
      href={t("action.connector.href", { ns: "common" })}
      {...props}
      target="_blank"
    />
  );
};
