import { useMediaQuery } from "@react-hook/media-query";
import classNames from "classnames";
import { animate, AnimatePresence, motion, PanInfo, useMotionValue, ValueAnimationTransition } from "framer-motion";
import { useDimensions, useScreenSize } from "hooks";
import { FunctionComponent, MouseEventHandler, ReactNode, useCallback, useMemo } from "react";

interface Props {
  considersMenuWidth?: boolean;
  onClose?: () => void;
  overAll?: boolean;
  sheet: ReactNode | null;
  showBackdrop?: boolean;
}

const springTransition: ValueAnimationTransition<number> = { damping: 30, mass: 0.2, stiffness: 300, type: "spring" };

const BottomSheetWrapper: FunctionComponent<Props> = ({
  considersMenuWidth = false,
  onClose,
  overAll = false,
  sheet,
  showBackdrop,
}) => {
  const { height: screenHeight, width: screenWidth } = useScreenSize();
  const [ref, { height: containerHeight }] = useDimensions<HTMLDivElement>();
  const mdScreen = useMediaQuery("only screen and (min-width: 768px)");

  const initialY = useMemo(
    () => (containerHeight > 0 ? containerHeight : screenHeight),
    [containerHeight, screenHeight]
  );
  const y = useMotionValue(0);

  const handleDragEnd = useCallback(
    (_: MouseEvent | TouchEvent | PointerEvent, { velocity }: PanInfo) => {
      let yTo = 0;
      if (velocity.y > 50) {
        if (onClose) {
          onClose();
          return;
        } else {
          yTo = initialY;
        }
      }

      animate(y, yTo, springTransition);
    },
    [initialY, onClose, y]
  );

  const handleClickOnSheet: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (!showBackdrop || !event) return;

      const divElement = event.target as HTMLDivElement;
      if (divElement?.id === "bottom-sheet-handle") {
        return;
      }

      event.stopPropagation();
    },
    [showBackdrop]
  );

  const handleClickOnContainer = useCallback(() => {
    if (!showBackdrop || !onClose) return;

    onClose();
  }, [onClose, showBackdrop]);

  const animatedContent = useMemo(() => {
    // If we are on md screen, there is left menu opened which represents w-72
    // w-72 => 72 * 16 (1rem = 16px and w-4 = 1rem)
    const menuWidth = mdScreen && considersMenuWidth ? 72 * 16 : 0;
    const left = (screenWidth - menuWidth) / 2;
    const right = left;

    return (
      <AnimatePresence>
        {sheet ? (
          <motion.div
            key="card"
            ref={ref}
            drag="y"
            dragDirectionLock
            dragElastic={0.5}
            dragConstraints={{ bottom: initialY, top: 0 }}
            dragMomentum={false}
            onClick={handleClickOnSheet}
            onDragEnd={handleDragEnd}
            initial={{ y: initialY }}
            animate={{ y: 0 }}
            transition={{ bounce: 0, duration: 0.2 }}
            exit={{ y: initialY }}
            style={{ left, right, y }}
            className={classNames("absolute bottom-0 flex justify-center", {
              "z-100": overAll,
              "z-20": !overAll,
            })}
          >
            {sheet}
          </motion.div>
        ) : null}
      </AnimatePresence>
    );
  }, [considersMenuWidth, handleClickOnSheet, handleDragEnd, initialY, mdScreen, overAll, ref, screenWidth, sheet, y]);

  if (showBackdrop) {
    return (
      <div
        className={classNames("fixed inset-0 overflow-hidden transition-all", {
          "bg-black bg-opacity-50": sheet,
          "pointer-events-none": !sheet,
          "z-100": overAll,
          "z-30": !overAll,
        })}
        onClick={handleClickOnContainer}
      >
        {animatedContent}
      </div>
    );
  }

  return animatedContent;
};

export default BottomSheetWrapper;
