import * as Factory from "factory.ts";
import {
  ChargePointPage_ChargePointFragment,
  ChargeTypeEnum,
  ConnectorPlug,
  ConnectorPublicAvailability,
} from "operations";

import { stationFactory, stationFactoryClosed, stationFactoryWithAnonymousOccupied } from "./stationFactory";

const spot = (index: number) => ({
  __typename: "Spot" as const,
  id: `0${index}`,
  number: index,
  publicLabel: `Place 0${index}`,
  reducedMobility: false,
});

const connector = (
  chargeType: ChargeTypeEnum,
  index: number,
  plug: ConnectorPlug,
  anonymouslyOccupied: boolean,
  availability: ConnectorPublicAvailability,
  plugShortName: string,
  readyToCharge: boolean,
  maxPowerWatts: number
) => ({
  __typename: "Connector" as const,
  anonymouslyOccupied,
  availability,
  chargeType,
  id: `0${index}`,
  maxPowerWatts,
  number: index,
  plug,
  plugShortName,
  pricingDetail: "0,49 € / kWh",
  publicLabel: spot(index).publicLabel,
  readyToCharge,
  spot: spot(index),
});

const connectors = [
  connector(
    ChargeTypeEnum.FastCharge,
    1,
    ConnectorPlug.ComboCcsEu,
    false,
    ConnectorPublicAvailability.Available,
    "CSS",
    true,
    150000
  ),
  connector(
    ChargeTypeEnum.SlowCharge,
    2,
    ConnectorPlug.Type4,
    false,
    ConnectorPublicAvailability.Reserved,
    "CHadeMO",
    false,
    22000
  ),
  connector(
    ChargeTypeEnum.SlowCharge,
    3,
    ConnectorPlug.Type2,
    false,
    ConnectorPublicAvailability.Available,
    "Type 2",
    true,
    22000
  ),
  connector(
    ChargeTypeEnum.FastCharge,
    4,
    ConnectorPlug.ComboCcsEu,
    false,
    ConnectorPublicAvailability.Unavailable,
    "CSS",
    false,
    120000
  ),
];

const connectorsWithAnonymousOccupied = [
  connector(
    ChargeTypeEnum.FastCharge,
    5,
    ConnectorPlug.ComboCcsEu,
    true,
    ConnectorPublicAvailability.AnonymouslyOccupied,
    "CSS",
    false,
    120000
  ),
  connector(
    ChargeTypeEnum.FastCharge,
    1,
    ConnectorPlug.ComboCcsEu,
    false,
    ConnectorPublicAvailability.Available,
    "CSS",
    true,
    120000
  ),
  connector(
    ChargeTypeEnum.SlowCharge,
    3,
    ConnectorPlug.Type2,
    false,
    ConnectorPublicAvailability.Occupied,
    "Type 2",
    false,
    22000
  ),
  connector(
    ChargeTypeEnum.FastCharge,
    4,
    ConnectorPlug.ComboCcsEu,
    false,
    ConnectorPublicAvailability.Unavailable,
    "CSS",
    false,
    150000
  ),
];

export const chargePointFactory = Factory.Sync.makeFactory<ChargePointPage_ChargePointFragment & { id: string }>({
  __typename: "ChargePoint",
  connectors,
  id: "fake-charge-point",
  station: stationFactory.build(),
});

export const chargePointWithStationClosedFactory = Factory.Sync.makeFactory<
  ChargePointPage_ChargePointFragment & { id: string }
>({
  __typename: "ChargePoint",
  connectors,
  id: "fake-charge-point",
  station: stationFactoryClosed.build(),
});

export const chargePointWithAnonymousOccupiedFactory = Factory.Sync.makeFactory<
  ChargePointPage_ChargePointFragment & { id: string }
>({
  __typename: "ChargePoint",
  connectors: connectorsWithAnonymousOccupied,
  id: "fake-charge-point-with-anonymous-occupied",
  station: stationFactoryWithAnonymousOccupied.build(),
});
