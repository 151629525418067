import classNames from "classnames";
import { FunctionComponent, ReactNode } from "react";

interface Props {
  charactersCount?: number | undefined;
  charactersMaxCount?: number | undefined;
  className?: string;
  disabled?: boolean;
  label: ReactNode | string;
  size?: "medium" | "small";
}

const FieldLabel: FunctionComponent<Props> = ({
  charactersCount,
  charactersMaxCount,
  className,
  disabled = false,
  label,
  size = "medium",
}) => {
  return (
    <div
      className={classNames(
        "flex font-normal justify-between mb-1",
        {
          "text-neutral-300": disabled,
          "text-neutral-600": !disabled,
          "text-sm": size === "medium",
          "text-xs": size === "small",
        },
        className
      )}
    >
      {label}
      {charactersCount !== undefined && charactersMaxCount !== undefined ? (
        <div>{`${charactersCount} / ${charactersMaxCount}`}</div>
      ) : null}
    </div>
  );
};

export default FieldLabel;
