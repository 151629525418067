import { t } from "i18next";
import { isRouteErrorResponse } from "react-router-dom";

// used to get the right message sent to bugsnag
export const getErrorMessage = (error: unknown) => {
  if (isRouteErrorResponse(error)) {
    return (error.data?.message as string) ?? error.statusText;
  }
  if (error instanceof PageError) {
    const pageTitleMessage = error.pageTitle ? ` in ${error.pageTitle}` : "";
    return `Handled Page Error : ${error.message}${pageTitleMessage}`;
  }
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === "string") {
    return error;
  }
  if (!error) {
    return t("api.unknownError", { ns: "common" });
  }
  return JSON.stringify(error);
};

export const getUserErrorMessageAndTitle = (error: unknown) => {
  let title;
  let message;
  // for actions and loaders, we don't use them at the moment
  if (isRouteErrorResponse(error)) {
    message = error.data?.message as string;
  }
  if (error instanceof PageError) {
    message = error.message;
    title = error.pageTitle;
  }
  if (error instanceof Error) {
    message = error.message;
  }
  if (typeof error === "string") {
    message = error;
  }
  return { message, title };
};

// used to enhance errors sent to bugsnag
export const getBugsnagError = (error: unknown) => {
  if (error instanceof Error || error instanceof PageError) {
    return error;
  } else {
    const errorMessage = getErrorMessage(error);
    return new Error(errorMessage);
  }
};

// used in pages to throw errors catched by the ErrorPage
export class PageError extends Error {
  readonly pageTitle: string | undefined;
  constructor({ message, pageTitle }: { message?: string; pageTitle?: string }) {
    super(message);
    this.pageTitle = pageTitle;
    this.message = message ?? t("api.occuredError", { ns: "common" });
  }
}
