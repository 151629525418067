import classNames from "classnames";
import {
  forwardRef,
  FunctionComponent,
  InputHTMLAttributes,
  SVGProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";

export interface SelectItem {
  label?: string;
  value: string;
}

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
  children?: never;
  displayedValue?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
  includeBlank?: boolean;
  items: SelectItem[];
  state?: "none" | "invalid" | "valid";
  value?: string | undefined;
  variant?: "small" | "medium";
}

const Select = forwardRef<HTMLSelectElement, Props>(
  (
    {
      className,
      disabled = false,
      displayedValue,
      icon: Icon,
      includeBlank = false,
      items,
      placeholder,
      state = "none",
      value,
      variant = "medium",
      ...rest
    },
    forwardedRef
  ) => {
    const { t } = useTranslation();
    const isInvalid = state === "invalid";
    const isValid = state === "valid";
    const unknown = useMemo(() => {
      if (value === "" && includeBlank) {
        return false;
      }

      return !items.find(({ value: itemValue }) => itemValue === value);
    }, [includeBlank, items, value]);

    const ref = useRef<HTMLSelectElement>(null);

    /* intercept */
    useEffect(() => {
      if (typeof forwardedRef === "function") {
        forwardedRef(ref.current);
      } else if (forwardedRef) {
        forwardedRef.current = ref.current;
      }
    }, [forwardedRef]);

    const onClick = useCallback(() => {
      const input = ref?.current;
      if (!input || disabled || document.activeElement === input) return;

      input.focus();
    }, [disabled]);

    return (
      <div
        className={classNames(
          "inline-flex w-full max-w-full items-center gap-2 overflow-hidden rounded-sm border font-normal transition-colors",
          {
            "border-neutral-300 bg-white focus-within:border focus-within:border-neutral-700 focus-within:outline focus-within:outline-2 focus-within:outline-neutral-50":
              !disabled && state === "none",
            "border-neutral-300 bg-white focus-within:border-2 focus-within:border-red-500 focus-within:bg-red-100":
              !disabled && isInvalid,
            "border-neutral-300 bg-white focus-within:border-green-500 focus-within:bg-green-100": !disabled && isValid,
            "border-neutral-50 bg-neutral-50 text-neutral-300": disabled,
            "h-14": variant === "medium",
            "h-8": variant === "small",
            "p-2": variant === "small",
            "p-4": variant === "medium",
            "text-base": variant === "medium",
            "text-neutral-900": !disabled,
            "text-sm": variant === "small",
          },
          className
        )}
        onClick={onClick}
      >
        {Icon ? <Icon className="h-6 w-6 flex-none" /> : null}

        <div className="relative max-w-full flex-1">
          {displayedValue && (
            <div className="pointer-events-none absolute flex h-full w-full items-center">{displayedValue}</div>
          )}

          <select
            className={classNames("w-full border-none bg-transparent px-0 text-base transition-colors focus:ring-0", {
              small: variant === "small",
              "text-transparent": !!displayedValue,
            })}
            disabled={disabled}
            ref={ref}
            value={value}
            {...rest}
          >
            {includeBlank ? (
              <option className="text-neutral-300" key="__blank" value="" label={placeholder ?? " "}></option>
            ) : null}

            {items.map(({ label, value }) => (
              <option key={value} value={value}>
                {label ?? value}
              </option>
            ))}

            {unknown ? (
              <option key="__unknown" value={value}>
                {t("unknown.value", { ns: "common" })}
              </option>
            ) : null}
          </select>
        </div>
      </div>
    );
  }
);

export default Select;
