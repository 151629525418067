import * as Factory from "factory.ts";
import {
  CompletedScreen_AnonymousSessionFragment,
  CurrentRemoteChargeFragment,
  InvoiceStatusEnum,
  PaymentMethodPage_AnonymousSessionFragment,
} from "operations";

import { MOCK_USER } from "../constants";

import { paymentMethodPageFactory } from "./paymentMethodFactory";

const paymentMethod = paymentMethodPageFactory.build();

export const paymentMethodAnonymousSessionFactory =
  Factory.Sync.makeFactory<PaymentMethodPage_AnonymousSessionFragment>({
    __typename: "AnonymousSession",
    account: {
      __typename: "Account",
      accountUser: {
        __typename: "AccountUser",
        id: MOCK_USER.accountUserId,
        processoutCustomerRef: "fake-customer-ref",
        processoutTokenToVerify: "fake-processout-token",
      },
      id: MOCK_USER.accountId,
    },
    id: MOCK_USER.anonymousSessionId,
    instantChargeIntent: {
      __typename: "InstantChargeIntent",
      connector: {
        __typename: "Connector",
        chargePoint: {
          __typename: "ChargePoint",
          publicCode: "FR*ELC*CABC1",
        },
      },
      id: MOCK_USER.instantChargeIntentId,
    },
    paymentMethod,
  });

export const completedScreenAnonymousSessionFactory =
  Factory.Sync.makeFactory<CompletedScreen_AnonymousSessionFragment>({
    __typename: "AnonymousSession",
    account: {
      __typename: "Account",
      accountUser: {
        __typename: "AccountUser",
        charge: {
          __typename: "Charge",
          id: MOCK_USER.chargeId,
          invoice: {
            __typename: "Invoice",
            fileUrl: "https://www.go-electra.com",
            id: "fake-invoice-id",
            status: InvoiceStatusEnum.Available,
          },
        },
        id: MOCK_USER.accountUserId,
      },
      id: MOCK_USER.accountId,
    },
    id: MOCK_USER.anonymousSessionId,
  });

export const noCurrentRemoteChargeAnonymousSessionFactory = Factory.Sync.makeFactory<CurrentRemoteChargeFragment>({
  __typename: "AnonymousSession",
  currentRemoteCharge: null,
  id: MOCK_USER.anonymousSessionId,
});

export const withCurrentRemoteChargeAnonymousSessionFactory = Factory.Sync.makeFactory<CurrentRemoteChargeFragment>({
  __typename: "AnonymousSession",
  currentRemoteCharge: {
    __typename: "RemoteCharge",
    id: MOCK_USER.remoteChargeId,
  },
  id: MOCK_USER.anonymousSessionId,
});
