import classNames from "classnames";
import { FunctionComponent, InputHTMLAttributes, SVGProps, forwardRef, useCallback, useEffect, useRef } from "react";

import CloseIcon from "../../../images/icons/ri/close-circle.svg?react";
import CheckboxIcon from "../../../images/icons/ri/hollow-check-circle.svg?react";
import ErrorIcon from "../../../images/icons/ri/warning-triangle.svg?react";
import Action from "../Action";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  bg?: string;
  children?: never;
  ["data-testid"]?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
  initialValue?: string | number | readonly string[] | undefined;
  inputClassName?: string;
  onClear?: () => void;
  prefix?: string;
  resetServerError?: () => void;
  serverError?: boolean;
  state?: "none" | "invalid" | "valid";
  uppercase?: boolean;
  variant?: "small" | "medium";
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      disabled = false,
      icon: Icon,
      initialValue,
      state = "none",
      onClear,
      value,
      uppercase,
      resetServerError,
      serverError,
      inputClassName,
      prefix,
      variant = "medium",
      ...rest
    },
    forwardedRef
  ) => {
    const ref = useRef<HTMLInputElement>(null);
    const isInvalid = state === "invalid";
    const isValid = state === "valid";
    const isSmall = variant === "small";
    const hasFocus = document.activeElement === ref?.current && document.hasFocus();
    const showErrorIcon = isInvalid && !hasFocus && !isSmall;
    const mustResetServerError = isInvalid && resetServerError && hasFocus && serverError;
    const showValidIcon = isValid && !hasFocus && !isSmall; // Valid icon is not shown in small variant
    const showClearIcon =
      !!value &&
      !!onClear &&
      !disabled &&
      (!initialValue || initialValue !== value) &&
      !showErrorIcon &&
      !showValidIcon;

    /* intercept */
    useEffect(() => {
      if (typeof forwardedRef === "function") {
        forwardedRef(ref.current);
      } else if (forwardedRef) {
        forwardedRef.current = ref.current;
      }
    }, [forwardedRef]);

    useEffect(() => {
      if (mustResetServerError) {
        resetServerError();
      }
    }, [mustResetServerError, resetServerError]);

    const focusInput = useCallback(() => {
      const input = ref?.current;
      if (!input || disabled || hasFocus) return;

      input.focus();
    }, [disabled, hasFocus]);

    return (
      <div
        className={classNames(
          "inline-flex w-full max-w-full items-center gap-2 overflow-hidden rounded-sm border font-light transition-colors",
          {
            "border-neutral-300 bg-white focus-within:border focus-within:border-neutral-700 focus-within:outline focus-within:outline-2 focus-within:outline-neutral-50":
              !disabled && state === "none",
            "border-neutral-300 bg-white focus-within:border-green-500 focus-within:bg-green-100": !disabled && isValid,
            "border-neutral-300 focus-within:border-2 focus-within:border-red-500 focus-within:bg-red-100":
              !disabled && isInvalid,
            "border-neutral-50 bg-neutral-50 text-neutral-300": disabled,
            "border-red-500 bg-red-100": variant === "small" && !disabled && isInvalid,
            "h-14": !isSmall,
            "h-8": isSmall,
            "pl-2": isSmall,
            "px-4": !isSmall,
            "text-base": !isSmall,
            "text-neutral-900": !disabled,
            "text-sm": isSmall,
          },
          className
        )}
        onClick={focusInput}
      >
        {Icon ? <Icon className="h-6 w-6 flex-none" /> : null}

        {prefix && <span className="self-center text-neutral-600">{prefix}</span>}

        <input
          className={classNames(
            "appearance-none border-none px-0 font-normal placeholder-neutral-300 outline-none transition-colors placeholder:normal-case focus:ring-0",
            {
              "bg-neutral-50": disabled,
              "bg-transparent": !disabled,
              "text-base": !isSmall,
              "text-sm": isSmall,
              uppercase: !!uppercase,
              "w-full flex-1": !inputClassName,
            },
            inputClassName
          )}
          disabled={disabled}
          ref={ref}
          value={value}
          {...rest}
        />

        {showClearIcon && (
          <Action
            className={classNames({ "-ml-[16px]": isSmall })}
            icon={CloseIcon}
            onClick={onClear}
            size="small"
            testId="clear-button"
            iconSize={variant}
          />
        )}

        {showErrorIcon && <ErrorIcon className="h-6 w-6 flex-none" />}
        {showValidIcon && (
          <CheckboxIcon
            className={classNames("h-6 w-6 flex-none", {
              "text-green-500": !disabled,
              "text-neutral-300": disabled,
            })}
          />
        )}
      </div>
    );
  }
);

export default Input;
