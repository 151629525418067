import { HttpResponse, graphql } from "msw";
import {
  ChargePointPageQuery,
  ChargePointPageQueryVariables,
  CreateAnonymousChargeIntentMutation,
  CreateAnonymousChargeIntentMutationVariables,
  CurrentRemoteChargeQuery,
  CurrentRemoteChargeQueryVariables,
} from "operations";
import { getQueryErrorHandler, getQueryLoadingHandler, handleAuthenticatedMockedOperation } from "utils";

import { MOCK_USER } from "../../mocks/constants";
import {
  noCurrentRemoteChargeAnonymousSessionFactory,
  withCurrentRemoteChargeAnonymousSessionFactory,
} from "../../mocks/factories/anonymousSessionFactory";
import {
  chargePointFactory,
  chargePointWithAnonymousOccupiedFactory,
  chargePointWithStationClosedFactory,
} from "../../mocks/factories/chargePointFactory";

const chargePoint = chargePointFactory.build();
const chargePointQuery = graphql.query<ChargePointPageQuery, ChargePointPageQueryVariables>("ChargePointPage", () => {
  return HttpResponse.json({
    data: {
      chargePoint,
    },
  });
});
const chargePointWithStationClosedQuery = graphql.query<ChargePointPageQuery, ChargePointPageQueryVariables>(
  "ChargePointPage",
  () => {
    return HttpResponse.json({
      data: {
        chargePoint: chargePointWithStationClosedFactory.build(),
      },
    });
  }
);
const chargePointWithAnonymousOccupiedQuery = graphql.query<ChargePointPageQuery, ChargePointPageQueryVariables>(
  "ChargePointPage",
  () => {
    return HttpResponse.json({
      data: {
        chargePoint: chargePointWithAnonymousOccupiedFactory.build(),
      },
    });
  }
);
const mutationHandlers = [
  graphql.mutation<CreateAnonymousChargeIntentMutation, CreateAnonymousChargeIntentMutationVariables>(
    "CreateAnonymousChargeIntent",
    () => {
      return HttpResponse.json({
        data: {
          createAnonymousChargeIntent: {
            __typename: "CreateAnonymousChargeIntentPayload",
            accountId: MOCK_USER.accountId,
            errors: [],
            sessionToken: MOCK_USER.sessionToken,
          },
        },
      });
    }
  ),
];

const chargePointPageHandlers = {
  base: [
    ...mutationHandlers,
    handleAuthenticatedMockedOperation<CurrentRemoteChargeQuery, CurrentRemoteChargeQueryVariables>(
      "CurrentRemoteCharge",
      {
        anonymousSession: noCurrentRemoteChargeAnonymousSessionFactory.build(),
      }
    ),
    chargePointQuery,
  ],
  error: [getQueryErrorHandler("CurrentRemoteCharge"), chargePointQuery],
  loading: [getQueryLoadingHandler("CurrentRemoteCharge"), chargePointQuery],
  resumeSessionWithSessionStorage: [
    ...mutationHandlers,
    handleAuthenticatedMockedOperation<CurrentRemoteChargeQuery, CurrentRemoteChargeQueryVariables>(
      "CurrentRemoteCharge",
      {
        anonymousSession: withCurrentRemoteChargeAnonymousSessionFactory.build(),
      }
    ),
    chargePointQuery,
  ],
  resumeSessionWithoutSessionStorage: [
    ...mutationHandlers,
    handleAuthenticatedMockedOperation<CurrentRemoteChargeQuery, CurrentRemoteChargeQueryVariables>(
      "CurrentRemoteCharge",
      {
        anonymousSession: noCurrentRemoteChargeAnonymousSessionFactory.build(),
      }
    ),
    chargePointWithAnonymousOccupiedQuery,
  ],
  withStationClosed: [
    handleAuthenticatedMockedOperation<CurrentRemoteChargeQuery, CurrentRemoteChargeQueryVariables>(
      "CurrentRemoteCharge",
      {
        anonymousSession: noCurrentRemoteChargeAnonymousSessionFactory.build(),
      }
    ),
    chargePointWithStationClosedQuery,
  ],
};

export default chargePointPageHandlers;
