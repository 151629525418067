import classNames from "classnames";
import { ErrorMessage } from "formik";
import { FunctionComponent } from "react";

interface Props {
  name: string;
  variant?: "small" | "medium";
}

const FieldError: FunctionComponent<Props> = ({ name, variant = "medium" }) => {
  return (
    <ErrorMessage name={name}>
      {(msg) => (
        <div
          className={classNames("text-xs font-normal text-red-600", {
            "mt-1": variant === "small",
            "mt-2": variant === "medium",
          })}
          data-testid="input-error"
        >
          {msg}
        </div>
      )}
    </ErrorMessage>
  );
};

export default FieldError;
