import { PNF_TOKEN_STORAGE_KEY, PNF_APOLLO_STORAGE_KEY, WEBVIEW_TOKEN_STORAGE_KEY } from "constants/storage";

import { ApolloClient, ApolloLink, HttpLink, Operation, from, InMemoryCache } from "@apollo/client";
import { createConsumer, logger } from "@rails/actioncable";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import { useApolloErrorLinks, useGraphqlUris, useLocalStorage } from "hooks";
import typePolicies from "operations/src/type-policies";
import { useCallback, useEffect, useMemo } from "react";
import { version, getCurrentLocale } from "utils";

if (import.meta.env.DEV) {
  logger.enabled = true;
}

const WEBVIEW_OPERATIONS = ["ReferralRewards", "ReferralProgram", "RecapMetrics"];

const useClient = () => {
  const isStorybook = import.meta.env.STORYBOOK;
  const cache = useMemo(
    () =>
      new InMemoryCache({
        typePolicies,
      }),
    []
  );
  const { cableUri, graphqlUri } = useGraphqlUris(PNF_TOKEN_STORAGE_KEY);
  const apolloErrorLinks = useApolloErrorLinks(PNF_TOKEN_STORAGE_KEY);
  const cable = createConsumer(cableUri);
  const [sessionToken] = useLocalStorage(PNF_TOKEN_STORAGE_KEY);
  const [webViewSessionToken] = useLocalStorage(WEBVIEW_TOKEN_STORAGE_KEY);
  const httpLink = new HttpLink({
    headers: {
      "X-Electra-App-Name": "pnf",
      "X-Electra-App-Platform": "web",
      "X-Electra-App-Version": version,
    },
    uri: graphqlUri,
  });

  const middleware = new ApolloLink((operation, forward) => {
    const locale = getCurrentLocale();
    const baseHeaders = { "X-Electra-Locale": locale };

    const middlewareHeaders =
      WEBVIEW_OPERATIONS.includes(operation.operationName) && webViewSessionToken
        ? { ...baseHeaders, "X-Session-Token": webViewSessionToken }
        : sessionToken
          ? { ...baseHeaders, "X-Anonymous-Token": sessionToken }
          : baseHeaders;

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...middlewareHeaders,
      },
    }));

    return forward(operation);
  });

  const cableLink = new ActionCableLink({
    cable,
    channelName: "ApiChannel",
  });

  const storage = useMemo(() => new LocalStorageWrapper(window.localStorage), []);

  const persistApolloCache = useCallback(
    async () =>
      await persistCache({
        cache,
        debug: import.meta.env.DEV,
        key: PNF_APOLLO_STORAGE_KEY,
        storage,
        trigger: "write",
      }),
    [storage, cache]
  );

  useEffect(() => {
    persistApolloCache();
  });

  const hasSubscriptionOperation: (op: Operation) => boolean = ({ query: { definitions } }) => {
    return definitions.some((definition) => {
      if (definition.kind === "OperationDefinition") {
        return definition.operation === "subscription";
      }

      return false;
    });
  };

  const connectionLink = isStorybook ? httpLink : ApolloLink.split(hasSubscriptionOperation, cableLink, httpLink);

  const client = new ApolloClient({
    cache,
    link: from([...apolloErrorLinks, middleware, connectionLink]),
  });

  return client;
};

export default useClient;
