import "react-datepicker/dist/react-datepicker.css";
import { de } from "date-fns/locale/de";
import { enUS } from "date-fns/locale/en-US";
import { es } from "date-fns/locale/es";
import { fr } from "date-fns/locale/fr";
import { it } from "date-fns/locale/it";
import { nl } from "date-fns/locale/nl";
import { ComponentProps, forwardRef, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { getCurrentLocale } from "utils";

import Input from "../Input";

import FieldWrapper, { FieldWrapperProps } from "./FieldWrapper";

export interface Props extends FieldWrapperProps {
  children?: never;
  inputProps?: ComponentProps<typeof Input>;
  onChange: (date: Date | null) => void;
  value?: string;
}

const DateInputField = forwardRef<HTMLInputElement, Props>(({ label, name, onChange }, ref) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const locale = getCurrentLocale();

  useEffect(() => {
    registerLocale("de", de);
    registerLocale("enUs", enUS);
    registerLocale("es", es);
    registerLocale("fr", fr);
    registerLocale("it", it);
    registerLocale("nl", nl);
  }, []);

  return (
    <FieldWrapper className="col-span-2 w-full" label={label} name="dateOfCharge">
      <DatePicker
        wrapperClassName="w-full"
        selected={startDate}
        onChange={(date: Date | null) => {
          setStartDate(date);
          onChange(date);
        }}
        customInput={<Input name={name} placeholder="___/ ___/ ___" readOnly ref={ref} />}
        dateFormat="dd/MM/yyyy"
        locale={locale}
      />
    </FieldWrapper>
  );
});

export default DateInputField;
