import { forwardRef, ReactNode } from "react";

type Props = {
  children: ReactNode;
  content: string | ReactNode;
};

const Tooltip = forwardRef<HTMLDivElement, Props>(({ children, content }, ref) => {
  return (
    <div className="has-tooltip relative flex cursor-pointer" ref={ref}>
      <div className="tooltip rounded-xs after:border-x-3 margin-auto -bottom-10 left-[50%] w-fit -translate-x-[50%] bg-neutral-700 p-1 text-white after:absolute after:-top-1 after:left-[50%] after:h-0 after:w-0 after:border-b-[6px] after:border-x-transparent after:border-b-neutral-700 after:content-['']">
        {content}
      </div>
      {children}
    </div>
  );
});

export default Tooltip;
