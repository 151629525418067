import { graphql, GraphQLQuery, GraphQLVariables, HttpResponse, RequestHandlerOptions, StrictResponse } from "msw";
import { OperationTypeNode } from "graphql";

type AppOperationType = OperationTypeNode.QUERY | OperationTypeNode.MUTATION;

export const handleAuthenticatedMockedOperation = <T extends GraphQLQuery, U extends GraphQLVariables>(
  operationName: string,
  responseData: T,
  operationType?: AppOperationType,
  token?: "X-Anonymous-Token" | "X-Session-Token",
  options?: RequestHandlerOptions
) => {
  return graphql[operationType ?? OperationTypeNode.QUERY]<T, U>(
    operationName,
    ({ request }) => {
      const hasSessionToken = request.headers.get(token ?? "X-Anonymous-Token");
      return hasSessionToken
        ? HttpResponse.json({
            data: responseData,
          })
        : (new HttpResponse(null, { status: 401 }) as StrictResponse<never>);
    },
    options
  );
};
