export const getTimePortion = (duration: number) => {
  let seconds = duration;
  let minutes = Math.floor(seconds / 60);
  if (duration >= 3600) {
    seconds -= minutes * 60;
    const hours = Math.floor(minutes / 60);
    minutes -= hours * 60;
    return { hours, minutes, seconds };
  }
  seconds -= minutes * 60;
  return { hours: 0, minutes, seconds };
};
