import classNames from "classnames";
import { forwardRef, InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  children?: never;
}

const Slider = forwardRef<HTMLInputElement, Props>(
  ({ value = 50, min = 0, max = 100, step = 1, className, disabled = false, ...rest }, ref) => {
    const valueNumber = parseFloat(value.toString());
    const minNumber = parseFloat(min.toString());
    const maxNumber = parseFloat(max.toString());

    const background = `linear-gradient(to right, currentColor 0%, currentColor ${
      ((valueNumber - minNumber) / (maxNumber - minNumber)) * 100
    }%, #BBD6D3 ${((valueNumber - minNumber) / (maxNumber - minNumber)) * 100}%, #BBD6D3 100%)`;

    return (
      <input
        ref={ref}
        min={min}
        max={max}
        step={step}
        value={value}
        className={classNames(
          "appearance-none cursor-pointer disabled:border-neutral-300 disabled:cursor-auto disabled:text-neutral-600 h-0.5 leading-none text-neutral-900 w-full",
          className
        )}
        style={{
          background,
        }}
        disabled={disabled}
        type="range"
        {...rest}
      ></input>
    );
  }
);

export default Slider;
