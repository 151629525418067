import { handleAuthenticatedMockedOperation } from "@webapps/shared/utils";
import { OperationTypeNode } from "graphql";
import { UnsubscribeEmailMutation, UnsubscribeEmailMutationVariables } from "operations";

const base = [
  handleAuthenticatedMockedOperation<UnsubscribeEmailMutation, UnsubscribeEmailMutationVariables>(
    "Unsubscribe",
    {
      unsubscribe: { __typename: "UnsubscribePayload", email: "fake-email@fake.com", errors: [] },
    },
    OperationTypeNode.MUTATION
  ),
];

const supportPageHandlers = {
  base,
  error: base,
};

export default supportPageHandlers;
