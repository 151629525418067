import { ErrorScreen } from "@webapps/shared/components";
import { useErrorHandling } from "hooks";

function ErrorPage({ error }: { error?: Error }) {
  const { title, errorMessage } = useErrorHandling(error);

  return <ErrorScreen error={errorMessage} canReloadPage={true} navLeft={null} title={title} />;
}

export default ErrorPage;
