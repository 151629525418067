import * as Factory from "factory.ts";
import { PaymentMethodPage_PaymentMethodFragment } from "operations";

export enum CardScheme {
  AMEX = "american express",
  MASTERCARD = "mastercard",
  UNKNOWN = "unknown",
  VISA = "visa",
}

export const paymentMethodPageFactory = Factory.Sync.makeFactory<PaymentMethodPage_PaymentMethodFragment>({
  __typename: "PaymentMethod",
  cardLast4Digits: "9999",
  cardScheme: CardScheme.VISA,
  id: "card_145smI0DyRoSrKizUaVgrEb0FFInVgta",
  processoutTokenRef: "fake-processout-token-ref",
});
