import { useField } from "formik";
import { ComponentProps, forwardRef, useEffect, useState } from "react";

import MultilineInput from "../MultilineInput";

import FieldWrapper, { FieldWrapperProps } from "./FieldWrapper";

export interface Props extends FieldWrapperProps {
  children?: never;
  footer?: string | undefined;
  inputProps?: ComponentProps<typeof MultilineInput>;
  validate?: (value: string) => string | undefined;
}

const MultilineInputField = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, footer, inputProps, label, name, validate }, ref) => {
    const [field, { error, initialValue, touched }, { setValue }] = useField<string>({ name, validate });
    const [charactersCount, setCharactersCount] = useState<number>(0);

    useEffect(() => {
      setCharactersCount(field.value ? field.value.length : 0);
    }, [field.value]);

    return (
      <div className="flex flex-col gap-2">
        <FieldWrapper
          className={className}
          label={label}
          name={name}
          charactersCount={charactersCount}
          charactersMaxCount={inputProps?.maxLength}
        >
          <MultilineInput
            ref={ref}
            state={error && touched ? "invalid" : "none"}
            onClear={() => setValue((inputProps?.initialValue as string) ?? initialValue ?? "", false)}
            {...field}
            {...inputProps}
          />
        </FieldWrapper>
        {!!footer && <div className="font-normal text-neutral-600 text-xs">{footer}</div>}
      </div>
    );
  }
);

export default MultilineInputField;
