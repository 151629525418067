// session storage key
export const BUSINESS_TOKEN_STORAGE_KEY = "electra-business-user-session-token";
export const PARTNERS_TOKEN_STORAGE_KEY = "electra-partners-session-token";
export const PNF_TOKEN_STORAGE_KEY = "electra-pnf-user-session-token";
export const WEBVIEW_TOKEN_STORAGE_KEY = "electra-webview-session-token";
export const BUSINESS_PERSIST_STORAGE_KEY = "electra-business-user";

// Apollo persistence storage key
export const BUSINESS_APOLLO_STORAGE_KEY = "electra-business-apollo-cache-persist";
export const PARTNERS_APOLLO_STORAGE_KEY = "electra-partners-apollo-cache-persist";
export const PNF_APOLLO_STORAGE_KEY = "electra-pnf-apollo-cache-persist";
export const WEBVIEW_APOLLO_STORAGE_KEY = "electra-webview-apollo-cache-persist";

// other storage key
export const LAST_SWITCHED_ACCOUND_ID_KEY = "electra-last-switched-account-id";
