import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface ModalState {
  isModalDisplayed: boolean;
  setModalDisplayed: (displayed: boolean) => void;
}

export const useModal = create<ModalState>()(
  devtools((set) => ({
    isModalDisplayed: false,
    setModalDisplayed: (displayed) => set(() => ({ isModalDisplayed: displayed })),
  }))
);
