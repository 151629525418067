import classNames from "classnames";
import CheckboxIcon from "images/icons/ri/check-circle.svg?react";
import CloseIcon from "images/icons/ri/close-circle.svg?react";
import ErrorIcon from "images/icons/ri/error-warning-fill.svg?react";
import { TextareaHTMLAttributes, forwardRef, useCallback, useEffect, useRef } from "react";

import Action from "../Action";

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: never;
  initialValue?: string | number | readonly string[] | undefined;
  onClear?: () => void;
  state?: "none" | "invalid" | "valid";
  uppercase?: boolean;
}

const MultilineInput = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, disabled = false, initialValue, onClear, state = "none", uppercase, value, ...rest }, forwardedRef) => {
    const ref = useRef<HTMLTextAreaElement>(null);
    const isInvalid = state === "invalid";
    const isValid = state === "valid";

    /* intercept */
    useEffect(() => {
      if (typeof forwardedRef === "function") {
        forwardedRef(ref.current);
      } else if (forwardedRef) {
        forwardedRef.current = ref.current;
      }
    }, [forwardedRef]);

    const onClick = useCallback(() => {
      const input = ref?.current;
      if (!input || disabled || document.activeElement === input) return;

      input.focus();
    }, [disabled]);

    return (
      <div
        className={classNames(
          "inline-flex w-full max-w-full items-start gap-4 overflow-hidden rounded-sm border p-4 text-base transition-colors",
          {
            "border-neutral-300 bg-white": !disabled && state === "none",
            "border-neutral-300 bg-white focus-within:border-2 focus-within:border-red-500 focus-within:bg-red-100":
              !disabled && isInvalid,
            "border-neutral-300 bg-white focus-within:border-green-500 focus-within:bg-green-100": !disabled && isValid,
            "border-neutral-50 bg-neutral-50 text-neutral-300": disabled,
            "text-neutral-900": !disabled,
          },
          className
        )}
        onClick={onClick}
      >
        <textarea
          className={classNames(
            "flex-1 resize-none appearance-none border-none bg-transparent p-0 text-base text-current placeholder-neutral-300 outline-none transition-colors placeholder:text-base placeholder:font-normal focus:ring-0",
            {
              uppercase: !!uppercase,
            }
          )}
          disabled={disabled}
          ref={ref}
          value={value}
          {...rest}
        />
        <div className="flex-col">
          {!!value && !!onClear && !disabled && (!initialValue || initialValue !== value) && (
            <Action icon={CloseIcon} onClick={onClear} size="small" />
          )}
          {state === "invalid" ? (
            <ErrorIcon
              className={classNames("h-6 w-6", {
                "text-red-600": !disabled,
                "text-red-600 text-opacity-0": disabled,
              })}
            />
          ) : null}
          {state === "valid" ? (
            <CheckboxIcon
              className={classNames("h-6 w-6", {
                "text-green-500": !disabled,
                "text-red-600 text-opacity-0": disabled,
              })}
            />
          ) : null}
        </div>
      </div>
    );
  }
);

export default MultilineInput;
