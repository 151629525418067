import classNames from "classnames";
import { useField } from "formik";
import { ComponentProps, forwardRef } from "react";

import Input from "../Input";

import FieldWrapper, { FieldWrapperProps } from "./FieldWrapper";

export interface Props extends FieldWrapperProps {
  children?: never;
  inputProps?: ComponentProps<typeof Input>;
  serverError?: boolean;
  validate?: (value: string) => string | undefined;
}

const CardInputField = forwardRef<HTMLInputElement, Props>(
  ({ inputProps, label, name, validate, serverError = false }, ref) => {
    const [field, { error, initialValue, touched }, { setValue }] = useField<string>({ name, validate });

    const handleClear = () => {
      setValue((inputProps?.initialValue as string) ?? initialValue ?? "", false);
    };

    return (
      <div className={classNames("flex items-center justify-between", { "bg-white": inputProps?.bg === "white" })}>
        <FieldWrapper className="relative h-14 w-full" label={label} name={name} disabled={inputProps?.disabled}>
          <input
            placeholder=".... .... ...."
            disabled
            className="absolute bottom-[0.65rem] left-[3rem] h-14 w-[10rem] bg-transparent text-[2.25rem]"
          />
          <Input
            ref={ref}
            state={(error && touched) || serverError ? "invalid" : "none"}
            onClear={handleClear}
            {...field}
            {...inputProps}
            serverError={serverError}
            className="justify-between"
            inputClassName="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-[50px] absolute left-[12rem]"
          />
        </FieldWrapper>
      </div>
    );
  }
);

export default CardInputField;
