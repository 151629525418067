import { BusinessCompanyIdentificationType, CompanyScreen_CountryFragment } from "@webapps/shared/operations";
import { t } from "i18next";
import { Country } from "operations";

export const required = (value: string | number) =>
  !value ? t("form.validate.required", { ns: "common" }) : undefined;

export const validateCodePhoneChallenge = (value: string) => {
  if (!value) {
    return t("form.validate.required", { ns: "common" });
  }

  if (!/^[0-9]+$/.test(value)) {
    return t("form.validate.numbers", { ns: "common" });
  }

  if (!/^[0-9]{6}$/.test(value)) {
    return t("form.validate.sixNumbers", { ns: "common" });
  }
};

export const validateFourDigits = (value: string) => {
  if (!value) {
    return t("form.validate.required", { ns: "common" });
  }

  if (!/^[0-9]{4}$/.test(value)) {
    return t("form.validate.fourDigits", { ns: "common" });
  }
};

export const validateCompanyIdentificationNumber = (
  value: string,
  type: BusinessCompanyIdentificationType,
  allowedCountries: CompanyScreen_CountryFragment[]
) => {
  if (!value) {
    return t("form.validate.required", { ns: "common" });
  }

  if (type === "siret") {
    const regex = new RegExp(/^[0-9]{14}$/);
    if (!regex.test(value)) {
      return t("form.validate.siret", { ns: "common" });
    }
  } else {
    const regexArray = allowedCountries.map(
      (country) => vatRegexByEuropeanCountryKey[country.key as keyof typeof vatRegexByEuropeanCountryKey]
    );
    const countries = allowedCountries.map((country) => country.name).join(", ");
    if (!regexArray.some((regex) => regex.test(value))) {
      return t("form.validate.vat", { countries, ns: "common" });
    }
  }

  return undefined;
};

const emailRegex =
  // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const validateEmail = (value: string) => {
  if (!value) {
    return t("form.validate.required", { ns: "common" });
  }
  if (!emailRegex.test(value)) {
    return t("form.validate.email", { ns: "common" });
  }
};

export const validateGdpr = (value: boolean) => (!value ? t("form.validate.gdpr", { ns: "common" }) : undefined);

export const validateGdprRegister = (value: boolean) =>
  !value ? t("form.validate.gdprPro", { ns: "common" }) : undefined;

const vatRegexByEuropeanCountryKey = {
  [Country.At]: /^ATU[0-9]{8}$/,
  [Country.Be]: /^BE(0|1)[0-9]{9}$/,
  [Country.Bg]: /^BG[0-9]{9,10}$/,
  [Country.Cy]: /^CY[0-9]{8}L$/,
  [Country.Cz]: /^CZ[0-9]{8,10}$/,
  [Country.De]: /^DE[0-9]{9}$/,
  [Country.Dk]: /^DK[0-9]{8}$/,
  [Country.Ee]: /^EE[0-9]{9}$/,
  [Country.Gr]: /^(EL|GR)[0-9]{9}$/,
  [Country.Es]: /^ES[0-9A-Z][0-9]{7}[0-9A-Z]$/,
  [Country.Fi]: /^FI[0-9]{8}$/,
  [Country.Fr]: /^FR[0-9A-Z]{2}[0-9]{9}$/,
  [Country.Gb]: /^GB([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/,
  [Country.Hu]: /^HU[0-9]{8}$/,
  [Country.Ie]: /^IE[0-9]S[0-9]{5}L$/,
  [Country.It]: /^IT[0-9]{11}$/,
  [Country.Lt]: /^LT([0-9]{9}|[0-9]{12})$/,
  [Country.Lu]: /^LU[0-9]{8}$/,
  [Country.Lv]: /^LV[0-9]{11}$/,
  [Country.Mt]: /^MT[0-9]{8}$/,
  [Country.Nl]: /^NL[0-9]{9}B[0-9]{2}$/,
  [Country.Pl]: /^PL[0-9]{10}$/,
  [Country.Pt]: /^PT[0-9]{9}$/,
  [Country.Ro]: /^RO[0-9]{2,10}$/,
  [Country.Se]: /^SE[0-9]{12}$/,
  [Country.Si]: /^SI[0-9]{8}$/,
  [Country.Sk]: /^SK[0-9]{10}$/,
};
