import * as Factory from "factory.ts";
import {
  Currency,
  RemoteChargeActionType,
  RemoteChargePage_RemoteChargeActionStepFragment,
  RemoteChargePage_RemoteChargeChargingStepFragment,
  RemoteChargePage_RemoteChargeCompletedStepFragment,
  RemoteChargePage_RemoteChargeWaitStepFragment,
} from "operations";

export const actionStepFactory = Factory.Sync.makeFactory<RemoteChargePage_RemoteChargeActionStepFragment>({
  __typename: "RemoteChargeActionStep",
  actionHeaderMessage: "0,49 € / kWh",
  actionKey: "START_CHARGE",
  actionLabel: "Commencer la recharge",
  actionType: RemoteChargeActionType.Plug,
  message: "Ouvrez la trappe de recharge de votre véhicule. Vous brancherez le câble ensuite.",
  subTitle: "SubTitle",
  title: "Préparez-vous à recharger",
});

export const chargingStepFactory = Factory.Sync.makeFactory<RemoteChargePage_RemoteChargeChargingStepFragment>({
  __typename: "RemoteChargeChargingStep",
  actionConfirmMessage: "Êtes-vous certain de vouloir stopper la recharge ?",
  actionKey: "STOP_CHARGE",
  actionLabel: "Stopper la recharge",
  canShowPricing: true,
  currency: Currency.Eur,
  currentPower: 123456,
  currentPriceCents: 123,
  currentSoc: 45,
  energyDelivered: 12345,
  estimatedDurationLabel: null,
  estimatedRemainingDurationComputing: true,
  estimatedRemainingDurationLabel: "15 min. restantes",
  targetSoc: 80,
});

export const completedStepFactory = Factory.Sync.makeFactory<RemoteChargePage_RemoteChargeCompletedStepFragment>({
  __typename: "RemoteChargeCompletedStep",
  canShowPricing: true,
  currency: Currency.Eur,
  details: [
    {
      label: "Date",
      value: "7 Fév. 2021 à 16:38",
    },
    {
      label: "Station",
      value: "HYATT REGENCY Paris - Charles …",
    },
  ],
  energyDelivered: 12345,
  message: "",
  priceCents: 1234,
  priceLabel: "",
  title: "Charge terminée",
  totalDurationInSeconds: 2820,
});

export const waitStepFactory = Factory.Sync.makeFactory<RemoteChargePage_RemoteChargeWaitStepFragment>({
  __typename: "RemoteChargeWaitStep",
  message: "Le connecteur est bien branché. Patientez le temps que la recharge commence.",
  title: "Patientez",
});
