import classNames from "classnames";
import Logo from "images/logo.svg?react";
import { FunctionComponent, ReactNode } from "react";

interface Props {
  background?: "darkOnMobile" | "light" | "none";
  children?: never;
  className?: string;
  customPadding?: string;
  debug?: boolean;
  floating?: boolean;
  left?: ReactNode;
  right?: ReactNode;
  secondary?: ReactNode;
  showLogo?: boolean;
  showMain?: boolean;
  stretchContentOnLargeScreen?: boolean;
  title?: string | ReactNode | undefined;
}

const Navbar: FunctionComponent<Props> = ({
  background = "light",
  className,
  customPadding,
  debug = false,
  floating = false,
  left,
  right,
  secondary,
  showLogo = true,
  showMain = true,
  stretchContentOnLargeScreen = false,
  title,
}) => {
  return (
    <nav
      className={classNames(
        "w-full select-none ",
        {
          "bg-neutral-700 text-white md:bg-white md:text-neutral-700": background === "darkOnMobile",
          "bg-transparent text-neutral-700": background === "none",
          "bg-white": background === "light",
          "pt-1-safe": !customPadding,
          shadow: floating,
        },
        customPadding,
        className
      )}
    >
      <div
        className={classNames("px-4-safe flex h-11 items-stretch gap-2", {
          "md:max-w-screen-lg": !stretchContentOnLargeScreen,
        })}
      >
        <div
          className={classNames("flex items-center justify-start gap-4", {
            "bg-green-100": debug,
          })}
        >
          {left}
          {showLogo && <Logo className="w-24" />}
        </div>

        <div
          className={classNames(
            "flex flex-initial flex-col items-start justify-center overflow-hidden transition-opacity",
            {
              "bg-green-100": debug,
              "opacity-0": !showMain,
              "text-lg": !secondary,
              "text-sm": secondary,
            }
          )}
        >
          <div className="truncate font-medium">{title}</div>

          {secondary ?? null}
        </div>

        <div
          className={classNames("flex flex-1 items-center justify-end gap-4", {
            "bg-green-100": debug,
          })}
        >
          {right}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
