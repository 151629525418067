import { WEBVIEW_TOKEN_STORAGE_KEY } from "constants/storage";

import { useLocalStorage } from "hooks";
import { version, getCurrentLocale } from "utils";

export const useGraphqlUris = (storageKey: string) => {
  const isStorybook = import.meta.env.STORYBOOK;
  const [sessionToken] = useLocalStorage(storageKey);
  let graphqlUriFromHtmlLink: string | undefined;
  const graphqlLinkElement = document.querySelector<HTMLLinkElement>("link[rel=graphql]");
  if (graphqlLinkElement) {
    graphqlUriFromHtmlLink = graphqlLinkElement.href;
  }

  let cableUriFromHtmlLink: string | undefined;
  const cableLinkElement = document.querySelector<HTMLLinkElement>("link[rel=cable]");
  if (cableLinkElement) {
    cableUriFromHtmlLink = cableLinkElement.href;
  }

  if (import.meta.env.DEV || isStorybook) {
    // To work on tablets, phone on local network.
    graphqlUriFromHtmlLink = "/api/graphql";
    cableUriFromHtmlLink = "/cable";
    /* } else if (process.env.NODE_ENV === "test") {
  graphqlUriFromHtmlLink = "localhost:3000/api/graphql";
  cableUriFromHtmlLink = "localhost:3000/cable"; */
  } else if (!graphqlUriFromHtmlLink) {
    throw new Error('Missing <link rel="graphql" /> html element');
  } else if (!cableUriFromHtmlLink && storageKey !== WEBVIEW_TOKEN_STORAGE_KEY) {
    throw new Error('Missing <link rel="cable" /> html element');
  }

  const graphqlUri = graphqlUriFromHtmlLink;
  const currentLocale = getCurrentLocale();
  const cableUri = sessionToken
    ? `${cableUriFromHtmlLink}?session_token=${sessionToken}&app_platform=web&app_version=${version}&app_locale=${currentLocale}`
    : cableUriFromHtmlLink;

  return { cableUri, graphqlUri };
};
