import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useEventCallback, useEventListener } from "usehooks-ts";

import { notifyWarningOnBugsnag } from "../../libs/src/bugsnag";

type SetValue = Dispatch<SetStateAction<string | undefined | null>>;

// Hook adapted from https://github.com/juliencrn/usehooks-ts/blob/master/packages/usehooks-ts/src/useLocalStorage/useLocalStorage.ts
export function useLocalStorage(key?: string): [string | null | undefined, SetValue] {
  const readValue = useCallback(() => {
    if (typeof window === "undefined" || !key) {
      return null;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item;
    } catch (error) {
      handleLocalStorageError(error, key);
    }
  }, [key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<string | null | undefined>(() => readValue());

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue = useEventCallback((value) => {
    // Prevent build error "window is undefined" but keeps working
    if (!key) {
      console.warn(`Tried setting localStorage even though no key was provided`);
      return;
    }
    if (typeof window === "undefined") {
      console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
      return;
    }

    try {
      const newValue = value instanceof Function ? value(readValue()) : value;
      if (newValue) {
        window.localStorage.setItem(key, newValue as string);
      } else {
        window.localStorage.removeItem(key);
      }
      setStoredValue(newValue ?? null);
      window.dispatchEvent(new StorageEvent("local-storage", { key }));
    } catch (error) {
      handleLocalStorageError(error, key);
    }
  });

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue]
  );

  const handleLocalStorageError = (error: unknown, key: string) => {
    if (
      error instanceof DOMException &&
      // everything except Firefox
      (error.code === 22 ||
        // Firefox
        error.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        error.name === "QuotaExceededError" ||
        // Firefox
        error.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      window.localStorage &&
      window.localStorage.length !== 0
    ) {
      console.warn(`Unsupported localStorage:`, error);
      notifyWarningOnBugsnag(`[LocalStorage error] Unsupported localStorage: ${error}`, "warning");
    } else {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      notifyWarningOnBugsnag(`[LocalStorage error] Error reading localStorage key “${key}”`, "warning");
    }

    return null;
  };

  // this only works for other documents, not the current one
  useEventListener("storage", handleStorageChange);

  // this is a custom event, triggered in writeValueToLocalStorage
  // See: useLocalStorage()
  useEventListener("local-storage", handleStorageChange);

  return [storedValue, setValue];
}
