import classNames from "classnames";
import { ComponentProps, forwardRef, HTMLAttributeAnchorTarget, MouseEventHandler, useCallback } from "react";
import { useNavigate } from "react-router-dom";

type Props = Omit<ComponentProps<"a"> & ComponentProps<"div">, "href" | "onClick" | "target"> & {
  href?: string;
  onClick?: () => void;
  target?: HTMLAttributeAnchorTarget;
};

const AnchorOrDiv = forwardRef<HTMLDivElement | HTMLAnchorElement, Props>(
  ({ children, className, href, onClick, target = "_self", ...rest }, ref) => {
    const navigate = useNavigate();

    const onAnchorClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
      (event) => {
        event.stopPropagation();
        if (onClick) {
          event.preventDefault();
          onClick();
        } else if (href && !event.ctrlKey && !event.metaKey) {
          event.preventDefault();
          if (target === "_blank") {
            window.open(href, target);
          } else {
            navigate(href);
          }
        }
      },
      [navigate, href, onClick, target]
    );

    if (href) {
      return (
        <a
          ref={ref as React.Ref<HTMLAnchorElement>}
          className={classNames({ block: !hasBlockLikeClass(className) }, className)}
          href={href}
          onClick={onAnchorClick}
          {...rest}
        >
          {children}
        </a>
      );
    }

    return (
      <div
        ref={ref as React.Ref<HTMLDivElement>}
        className={classNames(className, { "cursor-pointer": !!onClick })}
        onClick={onClick}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

export default AnchorOrDiv;

export const hasBlockLikeClass = (className: string | undefined) =>
  !!className && /\b(block|flex|grid)\b/.test(className.replace(/-/g, "_"));
