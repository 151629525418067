import { onError } from "@apollo/client/link/error";
import { useLocalStorage } from "hooks";
import { useTranslation } from "react-i18next";

import { notifyWarningOnBugsnag } from "../../libs/src/bugsnag";

export const useApolloErrorLinks = (token: string) => {
  const [, setSessionToken] = useLocalStorage(token);
  const { t } = useTranslation();
  const networkErrorLink = onError(({ networkError }) => {
    if (networkError && "response" in networkError) {
      if (networkError.response.status === 401) {
        // error will be handled by the error screen, we clear the token to leave the user reload the page
        networkError.message = t("api.reloadPage", { ns: "common" });
        setSessionToken(undefined);
        notifyWarningOnBugsnag(networkError, "info");
      } else {
        notifyWarningOnBugsnag(networkError);
      }
    }
  });
  const graphqlErrorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        console.error(`graphqlError: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`);
        notifyWarningOnBugsnag(error);
      });
    }
  });
  return [networkErrorLink, graphqlErrorLink];
};
