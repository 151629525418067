import classNames from "classnames";
import { forwardRef, FunctionComponent, ReactNode, SVGProps, useEffect, useRef, useState } from "react";

interface Props {
  bottom?: ReactNode;
  children?: ReactNode;
  className?: string;
  containerHeight?: number;
  fontSize?: "bold" | "light" | "medium" | "semiBold";
  headerTrailingIcon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
  noMaxHeight?: boolean;
  onClickHeaderTrailingIcon?: () => void;
  onClose?: () => void;
  onTitleClick?: () => void;
  rawContent?: boolean;
  showHeaderSeparator?: boolean;
  subTitle?: ReactNode | string | undefined;
  subTitleSize?: "large" | "medium" | "small";
  title?: string | undefined;
  titleCentered?: boolean;
  titleSize?: "medium" | "large" | "xlarge";
  withBottomPaddingTop?: boolean;
  withContentPaddingTop?: boolean;
}

const HANDLE_HEIGHT = 18;
const SCROLL_OFFSET = 3;

const BottomSheet = forwardRef<HTMLDivElement, Props>(
  (
    {
      bottom,
      children,
      className,
      headerTrailingIcon: HeaderTrailingIcon,
      onClickHeaderTrailingIcon,
      onClose,
      rawContent = false,
      showHeaderSeparator = true,
      subTitle,
      subTitleSize = "small",
      title,
      titleSize = "large",
      fontSize = "medium",
      withContentPaddingTop = true,
      withBottomPaddingTop = true,
      noMaxHeight = false,
      titleCentered = false,
      containerHeight,
      onTitleClick,
    },
    ref
  ) => {
    const titleRef = useRef<HTMLDivElement>(null);
    const [childHeight, setChildHeight] = useState<number | string>("auto");

    useEffect(() => {
      if (!titleRef.current || !containerHeight) return;
      // 18px = handle height
      // We add 3px to avoid that the scroll bar appears when it is not necessary
      setChildHeight(containerHeight - (titleRef.current.clientHeight + HANDLE_HEIGHT) + SCROLL_OFFSET);
    }, [titleRef, containerHeight]);

    return (
      <div className={className} ref={ref}>
        <div className="-shadow-lg relative w-screen max-w-lg rounded-t-2xl bg-white pt-4 text-neutral-900 md:max-w-md lg:max-w-lg">
          <div
            className={classNames("absolute left-1/2 top-3 -ml-8 flex h-3 w-16 items-center justify-center", {
              "cursor-pointer": !!onClose,
            })}
            onClick={() => onClose?.()}
          >
            <div id="bottom-sheet-handle" className="h-1.5 w-12 rounded-full bg-neutral-300" />
          </div>

          {title || subTitle ? (
            <div
              ref={titleRef}
              className={classNames("px-4-safe pt-4", {
                "border-b border-neutral-200": showHeaderSeparator,
                "cursor-pointer": !!onTitleClick,
                "pb-4": showHeaderSeparator,
              })}
              onClick={onTitleClick}
            >
              {title ? (
                <div className="mb-2 flex flex-row">
                  <div
                    className={classNames("flex-1 text-neutral-900", {
                      "font-light": fontSize === "light",
                      "font-medium": fontSize === "medium",
                      "font-semibold": fontSize === "semiBold",
                      "text-2xl": titleSize === "xlarge",
                      "text-center": titleCentered,
                      "text-lg font-medium": titleSize === "medium",
                      "text-xl": titleSize === "large",
                    })}
                  >
                    {title}
                  </div>
                  {HeaderTrailingIcon ? (
                    <HeaderTrailingIcon
                      className={classNames("h-6 w-6 flex-none text-neutral-700", {
                        "cursor-pointer": !!onClickHeaderTrailingIcon,
                      })}
                      onClick={onClickHeaderTrailingIcon}
                    />
                  ) : null}
                </div>
              ) : null}

              {subTitle ? (
                <div
                  className={classNames("font-normal text-neutral-500", {
                    "text-base": subTitleSize === "medium",
                    "text-business-sm": subTitleSize === "small",
                    "text-xl": subTitleSize === "large",
                  })}
                >
                  {subTitle}
                </div>
              ) : null}
            </div>
          ) : null}

          {children ? (
            <div
              className={classNames("overflow-y-auto", {
                "max-h-96": !noMaxHeight,
                "pb-4-safe": !bottom && !rawContent,
                "pt-4": withContentPaddingTop && !rawContent,
                "px-4-safe": !rawContent,
              })}
              style={{ height: childHeight }}
            >
              {children}
            </div>
          ) : null}

          {bottom ? (
            <div
              className={classNames("px-4-safe pb-4-safe flex flex-col gap-2", {
                "pt-4": withBottomPaddingTop && !rawContent,
              })}
            >
              {bottom}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);

export default BottomSheet;
