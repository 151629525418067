import { HttpResponse, graphql } from "msw";
import { RetrieveAnonymousSessionMutation, RetrieveAnonymousSessionMutationVariables } from "operations";

import { MOCK_USER } from "../../mocks/constants";

const mutationHandlers = [
  graphql.mutation<RetrieveAnonymousSessionMutation, RetrieveAnonymousSessionMutationVariables>(
    "RetrieveAnonymousSession",
    () => {
      return HttpResponse.json({
        data: {
          retrieveAnonymousSession: {
            __typename: "RetrieveAnonymousSessionPayload",
            errors: [],
            sessionToken: MOCK_USER.sessionToken,
          },
        },
      });
    }
  ),
];

const checkCardPageHandlers = {
  base: mutationHandlers,
  resumeSessionWithSessionStorage: mutationHandlers,
  resumeSessionWithoutSessionStorage: mutationHandlers,
};

export default checkCardPageHandlers;
