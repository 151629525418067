import { Country, CountryObject } from "operations";

export const MOCK_USER = {
  accountId: "QXBwczo6QWNjb3VudF80ZmY0MjhlOC04MTMwLTExZWQtOTA2MC0xZjdhMjBmMmJlY2E",
  accountUserId: "QXBwczo6VXNlcl80ZmYxM2U5NC04MTMwLTExZWQtOTA2MC0yZjdkOWE2YjI1YTA",
  anonymousSessionId: "QXBwczo6VXNlclNlc3Npb25fYjQ3YmU5NzQtMDEyYi0xMWVlLTk4YjUtOTcwNjRkNDhmMmRm",
  chargeId: "QXBwczo6QWNjb3VudFVzZXJfYjQ3ODc1MWUtMDEyYi0xMWVlLTk4YjUtNjc2YzIyNGRiYWE2",
  id: "1ZOUyDVXNMh7QsDl5vsZOALv2AeZ2mIlqIlGbArypjW3G8s7Svwvdu4NXMRdSAdw",
  instantChargeIntentId: "QXBwczo6Q291bnRyeV9GUg",
  remoteChargeId: "QXBwczo6UmVtb3RlQ2hhcmdlX2EyNmNhZGE0LTA2YTgtMTFlZS1iM2QyLTdiYzNmY2I2MTA1Mw",
  sessionToken: "QXBwczo6Q2FyXzRjN2FhMWQ0LTg1ZmQtMTFlZC04YjI5LTBmM2Y3NTYzMmViNw",
};

export const FR_COUNTRY: CountryObject = {
  __typename: "CountryObject",
  callingCode: "33",
  id: "QXBwczo6Q291bnRyeV9GUg",
  isHighlighted: true,
  key: Country.Fr,
  name: "France",
  selfServeAvailable: true,
};
