import { OperationTypeNode } from "graphql";
import { HttpResponse, StrictResponse, graphql, delay } from "msw";
import {
  ChangeRemoteChargeObjectiveMutation,
  ChangeRemoteChargeObjectiveMutationVariables,
  CompletedScreenQuery,
  CompletedScreenQueryVariables,
  RemoteChargePageActionMutation,
  RemoteChargePageActionMutationVariables,
  RemoteChargePageQuery,
  RemoteChargePageQueryVariables,
} from "operations";
import { getQueryErrorHandler, getQueryLoadingHandler, handleAuthenticatedMockedOperation } from "utils";

import { MOCK_USER } from "../../mocks/constants";
import { completedScreenAnonymousSessionFactory } from "../../mocks/factories/anonymousSessionFactory";
import {
  remoteChargeFactoryActionStep,
  remoteChargeFactoryChargingStep,
  remoteChargeFactoryCompletedStep,
} from "../../mocks/factories/remoteChargeFactory";

const actionStepRemoteCharge = remoteChargeFactoryActionStep.build();
const chargingStepRemoteCharge = remoteChargeFactoryChargingStep.build();
const completedStepRemoteCharge = remoteChargeFactoryCompletedStep.build();

const mutationHandlers = [
  graphql.mutation<ChangeRemoteChargeObjectiveMutation, ChangeRemoteChargeObjectiveMutationVariables>(
    "ChangeRemoteChargeObjective",
    ({ request, variables }) => {
      const hasSessionToken = request.headers.get("X-Anonymous-Token");
      if (!hasSessionToken) {
        return new HttpResponse(null, { status: 401 }) as StrictResponse<never>;
      }
      const targetSoc = variables.input.targetSoc;
      return HttpResponse.json({
        data: {
          changeRemoteChargeObjective: {
            __typename: "ChangeRemoteChargeObjectivePayload",
            errors: [],
            remoteCharge: {
              ...chargingStepRemoteCharge,
              step: {
                ...chargingStepRemoteCharge.step,
                targetSoc,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any,
            },
          },
        },
      });
    }
  ),
  graphql.mutation<RemoteChargePageActionMutation, RemoteChargePageActionMutationVariables>(
    "RemoteChargePageAction",
    ({ request, variables }) => {
      const hasSessionToken = request.headers.get("X-Anonymous-Token");
      if (!hasSessionToken) {
        return new HttpResponse(null, { status: 401 }) as StrictResponse<never>;
      }
      const data =
        variables.input.actionKey === "START_CHARGE"
          ? {
              reportRemoteChargeAction: {
                errors: [],
                remoteCharge: chargingStepRemoteCharge,
              },
            }
          : {
              reportRemoteChargeAction: {
                errors: [],
                remoteCharge: completedStepRemoteCharge,
              },
            };
      return HttpResponse.json({
        data,
      });
    }
  ),
];
const completedRemoteChargeQuery = graphql.query<CompletedScreenQuery, CompletedScreenQueryVariables>(
  "CompletedScreen",
  async ({ request }) => {
    const hasSessionToken = request.headers.get("X-Anonymous-Token");
    if (!hasSessionToken) {
      return new HttpResponse(null, { status: 401 }) as StrictResponse<never>;
    }
    await delay(2000);
    return HttpResponse.json({
      data: {
        anonymousSession: completedScreenAnonymousSessionFactory.build(),
      },
    });
  }
);

const base = [
  ...mutationHandlers,
  handleAuthenticatedMockedOperation<RemoteChargePageQuery, RemoteChargePageQueryVariables>(
    "RemoteChargePage",
    {
      anonymousSession: {
        __typename: "AnonymousSession",
        currentRemoteCharge: actionStepRemoteCharge,
        id: MOCK_USER.anonymousSessionId,
      },
    },
    OperationTypeNode.QUERY,
    "X-Anonymous-Token",
    { once: true }
  ),
  handleAuthenticatedMockedOperation<RemoteChargePageQuery, RemoteChargePageQueryVariables>(
    "RemoteChargePage",
    {
      anonymousSession: {
        __typename: "AnonymousSession",
        currentRemoteCharge: actionStepRemoteCharge,
        id: MOCK_USER.anonymousSessionId,
      },
    },
    OperationTypeNode.QUERY,
    "X-Anonymous-Token",
    { once: true }
  ),
  handleAuthenticatedMockedOperation<RemoteChargePageQuery, RemoteChargePageQueryVariables>(
    "RemoteChargePage",
    {
      anonymousSession: {
        __typename: "AnonymousSession",
        currentRemoteCharge: chargingStepRemoteCharge,
        id: MOCK_USER.anonymousSessionId,
      },
    },
    OperationTypeNode.QUERY,
    "X-Anonymous-Token",
    { once: true }
  ),
  handleAuthenticatedMockedOperation<RemoteChargePageQuery, RemoteChargePageQueryVariables>(
    "RemoteChargePage",
    {
      anonymousSession: {
        __typename: "AnonymousSession",
        currentRemoteCharge: completedStepRemoteCharge,
        id: MOCK_USER.anonymousSessionId,
      },
    },
    OperationTypeNode.QUERY,
    "X-Anonymous-Token",
    { once: true }
  ),
  completedRemoteChargeQuery,
];

const withoutAction = [
  ...mutationHandlers,
  handleAuthenticatedMockedOperation<RemoteChargePageQuery, RemoteChargePageQueryVariables>(
    "RemoteChargePage",
    {
      anonymousSession: {
        __typename: "AnonymousSession",
        currentRemoteCharge: chargingStepRemoteCharge,
        id: MOCK_USER.anonymousSessionId,
      },
    },
    OperationTypeNode.QUERY,
    "X-Anonymous-Token",
    { once: true }
  ),
  handleAuthenticatedMockedOperation<RemoteChargePageQuery, RemoteChargePageQueryVariables>(
    "RemoteChargePage",
    {
      anonymousSession: {
        __typename: "AnonymousSession",
        currentRemoteCharge: completedStepRemoteCharge,
        id: MOCK_USER.anonymousSessionId,
      },
    },
    OperationTypeNode.QUERY,
    "X-Anonymous-Token",
    { once: true }
  ),
  completedRemoteChargeQuery,
];

export const completedRemoteChargeHandlers = {
  base: completedRemoteChargeQuery,
  loading: [getQueryLoadingHandler<CompletedScreenQuery, CompletedScreenQueryVariables>("CompletedScreen")],
};

const remoteChargePageHandlers = {
  base,
  error: [getQueryErrorHandler<RemoteChargePageQuery, RemoteChargePageQueryVariables>("RemoteChargePage")],
  loading: [getQueryLoadingHandler<RemoteChargePageQuery, RemoteChargePageQueryVariables>("RemoteChargePage")],
  resumeSessionWithSessionStorage: withoutAction,
  resumeSessionWithoutSessionStorage: withoutAction,
};

export default remoteChargePageHandlers;
