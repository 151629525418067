import { useState, useEffect, useCallback } from "react";

export enum BreakPoint {
  L = "L",
  M = "M",
  S = "S",
  XL = "XL",
  XS = "XS",
}

/**
 * Screen size hook.
 * @returns
 */
export const useScreenSize = () => {
  const isClient = typeof window !== "undefined";

  const getSize = useCallback(() => {
    return {
      height: isClient ? window.innerHeight : 0,
      screen: BreakPoint.S,
      width: isClient ? window.innerWidth : 0,
    };
  }, [isClient]);

  const [screenSize, setScreenSize] = useState(getSize);

  const handleResize = useCallback(() => {
    let screen = BreakPoint.XS;
    const { innerHeight, innerWidth } = window;
    if (innerHeight >= 576 && innerHeight < 768) {
      screen = BreakPoint.S;
    }
    if (innerHeight >= 768 && innerHeight < 992) {
      screen = BreakPoint.M;
    }
    if (innerHeight >= 992 && innerHeight < 1200) {
      screen = BreakPoint.L;
    }
    if (innerHeight >= 1200) {
      screen = BreakPoint.XL;
    }

    setScreenSize({ height: innerHeight, screen, width: innerWidth });
  }, []);

  useEffect(() => {
    if (!isClient) {
      return;
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isClient, handleResize]);

  return screenSize;
};
