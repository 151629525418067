import * as Factory from "factory.ts";
import { DefaultBodyType } from "msw";

export const processOutCardsPath = "https://api.processout.com/cards";
export const processOutCustomersPath = "https://api.processout.com/customers/*";
export const processOutInvoicesPath = "https://api.processout.com/invoices/*";
export const sandboxApiPath = "https://api.sandbox.checkout.com/*";
export const checkoutProcessoutApiPath = "https://checkout.processout.com/*";

export const processoutCardsFactory = Factory.Sync.makeFactory<DefaultBodyType>({
  card: {
    address1: null,
    address2: null,
    app_color_depth: 24,
    app_java_enabled: false,
    app_language: "fr-FR",
    app_screen_heigh: 1080,
    app_screen_width: 1920,
    app_timezone_offset: -120,
    avs_check: "unavailable",
    bank_name: null,
    brand: "classic",
    category: "consumer",
    checks: {
      avs_check: "unavailable",
      avs_name_check: "unavailable",
      avs_name_check_reason: null,
      avs_postal_check: "unavailable",
      avs_postal_check_reason: null,
      avs_street_check: "unavailable",
      avs_street_check_reason: null,
      cvc_check: "unavailable",
      cvc_check_reason: null,
    },
    city: null,
    co_scheme: null,
    country_code: "GB",
    created_at: "2023-06-08T11:54:52.077505892Z",
    cvc_check: "unavailable",
    cvc_tokenized: true,
    exp_month: 1,
    exp_year: 2025,
    expires_soon: false,
    fingerprint: "17650f4fb25bea2d5ea5c12bd4366eb2d3a0a373831fab30e19b54ca4f38e9c0",
    header_accept: "*",
    header_referer: "https://js.processout.com/",
    id: "card_fwPyzkYS3FxAORLa2VLuuEa3wbNlTkBS",
    iin: "424242",
    ip_address: "91.164.214.157",
    last_4_digits: "4242",
    metadata: {},
    name: null,
    payment_source_detail: null,
    preferred_scheme: null,
    project_id: "proj_FKw0SeFqxhIEePPEiufcbTHTNAqML4rE",
    sandbox: true,
    scheme: "visa",
    state: null,
    token_type: null,
    tokenization_method: null,
    type: "credit",
    update_reason_code: null,
    update_type: null,
    updated_at: "2023-06-08T11:54:52.077505803Z",
    used: null,
    user_agent:
      "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
    zip: null,
  },
  success: true,
});

export const processoutCustomersFactory = Factory.Sync.makeFactory<DefaultBodyType>({
  success: true,
});

export const processOutCardTokenizeFactory = Factory.Sync.makeFactory<DefaultBodyType>({
  success: true,
  token: {
    can_get_balance: null,
    cancel_url: null,
    card: {
      address1: null,
      address2: null,
      app_color_depth: 24,
      app_java_enabled: false,
      app_language: "fr-FR",
      app_screen_height: 1080,
      app_screen_width: 1920,
      app_timezone_offset: -120,
      avs_check: "unknown",
      bank_name: "STRIPE PAYMENTS UK LIMITED",
      brand: "visa classic",
      category: "consumer",
      checks: {
        avs_check: "unknown",
        avs_name_check: "unknown",
        avs_name_check_reason: null,
        avs_postal_check: "unknown",
        avs_postal_check_reason: null,
        avs_street_check: "unknown",
        avs_street_check_reason: null,
        cvc_check: "passed",
        cvc_check_reason: null,
      },
      city: null,
      co_scheme: null,
      country_code: "GB",
      created_at: "2023-06-08T12:08:23.466365Z",
      cvc_check: "passed",
      cvc_tokenized: true,
      exp_month: 1,
      exp_year: 2025,
      expires_soon: false,
      fingerprint: "17650f4fb25bea2d5ea5c12bd4366eb2d3a0a373831fab30e19b54ca4f38e9c0",
      header_accept: "*",
      header_referer: "https://js.processout.com/",
      id: "card_4UqULkVF5EzqrsUpz5f5syOPDiG1EWZZ",
      iin: "424242",
      ip_address: "91.164.214.157",
      last_4_digits: "4242",
      metadata: {},
      name: null,
      payment_source_detail: null,
      preferred_scheme: null,
      project_id: "proj_FKw0SeFqxhIEePPEiufcbTHTNAqML4rE",
      sandbox: true,
      scheme: "visa",
      state: null,
      token_type: null,
      tokenization_method: null,
      type: "credit",
      update_reason_code: null,
      update_type: null,
      updated_at: "2023-06-08T12:08:52.622988Z",
      used: null,
      user_agent:
        "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
      zip: null,
    },
    card_id: "card_4UqULkVF5EzqrsUpz5f5syOPDiG1EWZZ",
    created_at: "2023-06-08T12:08:01.32573Z",
    customer_id: "cust_NE8JyHGaqwr26h3Vdt3CoeQMbgugVaSR",
    description: null,
    gateway_configuration_id: null,
    gateway_configuration_sub_account: null,
    id: "tok_MMqLUEz766waPuXfmnx8F4sKjssHE50n",
    invoice_id: "iv_e2TKHchwg3ymaaRsSdWCAcnCt7PParip",
    is_chargeable: true,
    is_default: true,
    is_subscription_only: false,
    manual_invoice_cancellation: null,
    metadata: {},
    return_url: null,
    summary: "**** **** **** 4242",
    type: "card",
    verification_status: "success",
  },
});

export const processoutInvoicesFactory = Factory.Sync.makeFactory<DefaultBodyType>({
  success: true,
});
