import classNames from "classnames";
import { FunctionComponent, ReactNode } from "react";

interface Props {
  business?: boolean;
  children?: ReactNode;
  className?: string;
  fontWeight?: "light" | "semiBold" | "medium";
  size?: "large" | "medium" | "small";
  subTitle?: string | undefined;
  title?: string | undefined | ReactNode;
}

const Header: FunctionComponent<Props> = ({
  children,
  className,
  size = "large",
  subTitle,
  title,
  fontWeight = "semiBold",
  business = false,
}) => {
  return (
    <header
      className={classNames("w-full bg-white px-4 py-2", className, {
        "md:px-8-safe text-primary-default pt-6 md:pt-2": business,
        "text-neutral-900": !business,
      })}
    >
      {subTitle ? <div className="text-base font-light text-neutral-700">{subTitle}</div> : null}

      <div className="flex flex-col items-stretch gap-2">
        {title ? (
          <div
            className={classNames("whitespace-pre-line", {
              "font-light": fontWeight === "light",
              "font-medium": fontWeight === "medium",
              "font-semibold": fontWeight === "semiBold",
              "text-2xl": size === "medium",
              "text-3xl": size === "large",
              "text-lg": size === "small",
            })}
          >
            {title}
          </div>
        ) : null}

        {children}
      </div>
    </header>
  );
};

export default Header;
