import { useNotification, useTimer } from "hooks";
import { Suspense, lazy, useCallback, useEffect } from "react";

const NotificationWrapper = lazy(() => import("../Notification/NotificationWrapper"));

const NotificationContainer = () => {
  const notification = useNotification((state) => state.notification);
  const resetNotification = useNotification((state) => state.resetNotification);

  const handleElapsed = useCallback(() => {
    resetNotification();
    if (notification?.onNotificationHidden) {
      notification.onNotificationHidden();
    }
  }, [notification, resetNotification]);

  const { restartTimer, stopTimer } = useTimer({
    onElapsed: handleElapsed,
    totalDurationInMilliseconds: notification?.duration || 5000,
  });

  const hideNotification = useCallback(() => {
    stopTimer();
    resetNotification();
  }, [stopTimer, resetNotification]);

  const handleActionClick = () => {
    hideNotification();
    if (notification?.onActionClick) {
      notification.onActionClick();
    }
    if (notification?.onNotificationHidden) {
      notification.onNotificationHidden();
    }
  };

  useEffect(() => {
    if (notification?.autoHide) {
      restartTimer();
    }
  }, [notification?.autoHide, restartTimer]);

  return (
    <Suspense>
      <NotificationWrapper {...notification} onActionClick={handleActionClick} />
    </Suspense>
  );
};

export default NotificationContainer;
