import { useField } from "formik";
import { ComponentProps, forwardRef } from "react";

import Input from "../Input";

import FieldWrapper, { FieldWrapperProps } from "./FieldWrapper";

export interface Props extends FieldWrapperProps {
  children?: never;
  errorOnField?: boolean;
  inputProps?: ComponentProps<typeof Input>;
  onClear?: () => void;
  serverError?: boolean;
  validate?: (value: string) => string | undefined;
  variant?: "small" | "medium";
}

const InputField = forwardRef<HTMLInputElement, Props>(
  ({ className, inputProps, label, name, validate, onClear, serverError = false, variant = "medium" }, ref) => {
    const [field, { error, initialValue, touched }, { setValue }] = useField<string>({ name, validate });

    const handleClear = () => {
      if (onClear) {
        onClear();
      } else setValue((inputProps?.initialValue as string) ?? initialValue ?? "", false);
    };

    return (
      <FieldWrapper className={className} label={label} name={name} disabled={inputProps?.disabled} variant={variant}>
        <Input
          ref={ref}
          state={(error && touched) || serverError ? "invalid" : "none"}
          onClear={handleClear}
          {...field}
          {...inputProps}
          serverError={serverError}
          variant={variant}
        />
      </FieldWrapper>
    );
  }
);

export default InputField;
