import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  Boolean: { input: boolean; output: boolean };
  /** A valid URL, transported as a string */
  File: { input: File; output: File };
  Float: { input: number; output: number };
  ID: { input: string; output: string };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
  Int: { input: number; output: number };
  String: { input: string; output: string };
};

export type Access = {
  __typename?: "Access";
  accessId?: Maybe<Scalars["ID"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  accessKit?: Maybe<Array<AccessStep>>;
  /** @deprecated Will be removed in version 4.36 */
  carEntryFinalStep?: Maybe<CarEntryFinalStep>;
  /** @deprecated Will be removed in version 4.36 */
  carEntryInitialStep?: Maybe<CarEntryInitialStep>;
  /** @deprecated Will be removed in version 4.36 */
  carEntrySteps?: Maybe<Array<AccessStep>>;
  /** @deprecated Will be removed in version 4.36 */
  carExitSteps?: Maybe<Array<AccessStep>>;
  digicode?: Maybe<Scalars["String"]["output"]>;
  step?: Maybe<Scalars["Int"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type AccessActionButton = {
  __typename?: "AccessActionButton";
  /** @deprecated Will be removed in version 4.36 */
  confirmMessage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  fallbackResult?: Maybe<AccessResult>;
  /** @deprecated Will be removed in version 4.36 */
  key?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  label?: Maybe<Scalars["String"]["output"]>;
};

export type AccessActionResult = {
  __typename?: "AccessActionResult";
  /** @deprecated Will be removed in version 4.36 */
  actionKey?: Maybe<Scalars["String"]["output"]>;
};

export enum AccessImageKey {
  /** Barrier */
  Barrier = "BARRIER",
  /** Barrier */
  CarExit = "CAR_EXIT",
  /** Park */
  Park = "PARK",
  /** Unknown */
  Unknown = "UNKNOWN",
}

export type AccessInstruction = {
  __typename?: "AccessInstruction";
  /** @deprecated Will be removed in version 4.36 */
  label?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  subLabel?: Maybe<Scalars["String"]["output"]>;
};

export type AccessPopupResult = {
  __typename?: "AccessPopupResult";
  /** @deprecated Will be removed in version 4.36 */
  content?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  dismissActionKey?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  primaryActionButton?: Maybe<AccessActionButton>;
  /** @deprecated Will be removed in version 4.36 */
  tertiaryActionButton?: Maybe<AccessActionButton>;
  /** @deprecated Will be removed in version 4.36 */
  title?: Maybe<Scalars["String"]["output"]>;
};

export type AccessResult = AccessActionResult | AccessPopupResult;

export type AccessStep = {
  __typename?: "AccessStep";
  /** @deprecated Will be removed in version 4.36 */
  accessKitTitle?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  genericImageKey?: Maybe<AccessImageKey>;
  /** @deprecated Will be removed in version 4.36 */
  instructions?: Maybe<Array<AccessInstruction>>;
  /** @deprecated Will be removed in version 4.36 */
  primaryActionButton?: Maybe<AccessActionButton>;
  /** @deprecated Will be removed in version 4.36 */
  step?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  tertiaryActionButton?: Maybe<AccessActionButton>;
  /** @deprecated Will be removed in version 4.36 */
  title?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  type?: Maybe<AccessType>;
};

export enum AccessType {
  /** Car entry */
  CarEntry = "CAR_ENTRY",
  /** Car exit */
  CarExit = "CAR_EXIT",
  /** Pedestrian entry */
  PedestrianEntry = "PEDESTRIAN_ENTRY",
  /** Pedestrian exit */
  PedestrianExit = "PEDESTRIAN_EXIT",
}

export type Account = Node & {
  __typename?: "Account";
  accountUser?: Maybe<AccountUser>;
  accountUsers: AccountUserConnection;
  autochargeConfigs: AutochargeConfigConnection;
  bookingIntent?: Maybe<BookingIntent>;
  canShowPaymentMethod: Scalars["Boolean"]["output"];
  capacityBookings: CapacityBookingConnection;
  capacityOccurrences: CapacityOccurrenceConnection;
  car?: Maybe<Car>;
  cars: CarConnection;
  charge?: Maybe<Charge>;
  charges: ChargeConnection;
  chargesCount: Scalars["Int"]["output"];
  /** @deprecated Use accountUser instead */
  currentRemoteCharge?: Maybe<RemoteCharge>;
  customerDetail?: Maybe<CustomerDetail>;
  dashboardIframeUrl?: Maybe<Scalars["String"]["output"]>;
  externalPaymentLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use accountUsers instead */
  fallbackCar?: Maybe<Car>;
  hasPaymentMethods: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  instantChargeIntent?: Maybe<InstantChargeIntent>;
  invitation?: Maybe<Invitation>;
  invitations: InvitationConnection;
  /** Mandatory informations by country */
  invoicingInformations: Array<InvoicingInformation>;
  label: Scalars["String"]["output"];
  logoUrl?: Maybe<Scalars["String"]["output"]>;
  paymentIntent?: Maybe<PaymentIntent>;
  /** @deprecated Use accountUser instead */
  paymentMethod?: Maybe<PaymentMethod>;
  /** @deprecated Use accountUser instead */
  paymentMethods: PaymentMethodConnection;
  pro: Scalars["Boolean"]["output"];
  /** @deprecated Use accountUser instead */
  processoutCustomerRef: Scalars["String"]["output"];
  /** @deprecated Use accountUser instead */
  processoutTokenToVerify?: Maybe<Scalars["String"]["output"]>;
  selfServeUrl?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use accountUsers instead */
  singleBooking?: Maybe<SingleBooking>;
  /** @deprecated Use accountUsers instead */
  singleBookings: Array<SingleBooking>;
  userDeletionIntent?: Maybe<UserDeletionIntent>;
  /** @deprecated Use accountUsers instead */
  users: UserConnection;
};

export type AccountAccountUserArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountAccountUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  role?: InputMaybe<AccountUserRole>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountAutochargeConfigsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AccountBookingIntentArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountCapacityBookingsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AccountCapacityOccurrencesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AccountCarArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountCarsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AccountChargeArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountChargesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filters?: InputMaybe<ChargeFiltersType>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<ChargeSortType>;
};

export type AccountInstantChargeIntentArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountInvitationArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountPaymentIntentArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountPaymentMethodArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountPaymentMethodsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  onlyValid?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AccountSingleBookingArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountUserDeletionIntentArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for Account. */
export type AccountConnection = {
  __typename?: "AccountConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Account>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AccountEdge = {
  __typename?: "AccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Account>;
};

export enum AccountMissingInfos {
  /** Billing infos */
  BillingInfos = "BILLING_INFOS",
  /** Company */
  Company = "COMPANY",
  /** Documents */
  Documents = "DOCUMENTS",
}

export type AccountOnboardingStatus = {
  __typename?: "AccountOnboardingStatus";
  /** @deprecated Use pro_type instead */
  companyType?: Maybe<BusinessCompanyType>;
  country?: Maybe<CountryObject>;
  missingInfos: Array<AccountMissingInfos>;
  proType?: Maybe<ProAccountType>;
  proTypeLabel?: Maybe<ProAccountLabelType>;
  sessionToken?: Maybe<Scalars["String"]["output"]>;
  status: AccountValidationStatus;
};

export type AccountUser = Node & {
  __typename?: "AccountUser";
  account: Account;
  active: Scalars["Boolean"]["output"];
  alternativePaymentMethods: AlternativePaymentMethodConnection;
  bulkInvitationFileUrl?: Maybe<Scalars["String"]["output"]>;
  canCreateCapacityBooking: Scalars["Boolean"]["output"];
  canCreateCar: Scalars["Boolean"]["output"];
  canCreateSingleBooking: Scalars["Boolean"]["output"];
  canDelete: Scalars["Boolean"]["output"];
  canEdit: Scalars["Boolean"]["output"];
  canEditCompany: Scalars["Boolean"]["output"];
  canEditRole: Scalars["Boolean"]["output"];
  canListCapacityBookings: Scalars["Boolean"]["output"];
  canListCars: Scalars["Boolean"]["output"];
  canListPayments: Scalars["Boolean"]["output"];
  canListPeriodicInvoices: Scalars["Boolean"]["output"];
  canListUsers: Scalars["Boolean"]["output"];
  canShowCompany: Scalars["Boolean"]["output"];
  canShowDashboard: Scalars["Boolean"]["output"];
  canUseApplePay: Scalars["Boolean"]["output"];
  canUseGooglePay: Scalars["Boolean"]["output"];
  canUsePromoCode: Scalars["Boolean"]["output"];
  capacityOccurrences: CapacityOccurrenceConnection;
  capacityUsage?: Maybe<CapacityUsage>;
  capacityUsages: CapacityUsageConnection;
  charge?: Maybe<Charge>;
  charges: ChargeConnection;
  company?: Maybe<Company>;
  currentRemoteCharge?: Maybe<RemoteCharge>;
  emspBadgeAvailable: Scalars["Boolean"]["output"];
  fallbackCar?: Maybe<Car>;
  firstname: Scalars["String"]["output"];
  fullname: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  invitedBy?: Maybe<AccountUser>;
  joinedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  lastname?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethods: PaymentMethodConnection;
  periodicInvoices: PeriodicInvoiceConnection;
  periodicInvoicesLabel?: Maybe<Scalars["String"]["output"]>;
  processoutCustomerRef: Scalars["String"]["output"];
  processoutTokenToVerify?: Maybe<Scalars["String"]["output"]>;
  recapAvailable: Scalars["Boolean"]["output"];
  recapMetrics?: Maybe<AccountUserMetric>;
  recapWebviews?: Maybe<Array<Webview>>;
  referralProgram?: Maybe<ReferralProgram>;
  referralRewards: Array<ReferralProgramReward>;
  roamingPaymentMethods: RoamingPaymentMethodConnection;
  role: AccountUserRole;
  singleBooking?: Maybe<SingleBooking>;
  singleBookings: Array<SingleBooking>;
  user: User;
  webviews: Array<Webview>;
};

export type AccountUserAlternativePaymentMethodsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  stationId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountUserCapacityOccurrencesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AccountUserCapacityUsageArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountUserCapacityUsagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AccountUserChargeArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountUserChargesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AccountUserPaymentMethodArgs = {
  id: Scalars["ID"]["input"];
};

export type AccountUserPaymentMethodsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AccountUserPeriodicInvoicesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  afterDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  beforeDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AccountUserProcessoutTokenToVerifyArgs = {
  invoiceReturnUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountUserRoamingPaymentMethodsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AccountUserSingleBookingArgs = {
  id: Scalars["ID"]["input"];
};

/** The connection type for AccountUser. */
export type AccountUserConnection = {
  __typename?: "AccountUserConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountUserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AccountUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AccountUserEdge = {
  __typename?: "AccountUserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<AccountUser>;
};

export type AccountUserMetric = Node & {
  __typename?: "AccountUserMetric";
  accountUser: AccountUser;
  id: Scalars["ID"]["output"];
  networkShare: Scalars["Int"]["output"];
  preferredStations: Array<AccountUserMetricStation>;
  topTierAccountUsersPercentage: Scalars["Int"]["output"];
  totalChargesCount: Scalars["Int"]["output"];
  totalDistance: Scalars["Int"]["output"];
  totalEnergy: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type AccountUserMetricStation = {
  __typename?: "AccountUserMetricStation";
  chargesCount: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export enum AccountUserRole {
  /** User who can administrate the account */
  Admin = "ADMIN",
  /** User who can charge only */
  Member = "MEMBER",
}

export enum AccountValidationStatus {
  /** In progress */
  InProgress = "IN_PROGRESS",
  /** Validation not required */
  NotRequired = "NOT_REQUIRED",
  /** Pending manual validation */
  PendingManualValidation = "PENDING_MANUAL_VALIDATION",
  /** Rejected */
  Rejected = "REJECTED",
  /** Validated */
  Validated = "VALIDATED",
}

/** Autogenerated input type of ActivateAutochargeConfig */
export type ActivateAutochargeConfigInput = {
  autochargeConfigId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
  roamingPaymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of ActivateAutochargeConfig. */
export type ActivateAutochargeConfigPayload = {
  __typename?: "ActivateAutochargeConfigPayload";
  autochargeConfig?: Maybe<AutochargeConfig>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

export type AlternativePaymentMethod = Node & {
  __typename?: "AlternativePaymentMethod";
  displayName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  issuerCountry: Scalars["String"]["output"];
  logoUrl: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  processoutGatewayConfRef: Scalars["String"]["output"];
};

/** The connection type for AlternativePaymentMethod. */
export type AlternativePaymentMethodConnection = {
  __typename?: "AlternativePaymentMethodConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AlternativePaymentMethodEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AlternativePaymentMethod>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AlternativePaymentMethodEdge = {
  __typename?: "AlternativePaymentMethodEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<AlternativePaymentMethod>;
};

export enum AmenityEnum {
  /** Coffee place pictogram */
  Cafe = "CAFE",
  /** Hotel pictogram */
  Hotel = "HOTEL",
  /** Restaurant pictogram */
  Restaurant = "RESTAURANT",
  /** Shower pictogram */
  Shower = "SHOWER",
  /** Snacking place pictogram */
  Snack = "SNACK",
  /** Store pictogram */
  Store = "STORE",
  /** Supermarket pictogram */
  Supermarket = "SUPERMARKET",
  /** Toilet pictogram */
  Toilet = "TOILET",
  /** Wifi pictogram */
  Wifi = "WIFI",
}

export type AnonymousSession = Node & {
  __typename?: "AnonymousSession";
  account?: Maybe<Account>;
  currentRemoteCharge?: Maybe<RemoteCharge>;
  id: Scalars["ID"]["output"];
  instantChargeIntent?: Maybe<InstantChargeIntent>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type AutochargeConfig = Node & {
  __typename?: "AutochargeConfig";
  account: Account;
  accountUser: AccountUser;
  availability?: Maybe<AutochargeConfigAvailabilityEnum>;
  car: Car;
  enabledByUser: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  paymentMethod?: Maybe<PaymentMethod>;
  roamingPaymentMethod?: Maybe<RoamingPaymentMethod>;
};

export type AutochargeConfigAvailabilityCard = {
  __typename?: "AutochargeConfigAvailabilityCard";
  cardType: CardTypeEnum;
  link: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export enum AutochargeConfigAvailabilityEnum {
  /** When vid is present, config is enabled_by_user and payment method is verified */
  Activated = "ACTIVATED",
  /** When car is compatible and config is not enabled_by_user */
  Available = "AVAILABLE",
  /** When config is available, vid blank and payment method not verified */
  WaitingForPairing = "WAITING_FOR_PAIRING",
}

/** The connection type for AutochargeConfig. */
export type AutochargeConfigConnection = {
  __typename?: "AutochargeConfigConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AutochargeConfigEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AutochargeConfig>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AutochargeConfigEdge = {
  __typename?: "AutochargeConfigEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<AutochargeConfig>;
};

export type BookingIntent = Node & {
  __typename?: "BookingIntent";
  account: Account;
  accountUser: User;
  at: Scalars["ISO8601DateTime"]["output"];
  car: Car;
  choices: Array<BookingIntentChoice>;
  closestUnbookableStations: Array<ClosestUnbookableStation>;
  id: Scalars["ID"]["output"];
  originLatitude: Scalars["Float"]["output"];
  originLongitude: Scalars["Float"]["output"];
  /** @deprecated Use pricing_details in booking intent choice instead */
  pricingDetails?: Maybe<Array<PricingDetail>>;
  /** @deprecated Use promotion in booking intent choice instead */
  promoCode?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use accountUser instead */
  user: User;
};

export type BookingIntentChoice = Node & {
  __typename?: "BookingIntentChoice";
  available: Scalars["Boolean"]["output"];
  bookingIntent: BookingIntent;
  connector?: Maybe<Connector>;
  id: Scalars["ID"]["output"];
  pricingDetails?: Maybe<Array<PricingDetail>>;
  promotion?: Maybe<Promotion>;
  rank: Scalars["Int"]["output"];
  recommended: Scalars["Boolean"]["output"];
  spot?: Maybe<Spot>;
  startingAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  station: Station;
  venueGeoDistance?: Maybe<Scalars["Int"]["output"]>;
  venueRoadDistance?: Maybe<Scalars["Int"]["output"]>;
  venueRoadDuration?: Maybe<Scalars["Int"]["output"]>;
};

export enum BookingIntentTarget {
  /** Where the user is */
  Origin = "ORIGIN",
  /** A specific position */
  Position = "POSITION",
  /** A specific Station */
  Station = "STATION",
}

/** Autogenerated input type of BulkReinviteUsers */
export type BulkReinviteUsersInput = {
  accountId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of BulkReinviteUsers. */
export type BulkReinviteUsersPayload = {
  __typename?: "BulkReinviteUsersPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  sentReinvitationsCount: Scalars["Int"]["output"];
  successMessage?: Maybe<Scalars["String"]["output"]>;
  warningMessage?: Maybe<Scalars["String"]["output"]>;
};

export enum BusinessCompanyIdentificationType {
  /** Siret */
  Siret = "siret",
  /** Vat */
  Vat = "vat",
}

export enum BusinessCompanyType {
  /** Fleet */
  Fleet = "FLEET",
  /** Independant */
  Independant = "INDEPENDANT",
}

export type BusinessDocument = {
  __typename?: "BusinessDocument";
  documentType: DocumentType;
  label: Scalars["String"]["output"];
  sublabel: Scalars["String"]["output"];
};

/** Autogenerated input type of CallAccessAction */
export type CallAccessActionInput = {
  accessId?: InputMaybe<Scalars["ID"]["input"]>;
  actionKey?: InputMaybe<Scalars["String"]["input"]>;
  capacityUsageId?: InputMaybe<Scalars["ID"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  singleBookingId?: InputMaybe<Scalars["ID"]["input"]>;
  step?: InputMaybe<Scalars["Int"]["input"]>;
  type?: InputMaybe<AccessType>;
};

/** Autogenerated return type of CallAccessAction. */
export type CallAccessActionPayload = {
  __typename?: "CallAccessActionPayload";
  capacityUsage?: Maybe<CapacityUsage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  result?: Maybe<AccessResult>;
  singleBooking?: Maybe<SingleBooking>;
};

/** Autogenerated input type of CancelCapacityUsage */
export type CancelCapacityUsageInput = {
  capacityUsageId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CancelCapacityUsage. */
export type CancelCapacityUsagePayload = {
  __typename?: "CancelCapacityUsagePayload";
  capacityUsage?: Maybe<CapacityUsage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CancelRemoteCharge */
export type CancelRemoteChargeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  remoteChargeId: Scalars["ID"]["input"];
};

/** Autogenerated return type of CancelRemoteCharge. */
export type CancelRemoteChargePayload = {
  __typename?: "CancelRemoteChargePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  remoteCharge?: Maybe<RemoteCharge>;
};

/** Autogenerated input type of CancelSingleBooking */
export type CancelSingleBookingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  singleBookingId: Scalars["ID"]["input"];
};

/** Autogenerated return type of CancelSingleBooking. */
export type CancelSingleBookingPayload = {
  __typename?: "CancelSingleBookingPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  singleBooking?: Maybe<SingleBooking>;
};

export type CapacityBooking = Node & {
  __typename?: "CapacityBooking";
  account: Account;
  capacityOccurrences: CapacityOccurrenceConnection;
  duration: Scalars["Int"]["output"];
  endAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  recurrenceRuleIcal?: Maybe<Scalars["String"]["output"]>;
  recurrenceUntil?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  recurring: Scalars["Boolean"]["output"];
  spotCount: Scalars["Int"]["output"];
  startAt: Scalars["ISO8601DateTime"]["output"];
  station: Station;
};

export type CapacityBookingCapacityOccurrencesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for CapacityBooking. */
export type CapacityBookingConnection = {
  __typename?: "CapacityBookingConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CapacityBookingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CapacityBooking>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CapacityBookingEdge = {
  __typename?: "CapacityBookingEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<CapacityBooking>;
};

export type CapacityOccurrence = Node & {
  __typename?: "CapacityOccurrence";
  account: Account;
  capacityBooking: CapacityBooking;
  duration: Scalars["Int"]["output"];
  endAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  spotCount: Scalars["Int"]["output"];
  spots: SpotConnection;
  startAt: Scalars["ISO8601DateTime"]["output"];
  station: Station;
};

export type CapacityOccurrenceSpotsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for CapacityOccurrence. */
export type CapacityOccurrenceConnection = {
  __typename?: "CapacityOccurrenceConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CapacityOccurrenceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CapacityOccurrence>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CapacityOccurrenceEdge = {
  __typename?: "CapacityOccurrenceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<CapacityOccurrence>;
};

export type CapacityUsage = Node & {
  __typename?: "CapacityUsage";
  access: Access;
  canBeCanceled: Scalars["Boolean"]["output"];
  canBeFinished: Scalars["Boolean"]["output"];
  canDisplayAccess: Scalars["Boolean"]["output"];
  canExit: Scalars["Boolean"]["output"];
  capacityOccurrence: CapacityOccurrence;
  endAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  spots: SpotConnection;
  startAt: Scalars["ISO8601DateTime"]["output"];
  station: Station;
};

export type CapacityUsageSpotsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for CapacityUsage. */
export type CapacityUsageConnection = {
  __typename?: "CapacityUsageConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CapacityUsageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CapacityUsage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CapacityUsageEdge = {
  __typename?: "CapacityUsageEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<CapacityUsage>;
};

export type Car = Node & {
  __typename?: "Car";
  account: Account;
  autochargeAvailability?: Maybe<AutochargeConfigAvailabilityEnum>;
  autochargeAvailabilityCard?: Maybe<AutochargeConfigAvailabilityCard>;
  autochargeCompatible: Scalars["Boolean"]["output"];
  autochargeConfig?: Maybe<AutochargeConfig>;
  canBeDeleted: Scalars["Boolean"]["output"];
  canBeEdited: Scalars["Boolean"]["output"];
  carBrand: CarBrand;
  carModel?: Maybe<CarModel>;
  carVersion?: Maybe<CarVersion>;
  friendlyIdentifier: Scalars["String"]["output"];
  friendlyName: Scalars["String"]["output"];
  friendlyVersion: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  /** @deprecated Use license_plate_country_object instead */
  licensePlateCountry?: Maybe<Country>;
  licensePlateCountryObject?: Maybe<CountryObject>;
  licensePlateNumber?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Do not use anymore. Use only manual plugs if applicable. */
  manualModelName?: Maybe<Scalars["String"]["output"]>;
  manualPlugs: Array<ConnectorPlug>;
  /** @deprecated Do not use anymore. Use only manual plugs if applicable. */
  manualVersionName?: Maybe<Scalars["String"]["output"]>;
  missingInfos: Scalars["Boolean"]["output"];
  pro: Scalars["Boolean"]["output"];
  userVerifiedLicensePlate: Scalars["Boolean"]["output"];
};

export type CarBrand = Node & {
  __typename?: "CarBrand";
  carModels: Array<CarModel>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

/** The connection type for CarBrand. */
export type CarBrandConnection = {
  __typename?: "CarBrandConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CarBrandEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CarBrand>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CarBrandEdge = {
  __typename?: "CarBrandEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<CarBrand>;
};

/** The connection type for Car. */
export type CarConnection = {
  __typename?: "CarConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CarEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Car>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CarEdge = {
  __typename?: "CarEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Car>;
};

export type CarEntryFinalStep = {
  __typename?: "CarEntryFinalStep";
  /** @deprecated Will be removed in version 4.36 */
  manualContent?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  qrCodeContent?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CarEntryInitialStep = {
  __typename?: "CarEntryInitialStep";
  /** @deprecated Will be removed in version 4.36 */
  destinationAddress?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  destinationLatitude?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  destinationLongitude?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  destinationName?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  primaryActionButton?: Maybe<AccessActionButton>;
  /** @deprecated Will be removed in version 4.36 */
  subTitle?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CarModel = Node & {
  __typename?: "CarModel";
  carBrand: CarBrand;
  carVersions: Array<CarVersion>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type CarVersion = Node & {
  __typename?: "CarVersion";
  carModel: CarModel;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  plugs: Array<ConnectorPlug>;
  usableBatteryCapacity: Scalars["Int"]["output"];
  yearEnd: Scalars["Int"]["output"];
  yearStart: Scalars["Int"]["output"];
};

export type Card = {
  __typename?: "Card";
  action: CardActionEnum;
  actionLabel?: Maybe<Scalars["String"]["output"]>;
  deeplink?: Maybe<Scalars["String"]["output"]>;
  overtitle?: Maybe<Scalars["String"]["output"]>;
  picture?: Maybe<CardPictureEnum>;
  text?: Maybe<Scalars["String"]["output"]>;
  theme: CardThemeEnum;
  title?: Maybe<Scalars["String"]["output"]>;
  webviewLink?: Maybe<Scalars["String"]["output"]>;
};

export enum CardActionEnum {
  /** Icon */
  Icon = "icon",
  /** Label */
  Label = "label",
  /** None */
  None = "none",
}

export enum CardExpirationStatus {
  /** Available */
  Available = "available",
  /** Expired */
  Expired = "expired",
  /** Soon Expired */
  SoonExpired = "soon_expired",
}

export enum CardPictureEnum {
  /** Electra business logo */
  ElectraBusinessLogo = "ELECTRA_BUSINESS_LOGO",
  /** Fork pictoram */
  Fork = "FORK",
  /** Generic pictogram */
  Generic = "GENERIC",
  /** Groceries pictogram */
  Groceries = "GROCERIES",
  /** Ok Hand with sparkles pictogram */
  PerfectHand = "PERFECT_HAND",
  /** Pillow pictogram */
  Pillow = "PILLOW",
  /** Rate app */
  RateApp = "RATE_APP",
  /** Reco fast */
  RecoFast = "RECO_FAST",
  /** Reco feature */
  RecoFeature = "RECO_FEATURE",
  /** Shopping pictogram */
  Shopping = "SHOPPING",
}

export enum CardThemeEnum {
  /** Dark */
  Dark = "dark",
  /** Green */
  Green = "green",
  /** Light */
  Light = "light",
}

export enum CardTypeEnum {
  /** Info */
  Info = "INFO",
  /** Warning */
  Warning = "WARNING",
}

/** Autogenerated input type of ChangeRemoteChargeObjective */
export type ChangeRemoteChargeObjectiveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  remoteChargeId: Scalars["ID"]["input"];
  targetSoc: Scalars["Int"]["input"];
};

/** Autogenerated return type of ChangeRemoteChargeObjective. */
export type ChangeRemoteChargeObjectivePayload = {
  __typename?: "ChangeRemoteChargeObjectivePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  remoteCharge?: Maybe<RemoteCharge>;
};

export type Charge = Node & {
  __typename?: "Charge";
  account: Account;
  accountTagLabel?: Maybe<Scalars["String"]["output"]>;
  accountUser?: Maybe<AccountUser>;
  canShowPricing: Scalars["Boolean"]["output"];
  car?: Maybe<Car>;
  currency: Currency;
  date: Scalars["ISO8601DateTime"]["output"];
  details?: Maybe<Array<ChargeDetail>>;
  driverFullname?: Maybe<Scalars["String"]["output"]>;
  driverPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  endSoc?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  invoice?: Maybe<Invoice>;
  invoiceHandledByEmsp: Scalars["Boolean"]["output"];
  source: ChargeSourceEnum;
  startSoc?: Maybe<Scalars["Int"]["output"]>;
  station: Station;
  taxes?: Maybe<Array<ChargeDetail>>;
  totalAmountCents: Scalars["Int"]["output"];
  totalDuration: Scalars["Int"]["output"];
  totalEnergy: Scalars["Int"]["output"];
  /** @deprecated Use accountUser instead */
  user?: Maybe<User>;
};

/** The connection type for Charge. */
export type ChargeConnection = {
  __typename?: "ChargeConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChargeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Charge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ChargeDetail = {
  __typename?: "ChargeDetail";
  amountCents: Scalars["Int"]["output"];
  currency: Currency;
  label: Scalars["String"]["output"];
  sublabel?: Maybe<Scalars["String"]["output"]>;
  type: ChargeDetailEnum;
};

export enum ChargeDetailEnum {
  /** Coupon */
  Coupon = "coupon",
  /** Discount */
  Discount = "discount",
  /** Price */
  Price = "price",
}

/** An edge in a connection. */
export type ChargeEdge = {
  __typename?: "ChargeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Charge>;
};

export type ChargeFiltersType = {
  accountTagLabel?: InputMaybe<Scalars["String"]["input"]>;
  carIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  chargeSources?: InputMaybe<Array<ChargeSourceEnum>>;
  driverNameOrPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["String"]["input"]>;
  stationName?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChargePoint = Node & {
  __typename?: "ChargePoint";
  availability?: Maybe<ChargePointAvailability>;
  connectors: Array<Connector>;
  /** @deprecated Field not needed any longer due to UI updates */
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field not needed any longer due to UI updates */
  errorTitle?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  /** @deprecated Field not needed any longer due to UI updates */
  noConnectorAvailable: Scalars["Boolean"]["output"];
  /** @deprecated PricingDetail is now on ConnectorType */
  pricingDetail?: Maybe<Scalars["String"]["output"]>;
  publicCode: Scalars["String"]["output"];
  station: Station;
};

export type ChargePointAvailabilityArgs = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ChargePointAvailability = {
  __typename?: "ChargePointAvailability";
  cardType?: Maybe<ChargePointAvailabilityCardTypeEnum>;
  message?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export enum ChargePointAvailabilityCardTypeEnum {
  /** Info */
  Info = "INFO",
  /** Warning */
  Warning = "WARNING",
}

export enum ChargeSortKeyEnum {
  /** Order by at */
  At = "AT",
  /** Order by total_amount_cents */
  TotalAmountCents = "TOTAL_AMOUNT_CENTS",
}

export type ChargeSortType = {
  sortBy?: InputMaybe<ChargeSortKeyEnum>;
  sortDirection?: InputMaybe<SortDirectionEnum>;
};

export enum ChargeSourceEnum {
  /** Paid through app */
  App = "APP",
  /** Paid through autocharge */
  Autocharge = "AUTOCHARGE",
  /** Paid through badge */
  Badge = "BADGE",
}

export enum ChargeTypeEnum {
  /** Fast Charge */
  FastCharge = "fast_charge",
  /** Slow Charge */
  SlowCharge = "slow_charge",
}

/** Autogenerated input type of CheckChargePointQrCode */
export type CheckChargePointQrCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  qrCode: Scalars["String"]["input"];
};

/** Autogenerated return type of CheckChargePointQrCode. */
export type CheckChargePointQrCodePayload = {
  __typename?: "CheckChargePointQrCodePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  qrCode?: Maybe<Scalars["String"]["output"]>;
  qrCodeAccepted?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Autogenerated input type of CheckLicensePlate */
export type CheckLicensePlateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  licensePlateCountry?: InputMaybe<Country>;
  licensePlateNumber?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CheckLicensePlate. */
export type CheckLicensePlatePayload = {
  __typename?: "CheckLicensePlatePayload";
  carBrand?: Maybe<CarBrand>;
  carModel?: Maybe<CarModel>;
  carVersion?: Maybe<CarVersion>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  /** @deprecated Use license_plate_country_object */
  licensePlateCountry?: Maybe<Country>;
  licensePlateCountryObject?: Maybe<CountryObject>;
  licensePlateNumber?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type ClosestUnbookableStation = {
  __typename?: "ClosestUnbookableStation";
  station: Station;
  venueGeoDistance?: Maybe<Scalars["Int"]["output"]>;
  venueRoadDistance?: Maybe<Scalars["Int"]["output"]>;
  venueRoadDuration?: Maybe<Scalars["Int"]["output"]>;
};

export type Company = Node & {
  __typename?: "Company";
  id: Scalars["ID"]["output"];
  informations: Scalars["String"]["output"];
};

/** Autogenerated input type of CompleteInvitation */
export type CompleteInvitationInput = {
  accepted: Scalars["Boolean"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invitationId: Scalars["String"]["input"];
};

/** Autogenerated return type of CompleteInvitation. */
export type CompleteInvitationPayload = {
  __typename?: "CompleteInvitationPayload";
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  successMessage?: Maybe<Scalars["String"]["output"]>;
  successTitle?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CompletePhoneChallenge */
export type CompletePhoneChallengeInput = {
  challengeCode: Scalars["Int"]["input"];
  challengeToken: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CompletePhoneChallenge. */
export type CompletePhoneChallengePayload = {
  __typename?: "CompletePhoneChallengePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  completionToken?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  userExists?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Autogenerated input type of ConfirmBookingIntentChoice */
export type ConfirmBookingIntentChoiceInput = {
  bookingIntentChoiceId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of ConfirmBookingIntentChoice. */
export type ConfirmBookingIntentChoicePayload = {
  __typename?: "ConfirmBookingIntentChoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  singleBooking?: Maybe<SingleBooking>;
};

/** Autogenerated input type of ConfirmBookingRoamingPaymentStep */
export type ConfirmBookingRoamingPaymentStepInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  roamingPaymentMethodId: Scalars["ID"]["input"];
  singleBookingId: Scalars["ID"]["input"];
};

/** Autogenerated return type of ConfirmBookingRoamingPaymentStep. */
export type ConfirmBookingRoamingPaymentStepPayload = {
  __typename?: "ConfirmBookingRoamingPaymentStepPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  remoteCharge?: Maybe<RemoteCharge>;
  singleBooking?: Maybe<SingleBooking>;
};

/** Autogenerated input type of ConfirmBookingVenueFinalStep */
export type ConfirmBookingVenueFinalStepInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  qrCode: Scalars["String"]["input"];
  returnUrl?: InputMaybe<Scalars["String"]["input"]>;
  singleBookingId: Scalars["ID"]["input"];
};

/** Autogenerated return type of ConfirmBookingVenueFinalStep. */
export type ConfirmBookingVenueFinalStepPayload = {
  __typename?: "ConfirmBookingVenueFinalStepPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  paymentIntent?: Maybe<PaymentIntent>;
  paymentNeeded?: Maybe<Scalars["Boolean"]["output"]>;
  qrCodeAccepted?: Maybe<Scalars["Boolean"]["output"]>;
  remoteCharge?: Maybe<RemoteCharge>;
  singleBooking?: Maybe<SingleBooking>;
};

/** Autogenerated input type of ConfirmBookingVenuePaymentStep */
export type ConfirmBookingVenuePaymentStepInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  paymentIntentId: Scalars["ID"]["input"];
  processoutGatewayRequestRef?: InputMaybe<Scalars["String"]["input"]>;
  singleBookingId: Scalars["ID"]["input"];
};

/** Autogenerated return type of ConfirmBookingVenuePaymentStep. */
export type ConfirmBookingVenuePaymentStepPayload = {
  __typename?: "ConfirmBookingVenuePaymentStepPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  remoteCharge?: Maybe<RemoteCharge>;
  singleBooking?: Maybe<SingleBooking>;
};

/** Autogenerated input type of ConfirmCapacityUsageConnector */
export type ConfirmCapacityUsageConnectorInput = {
  capacityUsageId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  connectorId: Scalars["ID"]["input"];
};

/** Autogenerated return type of ConfirmCapacityUsageConnector. */
export type ConfirmCapacityUsageConnectorPayload = {
  __typename?: "ConfirmCapacityUsageConnectorPayload";
  capacityUsage?: Maybe<CapacityUsage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  remoteCharge?: Maybe<RemoteCharge>;
};

/** Autogenerated input type of ConfirmCapacityUsage */
export type ConfirmCapacityUsageInput = {
  capacityUsageId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  qrCode: Scalars["String"]["input"];
};

/** Autogenerated return type of ConfirmCapacityUsage. */
export type ConfirmCapacityUsagePayload = {
  __typename?: "ConfirmCapacityUsagePayload";
  capacityUsage?: Maybe<CapacityUsage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  connectorChoices?: Maybe<Array<Connector>>;
  errors: Array<Scalars["String"]["output"]>;
  qrCodeAccepted?: Maybe<Scalars["Boolean"]["output"]>;
  remoteCharge?: Maybe<RemoteCharge>;
};

/** Autogenerated input type of ConfirmEmail */
export type ConfirmEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  confirmationToken: Scalars["String"]["input"];
};

/** Autogenerated return type of ConfirmEmail. */
export type ConfirmEmailPayload = {
  __typename?: "ConfirmEmailPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Email>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of ConfirmInstantChargeIntent */
export type ConfirmInstantChargeIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  instantChargeIntentId: Scalars["ID"]["input"];
  paymentIntentId?: InputMaybe<Scalars["ID"]["input"]>;
  processoutGatewayRequestRef?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of ConfirmInstantChargeIntent. */
export type ConfirmInstantChargeIntentPayload = {
  __typename?: "ConfirmInstantChargeIntentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  remoteCharge?: Maybe<RemoteCharge>;
};

/** Autogenerated input type of ConfirmRoamingInstantChargeIntent */
export type ConfirmRoamingInstantChargeIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  instantChargeIntentId: Scalars["ID"]["input"];
  roamingPaymentMethodId: Scalars["ID"]["input"];
};

/** Autogenerated return type of ConfirmRoamingInstantChargeIntent. */
export type ConfirmRoamingInstantChargeIntentPayload = {
  __typename?: "ConfirmRoamingInstantChargeIntentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  remoteCharge?: Maybe<RemoteCharge>;
};

/** Autogenerated input type of ConfirmUserDeletionIntent */
export type ConfirmUserDeletionIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  userDeletionIntentId: Scalars["ID"]["input"];
};

/** Autogenerated return type of ConfirmUserDeletionIntent. */
export type ConfirmUserDeletionIntentPayload = {
  __typename?: "ConfirmUserDeletionIntentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

export type Connector = Node & {
  __typename?: "Connector";
  anonymouslyOccupied: Scalars["Boolean"]["output"];
  availability: ConnectorPublicAvailability;
  chargePoint: ChargePoint;
  chargeType: ChargeTypeEnum;
  id: Scalars["ID"]["output"];
  maxPowerWatts: Scalars["Int"]["output"];
  number: Scalars["Int"]["output"];
  plug: ConnectorPlug;
  plugShortName: Scalars["String"]["output"];
  pricingDetail?: Maybe<Scalars["String"]["output"]>;
  publicLabel: Scalars["String"]["output"];
  publicStatus: ConnectorPublicStatus;
  readyToCharge: Scalars["Boolean"]["output"];
  spot: Spot;
  station: Station;
};

export type ConnectorAnonymouslyOccupiedArgs = {
  forAnonymousSession?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ConnectorAvailabilityArgs = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  forAnonymousSession?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ConnectorPricingDetailArgs = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum ConnectorPlug {
  /** Combo CCS */
  ComboCcsEu = "COMBO_CCS_EU",
  /** Domestic EU */
  Schuko = "SCHUKO",
  /** Type 2 */
  Type2 = "TYPE2",
  /** Type 2 (cable attached) */
  Type2CableAttached = "TYPE2_CABLE_ATTACHED",
  /** Type 3c */
  Type3C = "TYPE3C",
  /** CHAdeMO */
  Type4 = "TYPE4",
}

export enum ConnectorPublicAvailability {
  /** The spot is occupied by an anonymous user */
  AnonymouslyOccupied = "ANONYMOUSLY_OCCUPIED",
  /** The spot is available */
  Available = "AVAILABLE",
  /** The spot is occupied */
  Occupied = "OCCUPIED",
  /** The spot is reserved */
  Reserved = "RESERVED",
  /** The spot is unavailable */
  Unavailable = "UNAVAILABLE",
}

export enum ConnectorPublicStatus {
  /** The connector is inoperative */
  Inoperative = "INOPERATIVE",
  /** The connector is operative */
  Operative = "OPERATIVE",
}

export enum Country {
  /** Andorra */
  Ad = "AD",
  /** Austria */
  At = "AT",
  /** Belgium */
  Be = "BE",
  /** Bulgaria */
  Bg = "BG",
  /** Switzerland */
  Ch = "CH",
  /** Cyprus */
  Cy = "CY",
  /** Czech Republic */
  Cz = "CZ",
  /** Germany */
  De = "DE",
  /** Denmark */
  Dk = "DK",
  /** Estonia */
  Ee = "EE",
  /** Spain */
  Es = "ES",
  /** Finland */
  Fi = "FI",
  /** France */
  Fr = "FR",
  /** United Kingdom */
  Gb = "GB",
  /** Guyane */
  Gf = "GF",
  /** Guadeloupe */
  Gp = "GP",
  /** Greece */
  Gr = "GR",
  /** Croatia */
  Hr = "HR",
  /** Hungary */
  Hu = "HU",
  /** Ireland */
  Ie = "IE",
  /** Italy */
  It = "IT",
  /** Lithuania */
  Lt = "LT",
  /** Luxembourg */
  Lu = "LU",
  /** Latvia */
  Lv = "LV",
  /** Monaco */
  Mc = "MC",
  /** Martinique */
  Mq = "MQ",
  /** Malta */
  Mt = "MT",
  /** Nouvelle-Calédonie */
  Nc = "NC",
  /** Netherlands */
  Nl = "NL",
  /** Norway */
  No = "NO",
  /** Polynésie Française */
  Pf = "PF",
  /** Poland */
  Pl = "PL",
  /** Saint-Pierre-et-Miquelon */
  Pm = "PM",
  /** Portugal */
  Pt = "PT",
  /** La Réunion/Mayotte */
  Re = "RE",
  /** Romania */
  Ro = "RO",
  /** Sweden */
  Se = "SE",
  /** Slovenia */
  Si = "SI",
  /** Slovakia */
  Sk = "SK",
  /** Wallis-et-Futuna */
  Wf = "WF",
}

export type CountryObject = Node & {
  __typename?: "CountryObject";
  callingCode: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  /** Promote the country at the top of the list */
  isHighlighted: Scalars["Boolean"]["output"];
  key: Country;
  name: Scalars["String"]["output"];
  selfServeAvailable: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of CreateAnonymousChargeIntent */
export type CreateAnonymousChargeIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  connectorId: Scalars["ID"]["input"];
};

/** Autogenerated return type of CreateAnonymousChargeIntent. */
export type CreateAnonymousChargeIntentPayload = {
  __typename?: "CreateAnonymousChargeIntentPayload";
  accountId?: Maybe<Scalars["String"]["output"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  sessionToken?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CreateAnonymousRight */
export type CreateAnonymousRightInput = {
  accessId?: InputMaybe<Scalars["ID"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CreateAnonymousRight. */
export type CreateAnonymousRightPayload = {
  __typename?: "CreateAnonymousRightPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Autogenerated input type of CreateAutochargeConfig */
export type CreateAutochargeConfigInput = {
  accountId: Scalars["ID"]["input"];
  accountUserId: Scalars["ID"]["input"];
  carId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
  roamingPaymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of CreateAutochargeConfig. */
export type CreateAutochargeConfigPayload = {
  __typename?: "CreateAutochargeConfigPayload";
  autochargeConfig?: Maybe<AutochargeConfig>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CreateBookingIntent */
export type CreateBookingIntentInput = {
  accountId: Scalars["ID"]["input"];
  carId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  entrySource?: InputMaybe<EntrySource>;
  originLatitude: Scalars["Float"]["input"];
  originLongitude: Scalars["Float"]["input"];
  target: BookingIntentTarget;
  targetLatitude?: InputMaybe<Scalars["Float"]["input"]>;
  targetLongitude?: InputMaybe<Scalars["Float"]["input"]>;
  targetStationId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of CreateBookingIntent. */
export type CreateBookingIntentPayload = {
  __typename?: "CreateBookingIntentPayload";
  bookingIntent?: Maybe<BookingIntent>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CreateCapacityBooking */
export type CreateCapacityBookingInput = {
  accountId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  duration: Scalars["Int"]["input"];
  recurrenceRuleIcal?: InputMaybe<Scalars["String"]["input"]>;
  recurrenceUntil?: InputMaybe<Scalars["String"]["input"]>;
  recurring: Scalars["Boolean"]["input"];
  spotCount: Scalars["Int"]["input"];
  startAt: Scalars["String"]["input"];
  stationId: Scalars["ID"]["input"];
};

/** Autogenerated return type of CreateCapacityBooking. */
export type CreateCapacityBookingPayload = {
  __typename?: "CreateCapacityBookingPayload";
  capacityBooking?: Maybe<CapacityBooking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CreateCapacityUsage */
export type CreateCapacityUsageInput = {
  capacityOccurrenceId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  originLatitude: Scalars["Float"]["input"];
  originLongitude: Scalars["Float"]["input"];
};

/** Autogenerated return type of CreateCapacityUsage. */
export type CreateCapacityUsagePayload = {
  __typename?: "CreateCapacityUsagePayload";
  capacityUsage?: Maybe<CapacityUsage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CreateCar */
export type CreateCarInput = {
  accountId: Scalars["ID"]["input"];
  accountUserId?: InputMaybe<Scalars["ID"]["input"]>;
  carBrandId: Scalars["ID"]["input"];
  carModelId?: InputMaybe<Scalars["ID"]["input"]>;
  carVersionId?: InputMaybe<Scalars["ID"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  licensePlateCountry?: InputMaybe<Country>;
  licensePlateNumber?: InputMaybe<Scalars["String"]["input"]>;
  manualPlugs?: InputMaybe<Array<ConnectorPlug>>;
};

/** Autogenerated return type of CreateCar. */
export type CreateCarPayload = {
  __typename?: "CreateCarPayload";
  car?: Maybe<Car>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CreateInstantChargeIntent */
export type CreateInstantChargeIntentInput = {
  accountId: Scalars["ID"]["input"];
  carId?: InputMaybe<Scalars["ID"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  connectorId: Scalars["ID"]["input"];
  entrySource?: InputMaybe<InstantChargeIntentEntrySource>;
};

/** Autogenerated return type of CreateInstantChargeIntent. */
export type CreateInstantChargeIntentPayload = {
  __typename?: "CreateInstantChargeIntentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  instantChargeIntent?: Maybe<InstantChargeIntent>;
};

/** Autogenerated input type of CreateInvitation */
export type CreateInvitationInput = {
  accountId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  firstname: Scalars["String"]["input"];
  lastname: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
};

/** Autogenerated return type of CreateInvitation. */
export type CreateInvitationPayload = {
  __typename?: "CreateInvitationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  invitation?: Maybe<Invitation>;
  successMessage?: Maybe<Scalars["String"]["output"]>;
  successTitle?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CreateMultipleInvitations */
export type CreateMultipleInvitationsInput = {
  accountId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invitations: Array<InvitationDataType>;
};

/** Autogenerated return type of CreateMultipleInvitations. */
export type CreateMultipleInvitationsPayload = {
  __typename?: "CreateMultipleInvitationsPayload";
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  sentInvitationsCount: Scalars["Int"]["output"];
  successMessage?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CreateOnlinePaymentIntent */
export type CreateOnlinePaymentIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  instantChargeIntentId?: InputMaybe<Scalars["ID"]["input"]>;
  returnUrl?: InputMaybe<Scalars["String"]["input"]>;
  singleBookingId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of CreateOnlinePaymentIntent. */
export type CreateOnlinePaymentIntentPayload = {
  __typename?: "CreateOnlinePaymentIntentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  paymentIntent: PaymentIntent;
};

/** Autogenerated input type of CreatePaymentMethod */
export type CreatePaymentMethodInput = {
  accountId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  processoutCardRef: Scalars["String"]["input"];
  processoutTokenRef?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CreatePaymentMethod. */
export type CreatePaymentMethodPayload = {
  __typename?: "CreatePaymentMethodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of CreateProAccount */
export type CreateProAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  firstname: Scalars["String"]["input"];
  lastname: Scalars["String"]["input"];
  phoneChallengeCompletionToken: Scalars["String"]["input"];
  proType: ProAccountType;
};

/** Autogenerated return type of CreateProAccount. */
export type CreateProAccountPayload = {
  __typename?: "CreateProAccountPayload";
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  onboardingStatus?: Maybe<AccountOnboardingStatus>;
  resumeSignUp?: Maybe<Scalars["Boolean"]["output"]>;
  sessionToken?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

/** Autogenerated input type of CreateReview */
export type CreateReviewInput = {
  accountId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  rating: Scalars["Int"]["input"];
  remoteChargeId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of CreateReview. */
export type CreateReviewPayload = {
  __typename?: "CreateReviewPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Autogenerated input type of CreateRoamingPaymentMethod */
export type CreateRoamingPaymentMethodInput = {
  accountId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  label: Scalars["String"]["input"];
  tagUid?: InputMaybe<Scalars["String"]["input"]>;
  visualNumber?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CreateRoamingPaymentMethod. */
export type CreateRoamingPaymentMethodPayload = {
  __typename?: "CreateRoamingPaymentMethodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  roamingPaymentMethod?: Maybe<RoamingPaymentMethod>;
};

/** Autogenerated input type of CreateUserDeletionIntent */
export type CreateUserDeletionIntentInput = {
  accountId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CreateUserDeletionIntent. */
export type CreateUserDeletionIntentPayload = {
  __typename?: "CreateUserDeletionIntentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  userDeletionIntent?: Maybe<UserDeletionIntent>;
};

export enum Currency {
  /** CHF */
  Chf = "CHF",
  /** EUR */
  Eur = "EUR",
}

export type CustomerDetail = Node & {
  __typename?: "CustomerDetail";
  city?: Maybe<Scalars["String"]["output"]>;
  country: CountryObject;
  fiscalCode?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isEditable: Scalars["Boolean"]["output"];
  streetAddress?: Maybe<Scalars["String"]["output"]>;
  zipCode?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of DeactivateAutochargeConfig */
export type DeactivateAutochargeConfigInput = {
  autochargeConfigId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of DeactivateAutochargeConfig. */
export type DeactivateAutochargeConfigPayload = {
  __typename?: "DeactivateAutochargeConfigPayload";
  autochargeConfig?: Maybe<AutochargeConfig>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of DeleteCar */
export type DeleteCarInput = {
  carId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of DeleteCar. */
export type DeleteCarPayload = {
  __typename?: "DeleteCarPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of DeleteInvitation */
export type DeleteInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invitationId: Scalars["ID"]["input"];
};

/** Autogenerated return type of DeleteInvitation. */
export type DeleteInvitationPayload = {
  __typename?: "DeleteInvitationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  successMessage?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of DeletePaymentMethod */
export type DeletePaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethodId: Scalars["ID"]["input"];
};

/** Autogenerated return type of DeletePaymentMethod. */
export type DeletePaymentMethodPayload = {
  __typename?: "DeletePaymentMethodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of DismissExitSteps */
export type DismissExitStepsInput = {
  capacityUsageId?: InputMaybe<Scalars["ID"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  singleBookingId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of DismissExitSteps. */
export type DismissExitStepsPayload = {
  __typename?: "DismissExitStepsPayload";
  capacityUsage?: Maybe<CapacityUsage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  singleBooking?: Maybe<SingleBooking>;
};

/** Autogenerated input type of DismissNotification */
export type DismissNotificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  notificationId: Scalars["ID"]["input"];
};

/** Autogenerated return type of DismissNotification. */
export type DismissNotificationPayload = {
  __typename?: "DismissNotificationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  notification?: Maybe<Notification>;
};

export type DocumentFileDataType = {
  documentType: DocumentType;
  files: Array<Scalars["File"]["input"]>;
};

export enum DocumentType {
  /** Document Type for Belgium */
  Cbe = "CBE",
  /** Document Type for personal identification (passport, ID) */
  IdDocument = "ID_DOCUMENT",
  /** Document Type for France */
  Kbis = "KBIS",
  /** Document Type for Italy */
  Visura = "VISURA",
}

export type Email = Node & {
  __typename?: "Email";
  confirmationToken?: Maybe<Scalars["String"]["output"]>;
  confirmationTokenExpiresAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  confirmed: Scalars["Boolean"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  status: EmailStatusEnum;
  /** @deprecated Do not use anymore. */
  user: User;
};

export enum EmailStatusEnum {
  /** Confirmed */
  Confirmed = "CONFIRMED",
  /** Unconfirmed */
  Unconfirmed = "UNCONFIRMED",
}

export enum EntrySource {
  /** Android */
  Android = "android",
  /** Android Auto */
  AndroidAuto = "android_auto",
  /** CarPlay */
  CarPlay = "car_play",
  /** iOS */
  Ios = "ios",
  /** Web */
  Web = "web",
}

/** Autogenerated input type of ExportProCharges */
export type ExportProChargesInput = {
  accountUserId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  filters?: InputMaybe<ChargeFiltersType>;
  sort?: InputMaybe<ChargeSortType>;
};

/** Autogenerated return type of ExportProCharges. */
export type ExportProChargesPayload = {
  __typename?: "ExportProChargesPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  successMessage?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of FinishCapacityUsage */
export type FinishCapacityUsageInput = {
  capacityUsageId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of FinishCapacityUsage. */
export type FinishCapacityUsagePayload = {
  __typename?: "FinishCapacityUsagePayload";
  capacityUsage?: Maybe<CapacityUsage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of FinishSingleBooking */
export type FinishSingleBookingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  singleBookingId: Scalars["ID"]["input"];
};

/** Autogenerated return type of FinishSingleBooking. */
export type FinishSingleBookingPayload = {
  __typename?: "FinishSingleBookingPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  singleBooking?: Maybe<SingleBooking>;
};

export type HomeBanner = {
  __typename?: "HomeBanner";
  promotionAmount: Scalars["Int"]["output"];
  showBanner: Scalars["Boolean"]["output"];
  text: Scalars["String"]["output"];
  webviewUrl: Scalars["String"]["output"];
};

export type Illustration = Node & {
  __typename?: "Illustration";
  id: Scalars["ID"]["output"];
  illustrationUrl?: Maybe<Scalars["String"]["output"]>;
  pictogram: Scalars["String"]["output"];
};

/** Autogenerated input type of InquireInstantChargePaymentIntent */
export type InquireInstantChargePaymentIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  instantChargeIntentId: Scalars["ID"]["input"];
  paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
  returnUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of InquireInstantChargePaymentIntent. */
export type InquireInstantChargePaymentIntentPayload = {
  __typename?: "InquireInstantChargePaymentIntentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  needed?: Maybe<Scalars["Boolean"]["output"]>;
  paymentIntent?: Maybe<PaymentIntent>;
};

/** Autogenerated input type of InquirePaymentIntent */
export type InquirePaymentIntentInput = {
  bookingIntentId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  returnUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of InquirePaymentIntent. */
export type InquirePaymentIntentPayload = {
  __typename?: "InquirePaymentIntentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  needed?: Maybe<Scalars["Boolean"]["output"]>;
  paymentIntent?: Maybe<PaymentIntent>;
};

export type InstantChargeIntent = Node & {
  __typename?: "InstantChargeIntent";
  account: Account;
  car?: Maybe<Car>;
  connector: Connector;
  entrySource: InstantChargeIntentEntrySource;
  id: Scalars["ID"]["output"];
  pricingDetails?: Maybe<Array<PricingDetail>>;
  /** @deprecated Use promotion instead */
  promoCode?: Maybe<Scalars["String"]["output"]>;
  promotion?: Maybe<Promotion>;
  remoteCharge?: Maybe<RemoteCharge>;
};

export enum InstantChargeIntentEntrySource {
  /** QR Code Scanner opened on user's native camera */
  Camera = "CAMERA",
  /** QR Code Scanner opened on the app */
  HomeButton = "HOME_BUTTON",
}

export type Invitation = Node & {
  __typename?: "Invitation";
  account: Account;
  at: Scalars["ISO8601DateTime"]["output"];
  canReinvite: Scalars["Boolean"]["output"];
  confirmedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  createdBy: AccountUser;
  details?: Maybe<Array<Scalars["String"]["output"]>>;
  firstname: Scalars["String"]["output"];
  fullname: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  invitationText: Scalars["String"]["output"];
  lastname: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
  refusedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  reinvitationErrorMessage?: Maybe<Scalars["String"]["output"]>;
  status: InvitationStatusEnum;
};

/** The connection type for Invitation. */
export type InvitationConnection = {
  __typename?: "InvitationConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvitationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Invitation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type InvitationDataType = {
  firstname: Scalars["String"]["input"];
  lastname: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
};

/** An edge in a connection. */
export type InvitationEdge = {
  __typename?: "InvitationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Invitation>;
};

export enum InvitationStatusEnum {
  /** Confirmed */
  Confirmed = "CONFIRMED",
  /** Pending */
  Pending = "PENDING",
  /** Refused */
  Refused = "REFUSED",
}

export type Invoice = Node & {
  __typename?: "Invoice";
  fileUrl?: Maybe<Scalars["String"]["output"]>;
  fileUrlExpiresAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  status: InvoiceStatusEnum;
};

export enum InvoiceStatusEnum {
  /** Available */
  Available = "AVAILABLE",
  /** Generation pending */
  Pending = "PENDING",
}

export type InvoicingInformation = {
  __typename?: "InvoicingInformation";
  b2b: Array<InvoicingInformationType>;
  b2c: Array<InvoicingInformationType>;
  countryId: Scalars["ID"]["output"];
};

export enum InvoicingInformationType {
  City = "CITY",
  FiscalCode = "FISCAL_CODE",
  StreetAddress = "STREET_ADDRESS",
  ZipCode = "ZIP_CODE",
}

export type MinimumAppVersion = {
  __typename?: "MinimumAppVersion";
  updateAdvised: Scalars["Boolean"]["output"];
  updateMandatory: Scalars["Boolean"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  activateAutochargeConfig?: Maybe<ActivateAutochargeConfigPayload>;
  bulkReinviteUsers?: Maybe<BulkReinviteUsersPayload>;
  callAccessAction?: Maybe<CallAccessActionPayload>;
  cancelCapacityUsage?: Maybe<CancelCapacityUsagePayload>;
  cancelRemoteCharge?: Maybe<CancelRemoteChargePayload>;
  cancelSingleBooking?: Maybe<CancelSingleBookingPayload>;
  changeRemoteChargeObjective?: Maybe<ChangeRemoteChargeObjectivePayload>;
  checkChargePointQrCode?: Maybe<CheckChargePointQrCodePayload>;
  checkLicensePlate?: Maybe<CheckLicensePlatePayload>;
  completeInvitation?: Maybe<CompleteInvitationPayload>;
  completePhoneChallenge?: Maybe<CompletePhoneChallengePayload>;
  confirmBookingIntentChoice?: Maybe<ConfirmBookingIntentChoicePayload>;
  confirmBookingRoamingPaymentStep?: Maybe<ConfirmBookingRoamingPaymentStepPayload>;
  confirmBookingVenueFinalStep?: Maybe<ConfirmBookingVenueFinalStepPayload>;
  confirmBookingVenuePaymentStep?: Maybe<ConfirmBookingVenuePaymentStepPayload>;
  confirmCapacityUsage?: Maybe<ConfirmCapacityUsagePayload>;
  confirmCapacityUsageConnector?: Maybe<ConfirmCapacityUsageConnectorPayload>;
  confirmEmail?: Maybe<ConfirmEmailPayload>;
  confirmInstantChargeIntent?: Maybe<ConfirmInstantChargeIntentPayload>;
  confirmRoamingInstantChargeIntent?: Maybe<ConfirmRoamingInstantChargeIntentPayload>;
  confirmUserDeletionIntent?: Maybe<ConfirmUserDeletionIntentPayload>;
  createAnonymousChargeIntent?: Maybe<CreateAnonymousChargeIntentPayload>;
  createAnonymousRight?: Maybe<CreateAnonymousRightPayload>;
  createAutochargeConfig?: Maybe<CreateAutochargeConfigPayload>;
  createBookingIntent?: Maybe<CreateBookingIntentPayload>;
  createCapacityBooking?: Maybe<CreateCapacityBookingPayload>;
  createCapacityUsage?: Maybe<CreateCapacityUsagePayload>;
  createCar?: Maybe<CreateCarPayload>;
  createInstantChargeIntent?: Maybe<CreateInstantChargeIntentPayload>;
  createInvitation?: Maybe<CreateInvitationPayload>;
  createMultipleInvitations?: Maybe<CreateMultipleInvitationsPayload>;
  createOnlinePaymentIntent?: Maybe<CreateOnlinePaymentIntentPayload>;
  createPaymentMethod?: Maybe<CreatePaymentMethodPayload>;
  createProAccount?: Maybe<CreateProAccountPayload>;
  createReview?: Maybe<CreateReviewPayload>;
  createRoamingPaymentMethod?: Maybe<CreateRoamingPaymentMethodPayload>;
  createUserDeletionIntent?: Maybe<CreateUserDeletionIntentPayload>;
  deactivateAutochargeConfig?: Maybe<DeactivateAutochargeConfigPayload>;
  deleteCar?: Maybe<DeleteCarPayload>;
  deleteInvitation?: Maybe<DeleteInvitationPayload>;
  deletePaymentMethod?: Maybe<DeletePaymentMethodPayload>;
  dismissExitSteps?: Maybe<DismissExitStepsPayload>;
  dismissNotification?: Maybe<DismissNotificationPayload>;
  exportProCharges?: Maybe<ExportProChargesPayload>;
  finishCapacityUsage?: Maybe<FinishCapacityUsagePayload>;
  finishSingleBooking?: Maybe<FinishSingleBookingPayload>;
  inquireInstantChargePaymentIntent?: Maybe<InquireInstantChargePaymentIntentPayload>;
  inquirePaymentIntent?: Maybe<InquirePaymentIntentPayload>;
  reinviteUser?: Maybe<ReinviteUserPayload>;
  reportRemoteChargeAction?: Maybe<ReportRemoteChargeActionPayload>;
  retrieveAnonymousSession?: Maybe<RetrieveAnonymousSessionPayload>;
  retrieveBusinessCharges?: Maybe<RetrieveBusinessChargesPayload>;
  searchBusinessCompany?: Maybe<SearchBusinessCompanyPayload>;
  sendEmailVerification?: Maybe<SendEmailVerificationPayload>;
  sendInvoice?: Maybe<SendInvoicePayload>;
  sessionlessConfirmEmail?: Maybe<SessionlessConfirmEmailPayload>;
  setBookingIntentChoicePromoCode?: Maybe<SetBookingIntentChoicePromoCodePayload>;
  /** @deprecated Use set_booking_intent_choice_promo_code instead */
  setBookingIntentPromoCode?: Maybe<SetBookingIntentPromoCodePayload>;
  setInstantChargeIntentPromoCode?: Maybe<SetInstantChargeIntentPromoCodePayload>;
  setSingleBookingPromoCode?: Maybe<SetSingleBookingPromoCodePayload>;
  signIn?: Maybe<SignInPayload>;
  signOut?: Maybe<SignOutPayload>;
  signUp?: Maybe<SignUpPayload>;
  startPhoneChallenge?: Maybe<StartPhoneChallengePayload>;
  switchAccount?: Maybe<SwitchAccountPayload>;
  unsubscribe?: Maybe<UnsubscribePayload>;
  updateAccountUser?: Maybe<UpdateAccountUserPayload>;
  updateBusinessCompany?: Maybe<UpdateBusinessCompanyPayload>;
  updateCar?: Maybe<UpdateCarPayload>;
  updateCompany?: Maybe<UpdateCompanyPayload>;
  updateCustomerDetail?: Maybe<UpdateCustomerDetailPayload>;
  updateEmail?: Maybe<UpdateEmailPayload>;
  updatePaymentIntent?: Maybe<UpdatePaymentIntentPayload>;
  updatePaymentMethod?: Maybe<UpdatePaymentMethodPayload>;
  updateUserEmailConsent?: Maybe<UpdateUserEmailConsentPayload>;
  updateUserPhoneNumber?: Maybe<UpdateUserPhoneNumberPayload>;
  uploadBusinessDocuments?: Maybe<UploadBusinessDocumentsPayload>;
};

export type MutationActivateAutochargeConfigArgs = {
  input: ActivateAutochargeConfigInput;
};

export type MutationBulkReinviteUsersArgs = {
  input: BulkReinviteUsersInput;
};

export type MutationCallAccessActionArgs = {
  input: CallAccessActionInput;
};

export type MutationCancelCapacityUsageArgs = {
  input: CancelCapacityUsageInput;
};

export type MutationCancelRemoteChargeArgs = {
  input: CancelRemoteChargeInput;
};

export type MutationCancelSingleBookingArgs = {
  input: CancelSingleBookingInput;
};

export type MutationChangeRemoteChargeObjectiveArgs = {
  input: ChangeRemoteChargeObjectiveInput;
};

export type MutationCheckChargePointQrCodeArgs = {
  input: CheckChargePointQrCodeInput;
};

export type MutationCheckLicensePlateArgs = {
  input: CheckLicensePlateInput;
};

export type MutationCompleteInvitationArgs = {
  input: CompleteInvitationInput;
};

export type MutationCompletePhoneChallengeArgs = {
  input: CompletePhoneChallengeInput;
};

export type MutationConfirmBookingIntentChoiceArgs = {
  input: ConfirmBookingIntentChoiceInput;
};

export type MutationConfirmBookingRoamingPaymentStepArgs = {
  input: ConfirmBookingRoamingPaymentStepInput;
};

export type MutationConfirmBookingVenueFinalStepArgs = {
  input: ConfirmBookingVenueFinalStepInput;
};

export type MutationConfirmBookingVenuePaymentStepArgs = {
  input: ConfirmBookingVenuePaymentStepInput;
};

export type MutationConfirmCapacityUsageArgs = {
  input: ConfirmCapacityUsageInput;
};

export type MutationConfirmCapacityUsageConnectorArgs = {
  input: ConfirmCapacityUsageConnectorInput;
};

export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};

export type MutationConfirmInstantChargeIntentArgs = {
  input: ConfirmInstantChargeIntentInput;
};

export type MutationConfirmRoamingInstantChargeIntentArgs = {
  input: ConfirmRoamingInstantChargeIntentInput;
};

export type MutationConfirmUserDeletionIntentArgs = {
  input: ConfirmUserDeletionIntentInput;
};

export type MutationCreateAnonymousChargeIntentArgs = {
  input: CreateAnonymousChargeIntentInput;
};

export type MutationCreateAnonymousRightArgs = {
  input: CreateAnonymousRightInput;
};

export type MutationCreateAutochargeConfigArgs = {
  input: CreateAutochargeConfigInput;
};

export type MutationCreateBookingIntentArgs = {
  input: CreateBookingIntentInput;
};

export type MutationCreateCapacityBookingArgs = {
  input: CreateCapacityBookingInput;
};

export type MutationCreateCapacityUsageArgs = {
  input: CreateCapacityUsageInput;
};

export type MutationCreateCarArgs = {
  input: CreateCarInput;
};

export type MutationCreateInstantChargeIntentArgs = {
  input: CreateInstantChargeIntentInput;
};

export type MutationCreateInvitationArgs = {
  input: CreateInvitationInput;
};

export type MutationCreateMultipleInvitationsArgs = {
  input: CreateMultipleInvitationsInput;
};

export type MutationCreateOnlinePaymentIntentArgs = {
  input: CreateOnlinePaymentIntentInput;
};

export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};

export type MutationCreateProAccountArgs = {
  input: CreateProAccountInput;
};

export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};

export type MutationCreateRoamingPaymentMethodArgs = {
  input: CreateRoamingPaymentMethodInput;
};

export type MutationCreateUserDeletionIntentArgs = {
  input: CreateUserDeletionIntentInput;
};

export type MutationDeactivateAutochargeConfigArgs = {
  input: DeactivateAutochargeConfigInput;
};

export type MutationDeleteCarArgs = {
  input: DeleteCarInput;
};

export type MutationDeleteInvitationArgs = {
  input: DeleteInvitationInput;
};

export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};

export type MutationDismissExitStepsArgs = {
  input: DismissExitStepsInput;
};

export type MutationDismissNotificationArgs = {
  input: DismissNotificationInput;
};

export type MutationExportProChargesArgs = {
  input: ExportProChargesInput;
};

export type MutationFinishCapacityUsageArgs = {
  input: FinishCapacityUsageInput;
};

export type MutationFinishSingleBookingArgs = {
  input: FinishSingleBookingInput;
};

export type MutationInquireInstantChargePaymentIntentArgs = {
  input: InquireInstantChargePaymentIntentInput;
};

export type MutationInquirePaymentIntentArgs = {
  input: InquirePaymentIntentInput;
};

export type MutationReinviteUserArgs = {
  input: ReinviteUserInput;
};

export type MutationReportRemoteChargeActionArgs = {
  input: ReportRemoteChargeActionInput;
};

export type MutationRetrieveAnonymousSessionArgs = {
  input: RetrieveAnonymousSessionInput;
};

export type MutationRetrieveBusinessChargesArgs = {
  input: RetrieveBusinessChargesInput;
};

export type MutationSearchBusinessCompanyArgs = {
  input: SearchBusinessCompanyInput;
};

export type MutationSendEmailVerificationArgs = {
  input: SendEmailVerificationInput;
};

export type MutationSendInvoiceArgs = {
  input: SendInvoiceInput;
};

export type MutationSessionlessConfirmEmailArgs = {
  input: SessionlessConfirmEmailInput;
};

export type MutationSetBookingIntentChoicePromoCodeArgs = {
  input: SetBookingIntentChoicePromoCodeInput;
};

export type MutationSetBookingIntentPromoCodeArgs = {
  input: SetBookingIntentPromoCodeInput;
};

export type MutationSetInstantChargeIntentPromoCodeArgs = {
  input: SetInstantChargeIntentPromoCodeInput;
};

export type MutationSetSingleBookingPromoCodeArgs = {
  input: SetSingleBookingPromoCodeInput;
};

export type MutationSignInArgs = {
  input: SignInInput;
};

export type MutationSignOutArgs = {
  input: SignOutInput;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationStartPhoneChallengeArgs = {
  input: StartPhoneChallengeInput;
};

export type MutationSwitchAccountArgs = {
  input: SwitchAccountInput;
};

export type MutationUnsubscribeArgs = {
  input: UnsubscribeInput;
};

export type MutationUpdateAccountUserArgs = {
  input: UpdateAccountUserInput;
};

export type MutationUpdateBusinessCompanyArgs = {
  input: UpdateBusinessCompanyInput;
};

export type MutationUpdateCarArgs = {
  input: UpdateCarInput;
};

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};

export type MutationUpdateCustomerDetailArgs = {
  input: UpdateCustomerDetailInput;
};

export type MutationUpdateEmailArgs = {
  input: UpdateEmailInput;
};

export type MutationUpdatePaymentIntentArgs = {
  input: UpdatePaymentIntentInput;
};

export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};

export type MutationUpdateUserEmailConsentArgs = {
  input: UpdateUserEmailConsentInput;
};

export type MutationUpdateUserPhoneNumberArgs = {
  input: UpdateUserPhoneNumberInput;
};

export type MutationUploadBusinessDocumentsArgs = {
  input: UploadBusinessDocumentsInput;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars["ID"]["output"];
};

export type Notification = {
  __typename?: "Notification";
  accountUser: AccountUser;
  category: NotificationCategoryEnum;
  event: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
  seenAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export enum NotificationCategoryEnum {
  /** Green notification for clients */
  Confirmation = "CONFIRMATION",
  /** Red notification for clients */
  Error = "ERROR",
  /** Neutral notification for clients */
  Information = "INFORMATION",
  /** Yellow notification for clients */
  Warning = "WARNING",
}

/** Autogenerated return type of NotificationSentSubscription. */
export type NotificationSentSubscriptionPayload = {
  __typename?: "NotificationSentSubscriptionPayload";
  notification?: Maybe<Notification>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentIntent = Node & {
  __typename?: "PaymentIntent";
  account: Account;
  authorizationAmountCents: Scalars["Int"]["output"];
  authorizationAmountWithoutVatCents: Scalars["Int"]["output"];
  authorizationStatus: Scalars["String"]["output"];
  authorizationVatCents: Scalars["Int"]["output"];
  country: CountryObject;
  currency: Currency;
  id: Scalars["ID"]["output"];
  processoutInvoiceRef?: Maybe<Scalars["String"]["output"]>;
  processoutMitEnabled: Scalars["Boolean"]["output"];
};

export type PaymentMethod = Node & {
  __typename?: "PaymentMethod";
  account: Account;
  accountUser: AccountUser;
  canShowPricing: Scalars["Boolean"]["output"];
  canUsePromoCode: Scalars["Boolean"]["output"];
  cardExpiration: Scalars["String"]["output"];
  cardExpirationStatus: CardExpirationStatus;
  cardLast4Digits: Scalars["String"]["output"];
  cardName?: Maybe<Scalars["String"]["output"]>;
  cardScheme: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  processoutTokenRef: Scalars["String"]["output"];
  /** @deprecated Use accountUser instead */
  user: User;
  verified: Scalars["Boolean"]["output"];
};

/** The connection type for PaymentMethod. */
export type PaymentMethodConnection = {
  __typename?: "PaymentMethodConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentMethodEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PaymentMethod>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PaymentMethodEdge = {
  __typename?: "PaymentMethodEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<PaymentMethod>;
};

export type PeriodicInvoice = Node & {
  __typename?: "PeriodicInvoice";
  chargesCount: Scalars["Int"]["output"];
  currency: Currency;
  fileUrl?: Maybe<Scalars["String"]["output"]>;
  fileUrlExpiresAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  status: InvoiceStatusEnum;
  totalAmountCents: Scalars["Int"]["output"];
  year: Scalars["String"]["output"];
};

/** The connection type for PeriodicInvoice. */
export type PeriodicInvoiceConnection = {
  __typename?: "PeriodicInvoiceConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PeriodicInvoiceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PeriodicInvoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PeriodicInvoiceEdge = {
  __typename?: "PeriodicInvoiceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<PeriodicInvoice>;
};

export type PricingDetail = {
  __typename?: "PricingDetail";
  label: Scalars["String"]["output"];
};

export enum ProAccountLabelType {
  /** Fleet */
  Fleet = "fleet",
  /** Independant */
  Independant = "independant",
}

export enum ProAccountType {
  /** Independant */
  Independant = "INDEPENDANT",
  /** KA */
  Ka = "KA",
  /** SMB */
  Smb = "SMB",
}

export type Promotion = Node & {
  __typename?: "Promotion";
  code: Scalars["String"]["output"];
  details: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  anonymousSession?: Maybe<AnonymousSession>;
  businessCompanyRequiredDocuments: Array<BusinessDocument>;
  carBrands: CarBrandConnection;
  chargePoint?: Maybe<ChargePoint>;
  countries: Array<CountryObject>;
  homeBanner?: Maybe<HomeBanner>;
  homeCards: Array<Card>;
  me?: Maybe<User>;
  minimumAppVersion: MinimumAppVersion;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  remoteCharge?: Maybe<RemoteCharge>;
  station?: Maybe<Station>;
  stationAccess?: Maybe<StationAccess>;
  stations: StationConnection;
  stationsMapUrl: Scalars["String"]["output"];
  /** @deprecated Use supportSections instead. */
  supportMethods: Array<SupportMethod>;
  supportSections: Array<SupportSection>;
};

export type QueryBusinessCompanyRequiredDocumentsArgs = {
  countryKey: Country;
};

export type QueryCarBrandsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryChargePointArgs = {
  publicCode: Scalars["String"]["input"];
};

export type QueryHomeBannerArgs = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryNodeArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryNodesArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type QueryRemoteChargeArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryStationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryStationAccessArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryStationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ReferralProgram = Node & {
  __typename?: "ReferralProgram";
  availableRewardsCount: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  promotionAmount: Scalars["Int"]["output"];
  promotionCode: Scalars["String"]["output"];
  promotionCurrency: Currency;
  rewardsUrl: Scalars["String"]["output"];
};

export type ReferralProgramReward = Node & {
  __typename?: "ReferralProgramReward";
  action?: Maybe<Scalars["String"]["output"]>;
  expiresSoon: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  promotionAmount: Scalars["Int"]["output"];
  promotionCurrency: Currency;
  subLabel: Scalars["String"]["output"];
};

/** Autogenerated input type of ReinviteUser */
export type ReinviteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invitationId: Scalars["ID"]["input"];
};

/** Autogenerated return type of ReinviteUser. */
export type ReinviteUserPayload = {
  __typename?: "ReinviteUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  invitation?: Maybe<Invitation>;
  successMessage?: Maybe<Scalars["String"]["output"]>;
  successTitle?: Maybe<Scalars["String"]["output"]>;
};

export type RemoteCharge = Node & {
  __typename?: "RemoteCharge";
  account: Account;
  accountUser: User;
  capacityUsage?: Maybe<CapacityUsage>;
  car: Car;
  cards: Array<Card>;
  charge?: Maybe<Charge>;
  connector: Connector;
  currentCouponUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  pricingDetails?: Maybe<Array<PricingDetail>>;
  promotion?: Maybe<Promotion>;
  singleBooking?: Maybe<SingleBooking>;
  station: Station;
  status: RemoteChargeStatus;
  step: RemoteChargeStep;
  /** @deprecated Use accountUser instead */
  user?: Maybe<User>;
};

export type RemoteChargeActionStep = {
  __typename?: "RemoteChargeActionStep";
  actionConfirmMessage?: Maybe<Scalars["String"]["output"]>;
  actionHeaderMessage?: Maybe<Scalars["String"]["output"]>;
  actionKey?: Maybe<Scalars["String"]["output"]>;
  actionLabel?: Maybe<Scalars["String"]["output"]>;
  actionType?: Maybe<RemoteChargeActionType>;
  connectorType?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export enum RemoteChargeActionType {
  /** plug */
  Plug = "plug",
  /** unplug */
  Unplug = "unplug",
}

/** Autogenerated return type of RemoteChargeChangedSubscription. */
export type RemoteChargeChangedSubscriptionPayload = {
  __typename?: "RemoteChargeChangedSubscriptionPayload";
  remoteCharge: RemoteCharge;
};

export type RemoteChargeChargingStep = {
  __typename?: "RemoteChargeChargingStep";
  actionConfirmMessage?: Maybe<Scalars["String"]["output"]>;
  actionKey?: Maybe<Scalars["String"]["output"]>;
  actionLabel?: Maybe<Scalars["String"]["output"]>;
  canShowPricing: Scalars["Boolean"]["output"];
  currency: Currency;
  currentPower?: Maybe<Scalars["Int"]["output"]>;
  currentPriceCents?: Maybe<Scalars["Int"]["output"]>;
  currentSoc?: Maybe<Scalars["Int"]["output"]>;
  energyDelivered: Scalars["Int"]["output"];
  estimatedDurationLabel?: Maybe<Scalars["String"]["output"]>;
  estimatedRemainingDurationComputing: Scalars["Boolean"]["output"];
  estimatedRemainingDurationLabel?: Maybe<Scalars["String"]["output"]>;
  targetSoc: Scalars["Int"]["output"];
};

export type RemoteChargeCompletedStep = {
  __typename?: "RemoteChargeCompletedStep";
  canShowPricing: Scalars["Boolean"]["output"];
  currency: Currency;
  /** @deprecated Will be removed in version 4.36 */
  details: Array<RemoteChargeCompletedStepDetail>;
  energyDelivered: Scalars["Int"]["output"];
  /** @deprecated Will be removed in version 4.36 */
  message: Scalars["String"]["output"];
  priceCents: Scalars["Int"]["output"];
  priceLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will be removed in version 4.36 */
  title: Scalars["String"]["output"];
  totalDurationInSeconds: Scalars["Int"]["output"];
};

export type RemoteChargeCompletedStepDetail = {
  __typename?: "RemoteChargeCompletedStepDetail";
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

/** Autogenerated return type of RemoteChargeCreatedSubscription. */
export type RemoteChargeCreatedSubscriptionPayload = {
  __typename?: "RemoteChargeCreatedSubscriptionPayload";
  remoteCharge?: Maybe<RemoteCharge>;
};

export enum RemoteChargeStatus {
  /** The cable has been plugged to the car */
  CablePlugged = "CABLE_PLUGGED",
  /** The charge is canceled */
  Canceled = "CANCELED",
  /** The charge is completed */
  Completed = "COMPLETED",
  /** Initial status */
  Project = "PROJECT",
  /** The charge has started */
  Started = "STARTED",
  /** The charge will start */
  Starting = "STARTING",
  /** The charge has stopped */
  Stopped = "STOPPED",
  /** The charge will stop */
  Stopping = "STOPPING",
}

export type RemoteChargeStep =
  | RemoteChargeActionStep
  | RemoteChargeChargingStep
  | RemoteChargeCompletedStep
  | RemoteChargeWaitStep;

export type RemoteChargeWaitStep = {
  __typename?: "RemoteChargeWaitStep";
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

/** Autogenerated input type of ReportRemoteChargeAction */
export type ReportRemoteChargeActionInput = {
  actionKey: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  remoteChargeId: Scalars["ID"]["input"];
};

/** Autogenerated return type of ReportRemoteChargeAction. */
export type ReportRemoteChargeActionPayload = {
  __typename?: "ReportRemoteChargeActionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  remoteCharge?: Maybe<RemoteCharge>;
};

/** Autogenerated input type of RetrieveAnonymousSession */
export type RetrieveAnonymousSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  connectorId: Scalars["ID"]["input"];
  lastFourDigits: Scalars["String"]["input"];
};

/** Autogenerated return type of RetrieveAnonymousSession. */
export type RetrieveAnonymousSessionPayload = {
  __typename?: "RetrieveAnonymousSessionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  sessionToken?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of RetrieveBusinessCharges */
export type RetrieveBusinessChargesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  dateOfCharge: Scalars["String"]["input"];
  lastFourDigits: Scalars["String"]["input"];
};

/** Autogenerated return type of RetrieveBusinessCharges. */
export type RetrieveBusinessChargesPayload = {
  __typename?: "RetrieveBusinessChargesPayload";
  charges?: Maybe<Array<Charge>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type RoamingPaymentMethod = Node & {
  __typename?: "RoamingPaymentMethod";
  account: Account;
  accountUser: AccountUser;
  canShowPricing: Scalars["Boolean"]["output"];
  canUsePromoCode: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  tagUid: Scalars["String"]["output"];
};

/** The connection type for RoamingPaymentMethod. */
export type RoamingPaymentMethodConnection = {
  __typename?: "RoamingPaymentMethodConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoamingPaymentMethodEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RoamingPaymentMethod>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RoamingPaymentMethodEdge = {
  __typename?: "RoamingPaymentMethodEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<RoamingPaymentMethod>;
};

/** Autogenerated input type of SearchBusinessCompany */
export type SearchBusinessCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  companyIdentificationNumber?: InputMaybe<Scalars["String"]["input"]>;
  companyIdentificationType?: InputMaybe<BusinessCompanyIdentificationType>;
};

/** Autogenerated return type of SearchBusinessCompany. */
export type SearchBusinessCompanyPayload = {
  __typename?: "SearchBusinessCompanyPayload";
  address?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  companyIdentificationNumber?: Maybe<Scalars["String"]["output"]>;
  companyIdentificationType?: Maybe<BusinessCompanyIdentificationType>;
  country?: Maybe<CountryObject>;
  errorKeys?: Maybe<Array<Scalars["String"]["output"]>>;
  errors: Array<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  postCode?: Maybe<Scalars["String"]["output"]>;
  vatId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of SendEmailVerification */
export type SendEmailVerificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  emailId: Scalars["ID"]["input"];
};

/** Autogenerated return type of SendEmailVerification. */
export type SendEmailVerificationPayload = {
  __typename?: "SendEmailVerificationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Email>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of SendInvoice */
export type SendInvoiceInput = {
  businessChargeId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
};

/** Autogenerated return type of SendInvoice. */
export type SendInvoicePayload = {
  __typename?: "SendInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** Autogenerated input type of SessionlessConfirmEmail */
export type SessionlessConfirmEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  confirmationToken: Scalars["String"]["input"];
};

/** Autogenerated return type of SessionlessConfirmEmail. */
export type SessionlessConfirmEmailPayload = {
  __typename?: "SessionlessConfirmEmailPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Autogenerated input type of SetBookingIntentChoicePromoCode */
export type SetBookingIntentChoicePromoCodeInput = {
  bookingIntentChoiceId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of SetBookingIntentChoicePromoCode. */
export type SetBookingIntentChoicePromoCodePayload = {
  __typename?: "SetBookingIntentChoicePromoCodePayload";
  bookingIntentChoice?: Maybe<BookingIntentChoice>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of SetBookingIntentPromoCode */
export type SetBookingIntentPromoCodeInput = {
  bookingIntentId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of SetBookingIntentPromoCode. */
export type SetBookingIntentPromoCodePayload = {
  __typename?: "SetBookingIntentPromoCodePayload";
  bookingIntent?: Maybe<BookingIntent>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of SetInstantChargeIntentPromoCode */
export type SetInstantChargeIntentPromoCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  instantChargeIntentId: Scalars["ID"]["input"];
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of SetInstantChargeIntentPromoCode. */
export type SetInstantChargeIntentPromoCodePayload = {
  __typename?: "SetInstantChargeIntentPromoCodePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  instantChargeIntent?: Maybe<InstantChargeIntent>;
};

/** Autogenerated input type of SetSingleBookingPromoCode */
export type SetSingleBookingPromoCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
  singleBookingId: Scalars["ID"]["input"];
};

/** Autogenerated return type of SetSingleBookingPromoCode. */
export type SetSingleBookingPromoCodePayload = {
  __typename?: "SetSingleBookingPromoCodePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  singleBooking?: Maybe<SingleBooking>;
};

/** Autogenerated input type of SignIn */
export type SignInInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  phoneChallengeCompletionToken: Scalars["String"]["input"];
};

/** Autogenerated return type of SignIn. */
export type SignInPayload = {
  __typename?: "SignInPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  sessionToken?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

/** Autogenerated input type of SignOut */
export type SignOutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  sessionToken: Scalars["String"]["input"];
};

/** Autogenerated return type of SignOut. */
export type SignOutPayload = {
  __typename?: "SignOutPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  signedOut?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Autogenerated input type of SignUp */
export type SignUpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  firstname: Scalars["String"]["input"];
  lastname?: InputMaybe<Scalars["String"]["input"]>;
  marketingConsent: Scalars["Boolean"]["input"];
  phoneChallengeCompletionToken: Scalars["String"]["input"];
};

/** Autogenerated return type of SignUp. */
export type SignUpPayload = {
  __typename?: "SignUpPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  sessionToken?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

export type SingleBooking = Node & {
  __typename?: "SingleBooking";
  /** @deprecated Will be removed in version 4.36 */
  access?: Maybe<Access>;
  account: Account;
  accountUser: User;
  bookingKitAccess?: Maybe<Array<Access>>;
  canBeCanceled: Scalars["Boolean"]["output"];
  canBeFinished: Scalars["Boolean"]["output"];
  canDisplayAccess: Scalars["Boolean"]["output"];
  canExit: Scalars["Boolean"]["output"];
  canResume: Scalars["Boolean"]["output"];
  canRetry: Scalars["Boolean"]["output"];
  cancellationInfo?: Maybe<Scalars["String"]["output"]>;
  car: Car;
  checkoutKitAccess?: Maybe<Array<Access>>;
  /** @deprecated Use accountUser instead */
  currentRemoteCharge?: Maybe<RemoteCharge>;
  endAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  promotion?: Maybe<Promotion>;
  spot: Spot;
  startAt: Scalars["ISO8601DateTime"]["output"];
  station: Station;
  /** @deprecated Use accountUser instead */
  user: User;
};

/** Autogenerated return type of SingleBookingChangedSubscription. */
export type SingleBookingChangedSubscriptionPayload = {
  __typename?: "SingleBookingChangedSubscriptionPayload";
  singleBooking: SingleBooking;
};

export enum SortDirectionEnum {
  /** Ascending order */
  Asc = "ASC",
  /** Descending order */
  Desc = "DESC",
}

export type Spot = Node & {
  __typename?: "Spot";
  connectors: Array<Connector>;
  id: Scalars["ID"]["output"];
  number: Scalars["Int"]["output"];
  publicAvailability: SpotPublicAvailability;
  publicLabel: Scalars["String"]["output"];
  reducedMobility: Scalars["Boolean"]["output"];
  station: Station;
};

/** Autogenerated return type of SpotChangedSubscription. */
export type SpotChangedSubscriptionPayload = {
  __typename?: "SpotChangedSubscriptionPayload";
  spot: Spot;
};

/** The connection type for Spot. */
export type SpotConnection = {
  __typename?: "SpotConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SpotEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Spot>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SpotEdge = {
  __typename?: "SpotEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Spot>;
};

export enum SpotPublicAvailability {
  /** The spot is available */
  Available = "AVAILABLE",
  /** The spot is occupied */
  Occupied = "OCCUPIED",
  /** The spot is reserved */
  Reserved = "RESERVED",
  /** The spot is unavailable */
  Unavailable = "UNAVAILABLE",
}

/** Autogenerated input type of StartPhoneChallenge */
export type StartPhoneChallengeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
};

/** Autogenerated return type of StartPhoneChallenge. */
export type StartPhoneChallengePayload = {
  __typename?: "StartPhoneChallengePayload";
  challengeToken?: Maybe<Scalars["String"]["output"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

export type Station = Node & {
  __typename?: "Station";
  address: Scalars["String"]["output"];
  amenities: Array<AmenityEnum>;
  availableSpotsCount: Scalars["Int"]["output"];
  canBeBooked: Scalars["Boolean"]["output"];
  capacityBookableSpotsLimit: Scalars["Int"]["output"];
  cards: Array<Card>;
  comingSoonLabel?: Maybe<Scalars["String"]["output"]>;
  energyPricingDetails?: Maybe<Array<StationEnergyPricingDetail>>;
  fastSpeedPricingDetail?: Maybe<StationPricingDetail>;
  freeParkingDurationInMinutes?: Maybe<Scalars["Int"]["output"]>;
  hourlies: Array<StationHourly>;
  id: Scalars["ID"]["output"];
  illustrations: Array<Illustration>;
  key?: Maybe<Scalars["String"]["output"]>;
  latitude: Scalars["Float"]["output"];
  licensePlateAccessEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  longitude: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  nextClosing?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  nextOpening?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  parkDiscountInMinutes?: Maybe<Scalars["Int"]["output"]>;
  parkingTypeLabel?: Maybe<Scalars["String"]["output"]>;
  pricingDetails?: Maybe<Array<StationPricingDetail>>;
  publicAvailablePower?: Maybe<Scalars["Int"]["output"]>;
  publicStatus: StationPublicStatus;
  spots: Array<Spot>;
  spotsCount: Scalars["Int"]["output"];
  status: StationStatus;
};

export type StationEnergyPricingDetailsArgs = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type StationFastSpeedPricingDetailArgs = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type StationPricingDetailsArgs = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type StationAccess = Node & {
  __typename?: "StationAccess";
  id: Scalars["ID"]["output"];
  stationCountryCode?: Maybe<Scalars["String"]["output"]>;
  stationName: Scalars["String"]["output"];
};

/** The connection type for Station. */
export type StationConnection = {
  __typename?: "StationConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Station>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type StationEdge = {
  __typename?: "StationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Station>;
};

export type StationEnergyPricingDetail = {
  __typename?: "StationEnergyPricingDetail";
  chargeType: ChargeTypeEnum;
  currency: Currency;
  endTimeOfDay: Scalars["String"]["output"];
  priceCents: Scalars["Int"]["output"];
  startTimeOfDay: Scalars["String"]["output"];
  timezone: Scalars["String"]["output"];
};

export type StationHourly = {
  __typename?: "StationHourly";
  currentDay: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type StationPricingDetail = {
  __typename?: "StationPricingDetail";
  chargeType: ChargeTypeEnum;
  currency: Currency;
  maxPower: Scalars["Int"]["output"];
  priceCents: Scalars["Int"]["output"];
};

export enum StationPublicStatus {
  Available = "AVAILABLE",
  ComingSoon = "COMING_SOON",
  OutOfService = "OUT_OF_SERVICE",
  Unavailable = "UNAVAILABLE",
  Unknown = "UNKNOWN",
}

export enum StationStatus {
  /** Closed */
  Closed = "closed",
  /** Closing Soon */
  ClosingSoon = "closing_soon",
  /** Opened */
  Opened = "opened",
  /** Opening Soon */
  OpeningSoon = "opening_soon",
}

export type Subscription = {
  __typename?: "Subscription";
  notificationSent: NotificationSentSubscriptionPayload;
  remoteChargeChanged: RemoteChargeChangedSubscriptionPayload;
  remoteChargeCreated: RemoteChargeCreatedSubscriptionPayload;
  singleBookingChanged: SingleBookingChangedSubscriptionPayload;
  spotChanged: SpotChangedSubscriptionPayload;
};

export type SubscriptionNotificationSentArgs = {
  accountId: Scalars["ID"]["input"];
};

export type SubscriptionRemoteChargeChangedArgs = {
  remoteChargeId: Scalars["ID"]["input"];
};

export type SubscriptionRemoteChargeCreatedArgs = {
  accountId: Scalars["ID"]["input"];
};

export type SubscriptionSingleBookingChangedArgs = {
  singleBookingId: Scalars["ID"]["input"];
};

export type SupportItem = SupportMethod | SupportSection;

export type SupportMethod = {
  __typename?: "SupportMethod";
  deeplink: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  subLabel?: Maybe<Scalars["String"]["output"]>;
  type: SupportMethodEnum;
};

export enum SupportMethodEnum {
  /** Chat */
  Chat = "chat",
  /** FAQ */
  Faq = "faq",
  /** Mail */
  Mail = "mail",
  /** Phone */
  Phone = "phone",
  /** Whatsapp */
  Whatsapp = "whatsapp",
}

export type SupportSection = {
  __typename?: "SupportSection";
  children: Array<SupportItem>;
  label: Scalars["String"]["output"];
};

/** Autogenerated input type of SwitchAccount */
export type SwitchAccountInput = {
  accountId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of SwitchAccount. */
export type SwitchAccountPayload = {
  __typename?: "SwitchAccountPayload";
  account?: Maybe<Account>;
  accountUser?: Maybe<AccountUser>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of Unsubscribe */
export type UnsubscribeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  unsubscribeReason: UserEmailUnsubscribeReason;
  unsubscribeReasonDetails?: InputMaybe<Scalars["String"]["input"]>;
  unsubscribeToken: Scalars["String"]["input"];
  userId: Scalars["ID"]["input"];
};

/** Autogenerated return type of Unsubscribe. */
export type UnsubscribePayload = {
  __typename?: "UnsubscribePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  emailConsent?: Maybe<UserEmailConsent>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of UpdateAccountUser */
export type UpdateAccountUserInput = {
  accountUserId: Scalars["ID"]["input"];
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  firstname?: InputMaybe<Scalars["String"]["input"]>;
  lastname?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<AccountUserRole>;
};

/** Autogenerated return type of UpdateAccountUser. */
export type UpdateAccountUserPayload = {
  __typename?: "UpdateAccountUserPayload";
  accountUser?: Maybe<AccountUser>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of UpdateBusinessCompany */
export type UpdateBusinessCompanyInput = {
  accountId: Scalars["ID"]["input"];
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["ID"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  infosManuallyProvided?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  postCode?: InputMaybe<Scalars["String"]["input"]>;
  siretNumber?: InputMaybe<Scalars["String"]["input"]>;
  vatId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of UpdateBusinessCompany. */
export type UpdateBusinessCompanyPayload = {
  __typename?: "UpdateBusinessCompanyPayload";
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  onboardingStatus?: Maybe<AccountOnboardingStatus>;
};

/** Autogenerated input type of UpdateCar */
export type UpdateCarInput = {
  carId: Scalars["ID"]["input"];
  carModelId?: InputMaybe<Scalars["ID"]["input"]>;
  carVersionId?: InputMaybe<Scalars["ID"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  licensePlateCountry?: InputMaybe<Country>;
  licensePlateNumber?: InputMaybe<Scalars["String"]["input"]>;
  manualPlugs?: InputMaybe<Array<ConnectorPlug>>;
  userVerifiedLicensePlate?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated return type of UpdateCar. */
export type UpdateCarPayload = {
  __typename?: "UpdateCarPayload";
  car?: Maybe<Car>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of UpdateCompany */
export type UpdateCompanyInput = {
  accountUserId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  informations: Scalars["String"]["input"];
};

/** Autogenerated return type of UpdateCompany. */
export type UpdateCompanyPayload = {
  __typename?: "UpdateCompanyPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  company?: Maybe<Company>;
  errors: Array<Scalars["String"]["output"]>;
  successMessage?: Maybe<Scalars["String"]["output"]>;
  successTitle?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of UpdateCustomerDetail */
export type UpdateCustomerDetailInput = {
  accountId: Scalars["ID"]["input"];
  city?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  countryId: Scalars["ID"]["input"];
  fiscalCode?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of UpdateCustomerDetail. */
export type UpdateCustomerDetailPayload = {
  __typename?: "UpdateCustomerDetailPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  customerDetail?: Maybe<CustomerDetail>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of UpdateEmail */
export type UpdateEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
};

/** Autogenerated return type of UpdateEmail. */
export type UpdateEmailPayload = {
  __typename?: "UpdateEmailPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Email>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of UpdatePaymentIntent */
export type UpdatePaymentIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  paymentIntentId: Scalars["ID"]["input"];
  paymentMethodId: Scalars["ID"]["input"];
  returnUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of UpdatePaymentIntent. */
export type UpdatePaymentIntentPayload = {
  __typename?: "UpdatePaymentIntentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  paymentIntent?: Maybe<PaymentIntent>;
};

/** Autogenerated input type of UpdatePaymentMethod */
export type UpdatePaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethodId: Scalars["ID"]["input"];
  processoutCardRef: Scalars["String"]["input"];
  processoutTokenRef?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of UpdatePaymentMethod. */
export type UpdatePaymentMethodPayload = {
  __typename?: "UpdatePaymentMethodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<PaymentMethod>;
};

/** Autogenerated input type of UpdateUserEmailConsent */
export type UpdateUserEmailConsentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  marketingConsent: Scalars["Boolean"]["input"];
  unsubscribeReason?: InputMaybe<UserEmailUnsubscribeReason>;
  unsubscribeReasonDetails?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["ID"]["input"];
};

/** Autogenerated return type of UpdateUserEmailConsent. */
export type UpdateUserEmailConsentPayload = {
  __typename?: "UpdateUserEmailConsentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  emailConsent?: Maybe<UserEmailConsent>;
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated input type of UpdateUserPhoneNumber */
export type UpdateUserPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  phoneChallengeCompletionToken: Scalars["String"]["input"];
  userId: Scalars["ID"]["input"];
};

/** Autogenerated return type of UpdateUserPhoneNumber. */
export type UpdateUserPhoneNumberPayload = {
  __typename?: "UpdateUserPhoneNumberPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UploadBusinessDocuments */
export type UploadBusinessDocumentsInput = {
  accountId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  countryKey: Country;
  documentFiles: Array<DocumentFileDataType>;
};

/** Autogenerated return type of UploadBusinessDocuments. */
export type UploadBusinessDocumentsPayload = {
  __typename?: "UploadBusinessDocumentsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  onboardingStatus?: Maybe<AccountOnboardingStatus>;
};

export type User = Node & {
  __typename?: "User";
  account?: Maybe<Account>;
  accounts: AccountConnection;
  email?: Maybe<Email>;
  emailConsent?: Maybe<UserEmailConsent>;
  fallbackAccount: Account;
  /** @deprecated Use account_user */
  firstname?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  invitation?: Maybe<Invitation>;
  invitations: InvitationConnection;
  /** @deprecated Use account_user */
  lastname?: Maybe<Scalars["String"]["output"]>;
  onboardingStatus?: Maybe<AccountOnboardingStatus>;
  phoneNumber: Scalars["String"]["output"];
};

export type UserAccountArgs = {
  id: Scalars["ID"]["input"];
};

export type UserAccountsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserInvitationArgs = {
  id: Scalars["ID"]["input"];
};

export type UserInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: "UserConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UserDeletionIntent = Node & {
  __typename?: "UserDeletionIntent";
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: "UserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserEmailConsent = Node & {
  __typename?: "UserEmailConsent";
  id: Scalars["ID"]["output"];
  marketingConsent: Scalars["Boolean"]["output"];
  unsubscribeReason?: Maybe<UserEmailUnsubscribeReason>;
  unsubscribeReasonDetails?: Maybe<Scalars["String"]["output"]>;
};

export enum UserEmailUnsubscribeReason {
  /** When user received too many emails */
  FrequencyTooHigh = "FREQUENCY_TOO_HIGH",
  /** When user received irrelevant emails */
  IrrelevantContent = "IRRELEVANT_CONTENT",
  /** An unsubscribe_reason_details will be provided for more details */
  Other = "OTHER",
  /** When user refused to receive email on signup */
  RefusedFromStart = "REFUSED_FROM_START",
  /** When user refused to receive email in app settings */
  RefusedInSettings = "REFUSED_IN_SETTINGS",
}

export type Webview = {
  __typename?: "Webview";
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  new: Scalars["Boolean"]["output"];
  url: Scalars["String"]["output"];
};

export type AccountSelector_AccountFragment = { __typename?: "Account"; id: string; label: string };

export type StationStatusBadge_StationFragment = {
  __typename?: "Station";
  availableSpotsCount: number;
  comingSoonLabel?: string | null;
  id: string;
  publicStatus: StationPublicStatus;
  spots: Array<{ __typename?: "Spot"; id: string; publicAvailability: SpotPublicAvailability }>;
  spotsCount: number;
};

export type AccountSelectorBottomSheet_AccountFragment = {
  __typename?: "Account";
  id: string;
  label: string;
  pro: boolean;
};

export type StationsPageStationSheet_StationFragment = {
  __typename?: "Station";
  address: string;
  availableSpotsCount: number;
  comingSoonLabel?: string | null;
  fastSpeedPricingDetail?: {
    __typename?: "StationPricingDetail";
    chargeType: ChargeTypeEnum;
    currency: Currency;
    maxPower: number;
    priceCents: number;
  } | null;
  id: string;
  latitude: number;
  longitude: number;
  name: string;
  nextClosing?: string | null;
  nextOpening?: string | null;
  parkingTypeLabel?: string | null;
  publicAvailablePower?: number | null;
  publicStatus: StationPublicStatus;
  spots: Array<{ __typename?: "Spot"; id: string; publicAvailability: SpotPublicAvailability }>;
  spotsCount: number;
  status: StationStatus;
};

export type Drawer_AccountUserFragment = { __typename?: "AccountUser"; firstname: string; id: string };

export type StationSummary_StationFragment = {
  __typename?: "Station";
  id: string;
  parkingTypeLabel?: string | null;
  publicAvailablePower?: number | null;
};

export type AccountUserFragment = {
  __typename?: "AccountUser";
  bulkInvitationFileUrl?: string | null;
  canEdit: boolean;
  canEditRole: boolean;
  canListUsers: boolean;
  canShowDashboard: boolean;
  firstname: string;
  id: string;
  role: AccountUserRole;
};

export type AccountFragment = {
  __typename?: "Account";
  accountUser?: {
    __typename?: "AccountUser";
    bulkInvitationFileUrl?: string | null;
    canEdit: boolean;
    canEditRole: boolean;
    canListUsers: boolean;
    canShowDashboard: boolean;
    firstname: string;
    id: string;
    role: AccountUserRole;
  } | null;
  id: string;
  invitations: {
    __typename?: "InvitationConnection";
    nodes?: Array<{ __typename?: "Invitation"; id: string } | null> | null;
  };
  label: string;
  members: {
    __typename?: "AccountUserConnection";
    nodes?: Array<{ __typename?: "AccountUser"; id: string } | null> | null;
  };
  pro: boolean;
};

export type OnboardingStatusFragment = {
  __typename?: "AccountOnboardingStatus";
  country?: { __typename?: "CountryObject"; key: Country } | null;
  missingInfos: Array<AccountMissingInfos>;
  proType?: ProAccountType | null;
  proTypeLabel?: ProAccountLabelType | null;
  status: AccountValidationStatus;
};

export type MembersFragment = {
  __typename?: "Account";
  invitations: {
    __typename?: "InvitationConnection";
    nodes?: Array<{ __typename?: "Invitation"; id: string } | null> | null;
  };
  members: {
    __typename?: "AccountUserConnection";
    nodes?: Array<{ __typename?: "AccountUser"; id: string } | null> | null;
  };
};

export type LayoutFragment = {
  __typename?: "User";
  accounts: {
    __typename?: "AccountConnection";
    nodes?: Array<{
      __typename?: "Account";
      accountUser?: {
        __typename?: "AccountUser";
        bulkInvitationFileUrl?: string | null;
        canEdit: boolean;
        canEditRole: boolean;
        canListUsers: boolean;
        canShowDashboard: boolean;
        firstname: string;
        id: string;
        role: AccountUserRole;
      } | null;
      id: string;
      invitations: {
        __typename?: "InvitationConnection";
        nodes?: Array<{ __typename?: "Invitation"; id: string } | null> | null;
      };
      label: string;
      members: {
        __typename?: "AccountUserConnection";
        nodes?: Array<{ __typename?: "AccountUser"; id: string } | null> | null;
      };
      pro: boolean;
    } | null> | null;
  };
  id: string;
  onboardingStatus?: {
    __typename?: "AccountOnboardingStatus";
    country?: { __typename?: "CountryObject"; key: Country } | null;
    missingInfos: Array<AccountMissingInfos>;
    proType?: ProAccountType | null;
    proTypeLabel?: ProAccountLabelType | null;
    status: AccountValidationStatus;
  } | null;
  phoneNumber: string;
};

export type SwitchAccountMutationVariables = Exact<{
  input: SwitchAccountInput;
}>;

export type SwitchAccountMutation = {
  __typename?: "Mutation";
  switchAccount?: {
    __typename?: "SwitchAccountPayload";
    account?: { __typename?: "Account"; id: string } | null;
    accountUser?: { __typename?: "AccountUser"; id: string } | null;
    errors: Array<string>;
  } | null;
};

export type LayoutQueryVariables = Exact<{ [key: string]: never }>;

export type LayoutQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    accounts: {
      __typename?: "AccountConnection";
      nodes?: Array<{
        __typename?: "Account";
        accountUser?: {
          __typename?: "AccountUser";
          bulkInvitationFileUrl?: string | null;
          canEdit: boolean;
          canEditRole: boolean;
          canListUsers: boolean;
          canShowDashboard: boolean;
          firstname: string;
          id: string;
          role: AccountUserRole;
        } | null;
        id: string;
        invitations: {
          __typename?: "InvitationConnection";
          nodes?: Array<{ __typename?: "Invitation"; id: string } | null> | null;
        };
        label: string;
        members: {
          __typename?: "AccountUserConnection";
          nodes?: Array<{ __typename?: "AccountUser"; id: string } | null> | null;
        };
        pro: boolean;
      } | null> | null;
    };
    id: string;
    onboardingStatus?: {
      __typename?: "AccountOnboardingStatus";
      country?: { __typename?: "CountryObject"; key: Country } | null;
      missingInfos: Array<AccountMissingInfos>;
      proType?: ProAccountType | null;
      proTypeLabel?: ProAccountLabelType | null;
      status: AccountValidationStatus;
    } | null;
    phoneNumber: string;
  } | null;
};

export type ChargesPage_ChargeFragment = {
  __typename?: "Charge";
  accountTagLabel?: string | null;
  canShowPricing: boolean;
  car?: { __typename?: "Car"; friendlyIdentifier: string; friendlyName: string; id: string } | null;
  currency: Currency;
  date: string;
  driverFullname?: string | null;
  driverPhoneNumber?: string | null;
  endSoc?: number | null;
  id: string;
  invoice?: { __typename?: "Invoice"; fileUrl?: string | null; id: string; status: InvoiceStatusEnum } | null;
  source: ChargeSourceEnum;
  startSoc?: number | null;
  station: { __typename?: "Station"; id: string; name: string };
  totalAmountCents: number;
  totalDuration: number;
  totalEnergy: number;
};

export type ChargesPage_PageInfoFragment = {
  __typename?: "PageInfo";
  endCursor?: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string | null;
};

export type ExportProChargesMutationVariables = Exact<{
  input: ExportProChargesInput;
}>;

export type ExportProChargesMutation = {
  __typename?: "Mutation";
  exportProCharges?: {
    __typename?: "ExportProChargesPayload";
    errors: Array<string>;
    successMessage?: string | null;
  } | null;
};

export type ChargesPageQueryVariables = Exact<{
  accountId: Scalars["ID"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<ChargeSortType>;
}>;

export type ChargesPageQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    account?: {
      __typename?: "Account";
      charges: {
        __typename?: "ChargeConnection";
        nodes?: Array<{
          __typename?: "Charge";
          accountTagLabel?: string | null;
          canShowPricing: boolean;
          car?: { __typename?: "Car"; friendlyIdentifier: string; friendlyName: string; id: string } | null;
          currency: Currency;
          date: string;
          driverFullname?: string | null;
          driverPhoneNumber?: string | null;
          endSoc?: number | null;
          id: string;
          invoice?: { __typename?: "Invoice"; fileUrl?: string | null; id: string; status: InvoiceStatusEnum } | null;
          source: ChargeSourceEnum;
          startSoc?: number | null;
          station: { __typename?: "Station"; id: string; name: string };
          totalAmountCents: number;
          totalDuration: number;
          totalEnergy: number;
        } | null> | null;
        pageInfo: {
          __typename?: "PageInfo";
          endCursor?: string | null;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
          startCursor?: string | null;
        };
      };
      chargesCount: number;
      id: string;
    } | null;
    id: string;
  } | null;
};

export type DashboardPageQueryVariables = Exact<{
  accountId: Scalars["ID"]["input"];
}>;

export type DashboardPageQuery = {
  __typename?: "Query";
  me?: { __typename?: "User"; account?: { __typename?: "Account"; dashboardIframeUrl?: string | null } | null } | null;
};

export type SessionlessConfirmEmailMutationVariables = Exact<{
  input: SessionlessConfirmEmailInput;
}>;

export type SessionlessConfirmEmailMutation = {
  __typename?: "Mutation";
  sessionlessConfirmEmail?: {
    __typename?: "SessionlessConfirmEmailPayload";
    errors: Array<string>;
    success?: boolean | null;
  } | null;
};

export type InvitationPage_InvitationFragment = {
  __typename?: "Invitation";
  canReinvite: boolean;
  confirmedAt?: string | null;
  createdBy: { __typename?: "AccountUser"; fullname: string; id: string };
  firstname: string;
  fullname: string;
  id: string;
  lastname: string;
  phoneNumber: string;
  reinvitationErrorMessage?: string | null;
  status: InvitationStatusEnum;
};

export type ReinviteUserMutationVariables = Exact<{
  input: ReinviteUserInput;
}>;

export type ReinviteUserMutation = {
  __typename?: "Mutation";
  reinviteUser?: {
    __typename?: "ReinviteUserPayload";
    errors: Array<string>;
    invitation?: { __typename?: "Invitation"; id: string } | null;
    successMessage?: string | null;
    successTitle?: string | null;
  } | null;
};

export type DeleteInvitationMutationVariables = Exact<{
  input: DeleteInvitationInput;
}>;

export type DeleteInvitationMutation = {
  __typename?: "Mutation";
  deleteInvitation?: {
    __typename?: "DeleteInvitationPayload";
    errors: Array<string>;
    successMessage?: string | null;
  } | null;
};

export type InvitationPageQueryVariables = Exact<{
  accountId: Scalars["ID"]["input"];
  invitationId: Scalars["ID"]["input"];
}>;

export type InvitationPageQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    account?: {
      __typename?: "Account";
      id: string;
      invitation?: {
        __typename?: "Invitation";
        canReinvite: boolean;
        confirmedAt?: string | null;
        createdBy: { __typename?: "AccountUser"; fullname: string; id: string };
        firstname: string;
        fullname: string;
        id: string;
        lastname: string;
        phoneNumber: string;
        reinvitationErrorMessage?: string | null;
        status: InvitationStatusEnum;
      } | null;
    } | null;
    id: string;
  } | null;
};

export type CompanyScreen_CountryFragment = { __typename?: "CountryObject"; key: Country; name: string };

export type CompanyScreen_DocumentFragment = {
  __typename?: "BusinessDocument";
  documentType: DocumentType;
  label: string;
  sublabel: string;
};

export type RegisterPageStartPhoneChallengeMutationVariables = Exact<{
  input: StartPhoneChallengeInput;
}>;

export type RegisterPageStartPhoneChallengeMutation = {
  __typename?: "Mutation";
  startPhoneChallenge?: {
    __typename?: "StartPhoneChallengePayload";
    challengeToken?: string | null;
    errors: Array<string>;
  } | null;
};

export type RegisterPageCompletePhoneChallengeMutationVariables = Exact<{
  input: CompletePhoneChallengeInput;
}>;

export type RegisterPageCompletePhoneChallengeMutation = {
  __typename?: "Mutation";
  completePhoneChallenge?: {
    __typename?: "CompletePhoneChallengePayload";
    completionToken?: string | null;
    errors: Array<string>;
  } | null;
};

export type RegisterPageCreateProAccountMutationVariables = Exact<{
  input: CreateProAccountInput;
}>;

export type RegisterPageCreateProAccountMutation = {
  __typename?: "Mutation";
  createProAccount?: {
    __typename?: "CreateProAccountPayload";
    account?: {
      __typename?: "Account";
      id: string;
      invitations: {
        __typename?: "InvitationConnection";
        nodes?: Array<{ __typename?: "Invitation"; id: string } | null> | null;
      };
      members: {
        __typename?: "AccountUserConnection";
        nodes?: Array<{ __typename?: "AccountUser"; id: string } | null> | null;
      };
    } | null;
    errors: Array<string>;
    onboardingStatus?: {
      __typename?: "AccountOnboardingStatus";
      country?: { __typename?: "CountryObject"; key: Country } | null;
      missingInfos: Array<AccountMissingInfos>;
      proType?: ProAccountType | null;
      proTypeLabel?: ProAccountLabelType | null;
      status: AccountValidationStatus;
    } | null;
    sessionToken?: string | null;
  } | null;
};

export type RegisterPageSearchBusinessCompanyMutationVariables = Exact<{
  input: SearchBusinessCompanyInput;
}>;

export type RegisterPageSearchBusinessCompanyMutation = {
  __typename?: "Mutation";
  searchBusinessCompany?: {
    __typename?: "SearchBusinessCompanyPayload";
    address?: string | null;
    city?: string | null;
    companyIdentificationNumber?: string | null;
    companyIdentificationType?: BusinessCompanyIdentificationType | null;
    country?: { __typename?: "CountryObject"; id: string; name: string } | null;
    errorKeys?: Array<string> | null;
    errors: Array<string>;
    name?: string | null;
    postCode?: string | null;
  } | null;
};

export type RegisterPageUpdateBusinessCompanyMutationVariables = Exact<{
  input: UpdateBusinessCompanyInput;
}>;

export type RegisterPageUpdateBusinessCompanyMutation = {
  __typename?: "Mutation";
  updateBusinessCompany?: {
    __typename?: "UpdateBusinessCompanyPayload";
    errors: Array<string>;
    onboardingStatus?: {
      __typename?: "AccountOnboardingStatus";
      country?: { __typename?: "CountryObject"; key: Country } | null;
      missingInfos: Array<AccountMissingInfos>;
      proType?: ProAccountType | null;
      proTypeLabel?: ProAccountLabelType | null;
      status: AccountValidationStatus;
    } | null;
  } | null;
};

export type RegisterPageUploadBusinessDocumentsMutationVariables = Exact<{
  input: UploadBusinessDocumentsInput;
}>;

export type RegisterPageUploadBusinessDocumentsMutation = {
  __typename?: "Mutation";
  uploadBusinessDocuments?: {
    __typename?: "UploadBusinessDocumentsPayload";
    errors: Array<string>;
    onboardingStatus?: {
      __typename?: "AccountOnboardingStatus";
      country?: { __typename?: "CountryObject"; key: Country } | null;
      missingInfos: Array<AccountMissingInfos>;
      proType?: ProAccountType | null;
      proTypeLabel?: ProAccountLabelType | null;
      status: AccountValidationStatus;
    } | null;
  } | null;
};

export type RegisterPageQueryVariables = Exact<{ [key: string]: never }>;

export type RegisterPageQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    id: string;
    onboardingStatus?: {
      __typename?: "AccountOnboardingStatus";
      country?: { __typename?: "CountryObject"; key: Country } | null;
      missingInfos: Array<AccountMissingInfos>;
      proType?: ProAccountType | null;
      proTypeLabel?: ProAccountLabelType | null;
      status: AccountValidationStatus;
    } | null;
  } | null;
};

export type DocumentsScreenQueryVariables = Exact<{
  countryKey: Country;
}>;

export type DocumentsScreenQuery = {
  __typename?: "Query";
  businessCompanyRequiredDocuments: Array<{
    __typename?: "BusinessDocument";
    documentType: DocumentType;
    label: string;
    sublabel: string;
  }>;
};

export type SettingsPageScreen_AccountFragment = {
  __typename?: "Account";
  accountUser?: { __typename?: "AccountUser"; canDelete: boolean; id: string } | null;
  id: string;
};

export type SettingsPageQueryVariables = Exact<{
  accountId: Scalars["ID"]["input"];
}>;

export type SettingsPageQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    account?: {
      __typename?: "Account";
      accountUser?: { __typename?: "AccountUser"; canDelete: boolean; id: string } | null;
      id: string;
    } | null;
    id: string;
  } | null;
};

export type SignInPageStartPhoneChallengeMutationVariables = Exact<{
  input: StartPhoneChallengeInput;
}>;

export type SignInPageStartPhoneChallengeMutation = {
  __typename?: "Mutation";
  startPhoneChallenge?: {
    __typename?: "StartPhoneChallengePayload";
    challengeToken?: string | null;
    errors: Array<string>;
  } | null;
};

export type SignInPageCompletePhoneChallengeMutationVariables = Exact<{
  input: CompletePhoneChallengeInput;
}>;

export type SignInPageCompletePhoneChallengeMutation = {
  __typename?: "Mutation";
  completePhoneChallenge?: {
    __typename?: "CompletePhoneChallengePayload";
    completionToken?: string | null;
    errors: Array<string>;
  } | null;
};

export type SignInPageMutationVariables = Exact<{
  input: SignInInput;
}>;

export type SignInPageMutation = {
  __typename?: "Mutation";
  signIn?: {
    __typename?: "SignInPayload";
    errors: Array<string>;
    sessionToken?: string | null;
    user?: {
      __typename?: "User";
      accounts: {
        __typename?: "AccountConnection";
        nodes?: Array<{
          __typename?: "Account";
          accountUser?: {
            __typename?: "AccountUser";
            bulkInvitationFileUrl?: string | null;
            canEdit: boolean;
            canEditRole: boolean;
            canListUsers: boolean;
            canShowDashboard: boolean;
            firstname: string;
            id: string;
            role: AccountUserRole;
          } | null;
          id: string;
          invitations: {
            __typename?: "InvitationConnection";
            nodes?: Array<{ __typename?: "Invitation"; id: string } | null> | null;
          };
          label: string;
          members: {
            __typename?: "AccountUserConnection";
            nodes?: Array<{ __typename?: "AccountUser"; id: string } | null> | null;
          };
          pro: boolean;
        } | null> | null;
      };
      id: string;
      onboardingStatus?: {
        __typename?: "AccountOnboardingStatus";
        country?: { __typename?: "CountryObject"; key: Country } | null;
        missingInfos: Array<AccountMissingInfos>;
        proType?: ProAccountType | null;
        proTypeLabel?: ProAccountLabelType | null;
        status: AccountValidationStatus;
      } | null;
      phoneNumber: string;
    } | null;
  } | null;
};

export type SignOutPageMutationVariables = Exact<{
  input: SignOutInput;
}>;

export type SignOutPageMutation = {
  __typename?: "Mutation";
  signOut?: { __typename?: "SignOutPayload"; errors: Array<string>; signedOut?: boolean | null } | null;
};

export type StationsPageQueryVariables = Exact<{
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type StationsPageQuery = {
  __typename?: "Query";
  stations: {
    __typename?: "StationConnection";
    nodes?: Array<{
      __typename?: "Station";
      address: string;
      availableSpotsCount: number;
      comingSoonLabel?: string | null;
      fastSpeedPricingDetail?: {
        __typename?: "StationPricingDetail";
        chargeType: ChargeTypeEnum;
        currency: Currency;
        maxPower: number;
        priceCents: number;
      } | null;
      id: string;
      latitude: number;
      longitude: number;
      name: string;
      nextClosing?: string | null;
      nextOpening?: string | null;
      parkingTypeLabel?: string | null;
      publicAvailablePower?: number | null;
      publicStatus: StationPublicStatus;
      spots: Array<{ __typename?: "Spot"; id: string; publicAvailability: SpotPublicAvailability }>;
      spotsCount: number;
      status: StationStatus;
    } | null> | null;
  };
};

export type StationsPageSpotSubscriptionVariables = Exact<{ [key: string]: never }>;

export type StationsPageSpotSubscription = {
  __typename?: "Subscription";
  spotChanged: {
    __typename?: "SpotChangedSubscriptionPayload";
    spot: { __typename?: "Spot"; id: string; publicAvailability: SpotPublicAvailability };
  };
};

export type SupportPageScreen_SupportSectionFragment = {
  __typename?: "SupportSection";
  children: Array<
    | {
        __typename: "SupportMethod";
        deeplink: string;
        label: string;
        subLabel?: string | null;
        type: SupportMethodEnum;
      }
    | { __typename: "SupportSection" }
  >;
  label: string;
};

export type SupportPageQueryVariables = Exact<{ [key: string]: never }>;

export type SupportPageQuery = {
  __typename?: "Query";
  supportSections: Array<{
    __typename?: "SupportSection";
    children: Array<
      | {
          __typename: "SupportMethod";
          deeplink: string;
          label: string;
          subLabel?: string | null;
          type: SupportMethodEnum;
        }
      | { __typename: "SupportSection" }
    >;
    label: string;
  }>;
};

export type CreateUserDeletionIntentMutationVariables = Exact<{
  input: CreateUserDeletionIntentInput;
}>;

export type CreateUserDeletionIntentMutation = {
  __typename?: "Mutation";
  createUserDeletionIntent?: {
    __typename?: "CreateUserDeletionIntentPayload";
    errors: Array<string>;
    userDeletionIntent?: { __typename?: "UserDeletionIntent"; id: string; message: string } | null;
  } | null;
};

export type UserDeletionIntentPage_UserDeletionIntentFragment = {
  __typename?: "UserDeletionIntent";
  id: string;
  message: string;
};

export type ConfirmUserDeletionIntentMutationVariables = Exact<{
  input: ConfirmUserDeletionIntentInput;
}>;

export type ConfirmUserDeletionIntentMutation = {
  __typename?: "Mutation";
  confirmUserDeletionIntent?: {
    __typename?: "ConfirmUserDeletionIntentPayload";
    deleted?: boolean | null;
    errors: Array<string>;
  } | null;
};

export type UserDeletionIntentPageQueryVariables = Exact<{
  accountId: Scalars["ID"]["input"];
  userDeletionIntentId: Scalars["ID"]["input"];
}>;

export type UserDeletionIntentPageQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    account?: {
      __typename?: "Account";
      id: string;
      userDeletionIntent?: { __typename?: "UserDeletionIntent"; id: string; message: string } | null;
    } | null;
    id: string;
  } | null;
};

export type UserInfoPageQueryVariables = Exact<{
  accountId: Scalars["ID"]["input"];
  accountUserId: Scalars["ID"]["input"];
}>;

export type UserInfoPageQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    account?: {
      __typename?: "Account";
      accountUser?: {
        __typename?: "AccountUser";
        active: boolean;
        canEdit: boolean;
        canEditRole: boolean;
        firstname: string;
        fullname: string;
        id: string;
        invitedBy?: { __typename?: "AccountUser"; fullname: string; id: string } | null;
        joinedAt?: string | null;
        lastname?: string | null;
        role: AccountUserRole;
        user: { __typename?: "User"; id: string; phoneNumber: string };
      } | null;
      id: string;
      pro: boolean;
    } | null;
    id: string;
  } | null;
};

export type UserPageScreen_AccountUserFragment = {
  __typename?: "AccountUser";
  active: boolean;
  canEdit: boolean;
  canEditRole: boolean;
  firstname: string;
  fullname: string;
  id: string;
  invitedBy?: { __typename?: "AccountUser"; fullname: string; id: string } | null;
  joinedAt?: string | null;
  lastname?: string | null;
  role: AccountUserRole;
  user: { __typename?: "User"; id: string; phoneNumber: string };
};

export type UpdateAccountUserPageMutationVariables = Exact<{
  input: UpdateAccountUserInput;
}>;

export type UpdateAccountUserPageMutation = {
  __typename?: "Mutation";
  updateAccountUser?: {
    __typename?: "UpdateAccountUserPayload";
    accountUser?: {
      __typename?: "AccountUser";
      active: boolean;
      canEdit: boolean;
      canEditRole: boolean;
      firstname: string;
      fullname: string;
      id: string;
      invitedBy?: { __typename?: "AccountUser"; fullname: string; id: string } | null;
      joinedAt?: string | null;
      lastname?: string | null;
      role: AccountUserRole;
      user: { __typename?: "User"; id: string; phoneNumber: string };
    } | null;
    errors: Array<string>;
  } | null;
};

export type UserPageQueryVariables = Exact<{
  accountId: Scalars["ID"]["input"];
  accountUserId: Scalars["ID"]["input"];
}>;

export type UserPageQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    account?: {
      __typename?: "Account";
      accountUser?: {
        __typename?: "AccountUser";
        active: boolean;
        canEdit: boolean;
        canEditRole: boolean;
        firstname: string;
        fullname: string;
        id: string;
        invitedBy?: { __typename?: "AccountUser"; fullname: string; id: string } | null;
        joinedAt?: string | null;
        lastname?: string | null;
        role: AccountUserRole;
        user: { __typename?: "User"; id: string; phoneNumber: string };
      } | null;
      id: string;
    } | null;
    id: string;
  } | null;
};

export type InvitationNewPage_InvitationFragment = {
  __typename?: "Invitation";
  firstname: string;
  id: string;
  lastname: string;
  phoneNumber: string;
};

export type CreateMultipleInvitationMutationVariables = Exact<{
  input: CreateMultipleInvitationsInput;
}>;

export type CreateMultipleInvitationMutation = {
  __typename?: "Mutation";
  createMultipleInvitations?: {
    __typename?: "CreateMultipleInvitationsPayload";
    account?: { __typename?: "Account"; id: string } | null;
    errorMessage?: string | null;
    errors: Array<string>;
    sentInvitationsCount: number;
    successMessage?: string | null;
  } | null;
};

export type CreateInvitationMutationVariables = Exact<{
  input: CreateInvitationInput;
}>;

export type CreateInvitationMutation = {
  __typename?: "Mutation";
  createInvitation?: {
    __typename?: "CreateInvitationPayload";
    errors: Array<string>;
    invitation?: {
      __typename?: "Invitation";
      firstname: string;
      id: string;
      lastname: string;
      phoneNumber: string;
    } | null;
    successMessage?: string | null;
    successTitle?: string | null;
  } | null;
};

export type UsersPageScreen_AccountUserFragment = {
  __typename?: "AccountUser";
  active: boolean;
  fullname: string;
  id: string;
  role: AccountUserRole;
  user: { __typename?: "User"; id: string };
};

export type UsersPageScreen_InvitationFragment = {
  __typename?: "Invitation";
  canReinvite: boolean;
  fullname: string;
  id: string;
  refusedAt?: string | null;
};

export type BulkReinviteUsersMutationVariables = Exact<{
  input: BulkReinviteUsersInput;
}>;

export type BulkReinviteUsersMutation = {
  __typename?: "Mutation";
  bulkReinviteUsers?: {
    __typename?: "BulkReinviteUsersPayload";
    errorMessage?: string | null;
    errors: Array<string>;
    sentReinvitationsCount: number;
    successMessage?: string | null;
    warningMessage?: string | null;
  } | null;
};

export type UsersPageQueryVariables = Exact<{
  accountId: Scalars["ID"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UsersPageQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    account?: {
      __typename?: "Account";
      admins: {
        __typename?: "AccountUserConnection";
        nodes?: Array<{
          __typename?: "AccountUser";
          active: boolean;
          fullname: string;
          id: string;
          role: AccountUserRole;
          user: { __typename?: "User"; id: string };
        } | null> | null;
      };
      id: string;
      invitations: {
        __typename?: "InvitationConnection";
        nodes?: Array<{
          __typename?: "Invitation";
          canReinvite: boolean;
          fullname: string;
          id: string;
          refusedAt?: string | null;
        } | null> | null;
      };
      members: {
        __typename?: "AccountUserConnection";
        nodes?: Array<{
          __typename?: "AccountUser";
          active: boolean;
          fullname: string;
          id: string;
          role: AccountUserRole;
          user: { __typename?: "User"; id: string };
        } | null> | null;
      };
    } | null;
    id: string;
  } | null;
};

export type PhoneNumberScreen_CountryObjectFragment = {
  __typename?: "CountryObject";
  callingCode: string;
  id: string;
  isHighlighted: boolean;
  key: Country;
  name: string;
  selfServeAvailable: boolean;
};

export type PhoneNumberScreenQueryVariables = Exact<{ [key: string]: never }>;

export type PhoneNumberScreenQuery = {
  __typename?: "Query";
  countries: Array<{
    __typename?: "CountryObject";
    callingCode: string;
    id: string;
    isHighlighted: boolean;
    key: Country;
    name: string;
    selfServeAvailable: boolean;
  }>;
};

export type PaymentIntentAuthorizeBottomSheet_PaymentIntentFragment = {
  __typename?: "PaymentIntent";
  authorizationAmountCents: number;
  authorizationStatus: string;
  currency: Currency;
  id: string;
  processoutInvoiceRef?: string | null;
  processoutMitEnabled: boolean;
};

export type AnonymousAccessPage_StationAccessFragment = {
  __typename?: "StationAccess";
  stationCountryCode?: string | null;
  stationName: string;
};

export type AnonymousAccessPage_CountryObjectFragment = {
  __typename?: "CountryObject";
  callingCode: string;
  id: string;
  key: Country;
  name: string;
};

export type AnonymousAccessPageCreateAnonymousRightMutationVariables = Exact<{
  input: CreateAnonymousRightInput;
}>;

export type AnonymousAccessPageCreateAnonymousRightMutation = {
  __typename?: "Mutation";
  createAnonymousRight?: {
    __typename?: "CreateAnonymousRightPayload";
    errors: Array<string>;
    success?: boolean | null;
  } | null;
};

export type AnonymousAccessPageQueryVariables = Exact<{
  accessId: Scalars["ID"]["input"];
}>;

export type AnonymousAccessPageQuery = {
  __typename?: "Query";
  countries: Array<{ __typename?: "CountryObject"; callingCode: string; id: string; key: Country; name: string }>;
  stationAccess?: { __typename?: "StationAccess"; stationCountryCode?: string | null; stationName: string } | null;
};

export type ChargePointPage_ChargePointFragment = {
  __typename?: "ChargePoint";
  connectors: Array<{
    __typename?: "Connector";
    anonymouslyOccupied: boolean;
    availability: ConnectorPublicAvailability;
    chargeType: ChargeTypeEnum;
    id: string;
    maxPowerWatts: number;
    number: number;
    plug: ConnectorPlug;
    plugShortName: string;
    pricingDetail?: string | null;
    readyToCharge: boolean;
    spot: { __typename?: "Spot"; id: string; number: number; publicLabel: string; reducedMobility: boolean };
  }>;
  station: { __typename?: "Station"; id: string; name: string; status: StationStatus };
};

export type ChargePointPage_ConnectorFragment = {
  __typename?: "Connector";
  anonymouslyOccupied: boolean;
  availability: ConnectorPublicAvailability;
  chargeType: ChargeTypeEnum;
  id: string;
  maxPowerWatts: number;
  number: number;
  plug: ConnectorPlug;
  plugShortName: string;
  pricingDetail?: string | null;
  readyToCharge: boolean;
  spot: { __typename?: "Spot"; id: string; number: number; publicLabel: string; reducedMobility: boolean };
};

export type CurrentRemoteChargeFragment = {
  __typename?: "AnonymousSession";
  currentRemoteCharge?: { __typename?: "RemoteCharge"; id: string } | null;
  id: string;
};

export type CreateAnonymousChargeIntentMutationVariables = Exact<{
  input: CreateAnonymousChargeIntentInput;
}>;

export type CreateAnonymousChargeIntentMutation = {
  __typename?: "Mutation";
  createAnonymousChargeIntent?: {
    __typename?: "CreateAnonymousChargeIntentPayload";
    accountId?: string | null;
    errors: Array<string>;
    sessionToken?: string | null;
  } | null;
};

export type ChargePointPageQueryVariables = Exact<{
  forAnonymousSession?: InputMaybe<Scalars["Boolean"]["input"]>;
  publicCode: Scalars["String"]["input"];
}>;

export type ChargePointPageQuery = {
  __typename?: "Query";
  chargePoint?: {
    __typename?: "ChargePoint";
    connectors: Array<{
      __typename?: "Connector";
      anonymouslyOccupied: boolean;
      availability: ConnectorPublicAvailability;
      chargeType: ChargeTypeEnum;
      id: string;
      maxPowerWatts: number;
      number: number;
      plug: ConnectorPlug;
      plugShortName: string;
      pricingDetail?: string | null;
      readyToCharge: boolean;
      spot: { __typename?: "Spot"; id: string; number: number; publicLabel: string; reducedMobility: boolean };
    }>;
    id: string;
    station: { __typename?: "Station"; id: string; name: string; status: StationStatus };
  } | null;
};

export type CurrentRemoteChargeQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentRemoteChargeQuery = {
  __typename?: "Query";
  anonymousSession?: {
    __typename?: "AnonymousSession";
    currentRemoteCharge?: { __typename?: "RemoteCharge"; id: string } | null;
    id: string;
  } | null;
};

export type RetrieveAnonymousSessionMutationVariables = Exact<{
  input: RetrieveAnonymousSessionInput;
}>;

export type RetrieveAnonymousSessionMutation = {
  __typename?: "Mutation";
  retrieveAnonymousSession?: {
    __typename?: "RetrieveAnonymousSessionPayload";
    errors: Array<string>;
    sessionToken?: string | null;
  } | null;
};

export type HomePageScreen_RemoteChargeFragment = {
  __typename?: "RemoteCharge";
  id: string;
  station: { __typename?: "Station"; id: string; name: string; nextClosing?: string | null };
  status: RemoteChargeStatus;
};

export type HomePageScreen_AccountUserFragment = {
  __typename?: "AccountUser";
  currentRemoteCharge?: {
    __typename?: "RemoteCharge";
    id: string;
    station: { __typename?: "Station"; id: string; name: string; nextClosing?: string | null };
    status: RemoteChargeStatus;
  } | null;
  id: string;
};

export type HomePageQueryVariables = Exact<{
  accountId: Scalars["ID"]["input"];
}>;

export type HomePageQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    account?: {
      __typename?: "Account";
      accountUser?: {
        __typename?: "AccountUser";
        currentRemoteCharge?: {
          __typename?: "RemoteCharge";
          id: string;
          station: { __typename?: "Station"; id: string; name: string; nextClosing?: string | null };
          status: RemoteChargeStatus;
        } | null;
        id: string;
      } | null;
      id: string;
    } | null;
    id: string;
  } | null;
};

export type InvoicePage_ChargeFragment = {
  __typename?: "Charge";
  currency: Currency;
  date: string;
  id: string;
  station: { __typename?: "Station"; name: string };
  totalAmountCents: number;
  totalEnergy: number;
};

export type RetrieveBusinessChargesMutationVariables = Exact<{
  input: RetrieveBusinessChargesInput;
}>;

export type RetrieveBusinessChargesMutation = {
  __typename?: "Mutation";
  retrieveBusinessCharges?: {
    __typename?: "RetrieveBusinessChargesPayload";
    charges?: Array<{
      __typename?: "Charge";
      currency: Currency;
      date: string;
      id: string;
      station: { __typename?: "Station"; name: string };
      totalAmountCents: number;
      totalEnergy: number;
    }> | null;
    errors?: Array<string> | null;
  } | null;
};

export type SendInvoiceMutationVariables = Exact<{
  input: SendInvoiceInput;
}>;

export type SendInvoiceMutation = {
  __typename?: "Mutation";
  sendInvoice?: { __typename?: "SendInvoicePayload"; errors?: Array<string> | null } | null;
};

export type PaymentMethodPage_PaymentMethodFragment = {
  __typename?: "PaymentMethod";
  cardLast4Digits: string;
  cardScheme: string;
  id: string;
  processoutTokenRef: string;
};

export type PaymentMethodPage_AccountUserFragment = {
  __typename?: "AccountUser";
  processoutCustomerRef: string;
  processoutTokenToVerify?: string | null;
};

export type PaymentMethodPage_AnonymousSessionFragment = {
  __typename?: "AnonymousSession";
  account?: {
    __typename?: "Account";
    accountUser?: {
      __typename?: "AccountUser";
      id: string;
      processoutCustomerRef: string;
      processoutTokenToVerify?: string | null;
    } | null;
    id: string;
  } | null;
  id: string;
  instantChargeIntent?: {
    __typename?: "InstantChargeIntent";
    connector: { __typename?: "Connector"; chargePoint: { __typename?: "ChargePoint"; publicCode: string } };
    id: string;
  } | null;
  paymentMethod?: {
    __typename?: "PaymentMethod";
    cardLast4Digits: string;
    cardScheme: string;
    id: string;
    processoutTokenRef: string;
  } | null;
};

export type CreatePaymentMethodMutationVariables = Exact<{
  input: CreatePaymentMethodInput;
}>;

export type CreatePaymentMethodMutation = {
  __typename?: "Mutation";
  createPaymentMethod?: {
    __typename?: "CreatePaymentMethodPayload";
    errors: Array<string>;
    paymentMethod?: {
      __typename?: "PaymentMethod";
      cardLast4Digits: string;
      cardScheme: string;
      id: string;
      processoutTokenRef: string;
    } | null;
  } | null;
};

export type ConfirmInstantChargeIntentMutationVariables = Exact<{
  input: ConfirmInstantChargeIntentInput;
}>;

export type ConfirmInstantChargeIntentMutation = {
  __typename?: "Mutation";
  confirmInstantChargeIntent?: {
    __typename?: "ConfirmInstantChargeIntentPayload";
    errors: Array<string>;
    remoteCharge?: {
      __typename?: "RemoteCharge";
      cards: Array<{
        __typename?: "Card";
        action: CardActionEnum;
        actionLabel?: string | null;
        deeplink?: string | null;
        overtitle?: string | null;
        picture?: CardPictureEnum | null;
        text?: string | null;
        theme: CardThemeEnum;
        title?: string | null;
      }>;
      charge?: { __typename?: "Charge"; id: string } | null;
      connector: { __typename?: "Connector"; plug: ConnectorPlug };
      id: string;
      step:
        | {
            __typename: "RemoteChargeActionStep";
            actionConfirmMessage?: string | null;
            actionHeaderMessage?: string | null;
            actionKey?: string | null;
            actionLabel?: string | null;
            actionType?: RemoteChargeActionType | null;
            message: string;
            subTitle?: string | null;
            title: string;
          }
        | {
            __typename: "RemoteChargeChargingStep";
            actionConfirmMessage?: string | null;
            actionKey?: string | null;
            actionLabel?: string | null;
            canShowPricing: boolean;
            currency: Currency;
            currentPower?: number | null;
            currentPriceCents?: number | null;
            currentSoc?: number | null;
            energyDelivered: number;
            estimatedDurationLabel?: string | null;
            estimatedRemainingDurationComputing: boolean;
            estimatedRemainingDurationLabel?: string | null;
            targetSoc: number;
          }
        | {
            __typename: "RemoteChargeCompletedStep";
            canShowPricing: boolean;
            currency: Currency;
            details: Array<{ __typename?: "RemoteChargeCompletedStepDetail"; label: string; value: string }>;
            energyDelivered: number;
            message: string;
            priceCents: number;
            priceLabel?: string | null;
            title: string;
            totalDurationInSeconds: number;
          }
        | { __typename: "RemoteChargeWaitStep"; message: string; title: string };
    } | null;
  } | null;
};

export type InquireInstantChargePaymentIntentMutationVariables = Exact<{
  input: InquireInstantChargePaymentIntentInput;
}>;

export type InquireInstantChargePaymentIntentMutation = {
  __typename?: "Mutation";
  inquireInstantChargePaymentIntent?: {
    __typename?: "InquireInstantChargePaymentIntentPayload";
    errors: Array<string>;
    needed?: boolean | null;
    paymentIntent?: {
      __typename?: "PaymentIntent";
      authorizationAmountCents: number;
      authorizationStatus: string;
      currency: Currency;
      id: string;
      processoutInvoiceRef?: string | null;
      processoutMitEnabled: boolean;
    } | null;
  } | null;
};

export type PaymentMethodPageQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodPageQuery = {
  __typename?: "Query";
  anonymousSession?: {
    __typename?: "AnonymousSession";
    account?: {
      __typename?: "Account";
      accountUser?: {
        __typename?: "AccountUser";
        id: string;
        processoutCustomerRef: string;
        processoutTokenToVerify?: string | null;
      } | null;
      id: string;
    } | null;
    id: string;
    instantChargeIntent?: {
      __typename?: "InstantChargeIntent";
      connector: { __typename?: "Connector"; chargePoint: { __typename?: "ChargePoint"; publicCode: string } };
      id: string;
    } | null;
    paymentMethod?: {
      __typename?: "PaymentMethod";
      cardLast4Digits: string;
      cardScheme: string;
      id: string;
      processoutTokenRef: string;
    } | null;
  } | null;
};

export type CardFragment = {
  __typename?: "Card";
  action: CardActionEnum;
  actionLabel?: string | null;
  deeplink?: string | null;
  overtitle?: string | null;
  picture?: CardPictureEnum | null;
  text?: string | null;
  theme: CardThemeEnum;
  title?: string | null;
};

export type RemoteChargePage_RemoteChargeActionStepFragment = {
  __typename: "RemoteChargeActionStep";
  actionConfirmMessage?: string | null;
  actionHeaderMessage?: string | null;
  actionKey?: string | null;
  actionLabel?: string | null;
  actionType?: RemoteChargeActionType | null;
  message: string;
  subTitle?: string | null;
  title: string;
};

export type RemoteChargePage_RemoteChargeChargingStepFragment = {
  __typename: "RemoteChargeChargingStep";
  actionConfirmMessage?: string | null;
  actionKey?: string | null;
  actionLabel?: string | null;
  canShowPricing: boolean;
  currency: Currency;
  currentPower?: number | null;
  currentPriceCents?: number | null;
  currentSoc?: number | null;
  energyDelivered: number;
  estimatedDurationLabel?: string | null;
  estimatedRemainingDurationComputing: boolean;
  estimatedRemainingDurationLabel?: string | null;
  targetSoc: number;
};

export type RemoteChargePage_RemoteChargeCompletedStepFragment = {
  __typename: "RemoteChargeCompletedStep";
  canShowPricing: boolean;
  currency: Currency;
  details: Array<{ __typename?: "RemoteChargeCompletedStepDetail"; label: string; value: string }>;
  energyDelivered: number;
  message: string;
  priceCents: number;
  priceLabel?: string | null;
  title: string;
  totalDurationInSeconds: number;
};

export type RemoteChargePage_RemoteChargeWaitStepFragment = {
  __typename: "RemoteChargeWaitStep";
  message: string;
  title: string;
};

export type RemoteChargePage_InvoiceFragment = {
  __typename?: "Invoice";
  fileUrl?: string | null;
  id: string;
  status: InvoiceStatusEnum;
};

export type RemoteChargePage_RemoteChargeFragment = {
  __typename?: "RemoteCharge";
  cards: Array<{
    __typename?: "Card";
    action: CardActionEnum;
    actionLabel?: string | null;
    deeplink?: string | null;
    overtitle?: string | null;
    picture?: CardPictureEnum | null;
    text?: string | null;
    theme: CardThemeEnum;
    title?: string | null;
  }>;
  charge?: { __typename?: "Charge"; id: string } | null;
  connector: { __typename?: "Connector"; plug: ConnectorPlug };
  id: string;
  step:
    | {
        __typename: "RemoteChargeActionStep";
        actionConfirmMessage?: string | null;
        actionHeaderMessage?: string | null;
        actionKey?: string | null;
        actionLabel?: string | null;
        actionType?: RemoteChargeActionType | null;
        message: string;
        subTitle?: string | null;
        title: string;
      }
    | {
        __typename: "RemoteChargeChargingStep";
        actionConfirmMessage?: string | null;
        actionKey?: string | null;
        actionLabel?: string | null;
        canShowPricing: boolean;
        currency: Currency;
        currentPower?: number | null;
        currentPriceCents?: number | null;
        currentSoc?: number | null;
        energyDelivered: number;
        estimatedDurationLabel?: string | null;
        estimatedRemainingDurationComputing: boolean;
        estimatedRemainingDurationLabel?: string | null;
        targetSoc: number;
      }
    | {
        __typename: "RemoteChargeCompletedStep";
        canShowPricing: boolean;
        currency: Currency;
        details: Array<{ __typename?: "RemoteChargeCompletedStepDetail"; label: string; value: string }>;
        energyDelivered: number;
        message: string;
        priceCents: number;
        priceLabel?: string | null;
        title: string;
        totalDurationInSeconds: number;
      }
    | { __typename: "RemoteChargeWaitStep"; message: string; title: string };
};

export type CompletedScreen_AnonymousSessionFragment = {
  __typename?: "AnonymousSession";
  account?: {
    __typename?: "Account";
    accountUser?: {
      __typename?: "AccountUser";
      charge?: {
        __typename?: "Charge";
        id: string;
        invoice?: { __typename?: "Invoice"; fileUrl?: string | null; id: string; status: InvoiceStatusEnum } | null;
      } | null;
      id: string;
    } | null;
    id: string;
  } | null;
  id: string;
};

export type RemoteChargePageActionMutationVariables = Exact<{
  input: ReportRemoteChargeActionInput;
}>;

export type RemoteChargePageActionMutation = {
  __typename?: "Mutation";
  reportRemoteChargeAction?: {
    __typename?: "ReportRemoteChargeActionPayload";
    errors: Array<string>;
    remoteCharge?: {
      __typename?: "RemoteCharge";
      cards: Array<{
        __typename?: "Card";
        action: CardActionEnum;
        actionLabel?: string | null;
        deeplink?: string | null;
        overtitle?: string | null;
        picture?: CardPictureEnum | null;
        text?: string | null;
        theme: CardThemeEnum;
        title?: string | null;
      }>;
      charge?: { __typename?: "Charge"; id: string } | null;
      connector: { __typename?: "Connector"; plug: ConnectorPlug };
      id: string;
      step:
        | {
            __typename: "RemoteChargeActionStep";
            actionConfirmMessage?: string | null;
            actionHeaderMessage?: string | null;
            actionKey?: string | null;
            actionLabel?: string | null;
            actionType?: RemoteChargeActionType | null;
            message: string;
            subTitle?: string | null;
            title: string;
          }
        | {
            __typename: "RemoteChargeChargingStep";
            actionConfirmMessage?: string | null;
            actionKey?: string | null;
            actionLabel?: string | null;
            canShowPricing: boolean;
            currency: Currency;
            currentPower?: number | null;
            currentPriceCents?: number | null;
            currentSoc?: number | null;
            energyDelivered: number;
            estimatedDurationLabel?: string | null;
            estimatedRemainingDurationComputing: boolean;
            estimatedRemainingDurationLabel?: string | null;
            targetSoc: number;
          }
        | {
            __typename: "RemoteChargeCompletedStep";
            canShowPricing: boolean;
            currency: Currency;
            details: Array<{ __typename?: "RemoteChargeCompletedStepDetail"; label: string; value: string }>;
            energyDelivered: number;
            message: string;
            priceCents: number;
            priceLabel?: string | null;
            title: string;
            totalDurationInSeconds: number;
          }
        | { __typename: "RemoteChargeWaitStep"; message: string; title: string };
    } | null;
  } | null;
};

export type ChangeRemoteChargeObjectiveMutationVariables = Exact<{
  input: ChangeRemoteChargeObjectiveInput;
}>;

export type ChangeRemoteChargeObjectiveMutation = {
  __typename?: "Mutation";
  changeRemoteChargeObjective?: {
    __typename?: "ChangeRemoteChargeObjectivePayload";
    errors: Array<string>;
    remoteCharge?: {
      __typename?: "RemoteCharge";
      cards: Array<{
        __typename?: "Card";
        action: CardActionEnum;
        actionLabel?: string | null;
        deeplink?: string | null;
        overtitle?: string | null;
        picture?: CardPictureEnum | null;
        text?: string | null;
        theme: CardThemeEnum;
        title?: string | null;
      }>;
      charge?: { __typename?: "Charge"; id: string } | null;
      connector: { __typename?: "Connector"; plug: ConnectorPlug };
      id: string;
      step:
        | {
            __typename: "RemoteChargeActionStep";
            actionConfirmMessage?: string | null;
            actionHeaderMessage?: string | null;
            actionKey?: string | null;
            actionLabel?: string | null;
            actionType?: RemoteChargeActionType | null;
            message: string;
            subTitle?: string | null;
            title: string;
          }
        | {
            __typename: "RemoteChargeChargingStep";
            actionConfirmMessage?: string | null;
            actionKey?: string | null;
            actionLabel?: string | null;
            canShowPricing: boolean;
            currency: Currency;
            currentPower?: number | null;
            currentPriceCents?: number | null;
            currentSoc?: number | null;
            energyDelivered: number;
            estimatedDurationLabel?: string | null;
            estimatedRemainingDurationComputing: boolean;
            estimatedRemainingDurationLabel?: string | null;
            targetSoc: number;
          }
        | {
            __typename: "RemoteChargeCompletedStep";
            canShowPricing: boolean;
            currency: Currency;
            details: Array<{ __typename?: "RemoteChargeCompletedStepDetail"; label: string; value: string }>;
            energyDelivered: number;
            message: string;
            priceCents: number;
            priceLabel?: string | null;
            title: string;
            totalDurationInSeconds: number;
          }
        | { __typename: "RemoteChargeWaitStep"; message: string; title: string };
    } | null;
  } | null;
};

export type RemoteChargePageQueryVariables = Exact<{ [key: string]: never }>;

export type RemoteChargePageQuery = {
  __typename?: "Query";
  anonymousSession?: {
    __typename?: "AnonymousSession";
    currentRemoteCharge?: {
      __typename?: "RemoteCharge";
      cards: Array<{
        __typename?: "Card";
        action: CardActionEnum;
        actionLabel?: string | null;
        deeplink?: string | null;
        overtitle?: string | null;
        picture?: CardPictureEnum | null;
        text?: string | null;
        theme: CardThemeEnum;
        title?: string | null;
      }>;
      charge?: { __typename?: "Charge"; id: string } | null;
      connector: { __typename?: "Connector"; plug: ConnectorPlug };
      id: string;
      step:
        | {
            __typename: "RemoteChargeActionStep";
            actionConfirmMessage?: string | null;
            actionHeaderMessage?: string | null;
            actionKey?: string | null;
            actionLabel?: string | null;
            actionType?: RemoteChargeActionType | null;
            message: string;
            subTitle?: string | null;
            title: string;
          }
        | {
            __typename: "RemoteChargeChargingStep";
            actionConfirmMessage?: string | null;
            actionKey?: string | null;
            actionLabel?: string | null;
            canShowPricing: boolean;
            currency: Currency;
            currentPower?: number | null;
            currentPriceCents?: number | null;
            currentSoc?: number | null;
            energyDelivered: number;
            estimatedDurationLabel?: string | null;
            estimatedRemainingDurationComputing: boolean;
            estimatedRemainingDurationLabel?: string | null;
            targetSoc: number;
          }
        | {
            __typename: "RemoteChargeCompletedStep";
            canShowPricing: boolean;
            currency: Currency;
            details: Array<{ __typename?: "RemoteChargeCompletedStepDetail"; label: string; value: string }>;
            energyDelivered: number;
            message: string;
            priceCents: number;
            priceLabel?: string | null;
            title: string;
            totalDurationInSeconds: number;
          }
        | { __typename: "RemoteChargeWaitStep"; message: string; title: string };
    } | null;
    id: string;
  } | null;
};

export type CompletedScreenQueryVariables = Exact<{
  chargeId: Scalars["ID"]["input"];
}>;

export type CompletedScreenQuery = {
  __typename?: "Query";
  anonymousSession?: {
    __typename?: "AnonymousSession";
    account?: {
      __typename?: "Account";
      accountUser?: {
        __typename?: "AccountUser";
        charge?: {
          __typename?: "Charge";
          id: string;
          invoice?: { __typename?: "Invoice"; fileUrl?: string | null; id: string; status: InvoiceStatusEnum } | null;
        } | null;
        id: string;
      } | null;
      id: string;
    } | null;
    id: string;
  } | null;
};

export type RemoteChargePageChangedSubscriptionVariables = Exact<{
  remoteChargeId: Scalars["ID"]["input"];
}>;

export type RemoteChargePageChangedSubscription = {
  __typename?: "Subscription";
  remoteChargeChanged: {
    __typename?: "RemoteChargeChangedSubscriptionPayload";
    remoteCharge: {
      __typename?: "RemoteCharge";
      cards: Array<{
        __typename?: "Card";
        action: CardActionEnum;
        actionLabel?: string | null;
        deeplink?: string | null;
        overtitle?: string | null;
        picture?: CardPictureEnum | null;
        text?: string | null;
        theme: CardThemeEnum;
        title?: string | null;
      }>;
      charge?: { __typename?: "Charge"; id: string } | null;
      connector: { __typename?: "Connector"; plug: ConnectorPlug };
      id: string;
      step:
        | {
            __typename: "RemoteChargeActionStep";
            actionConfirmMessage?: string | null;
            actionHeaderMessage?: string | null;
            actionKey?: string | null;
            actionLabel?: string | null;
            actionType?: RemoteChargeActionType | null;
            message: string;
            subTitle?: string | null;
            title: string;
          }
        | {
            __typename: "RemoteChargeChargingStep";
            actionConfirmMessage?: string | null;
            actionKey?: string | null;
            actionLabel?: string | null;
            canShowPricing: boolean;
            currency: Currency;
            currentPower?: number | null;
            currentPriceCents?: number | null;
            currentSoc?: number | null;
            energyDelivered: number;
            estimatedDurationLabel?: string | null;
            estimatedRemainingDurationComputing: boolean;
            estimatedRemainingDurationLabel?: string | null;
            targetSoc: number;
          }
        | {
            __typename: "RemoteChargeCompletedStep";
            canShowPricing: boolean;
            currency: Currency;
            details: Array<{ __typename?: "RemoteChargeCompletedStepDetail"; label: string; value: string }>;
            energyDelivered: number;
            message: string;
            priceCents: number;
            priceLabel?: string | null;
            title: string;
            totalDurationInSeconds: number;
          }
        | { __typename: "RemoteChargeWaitStep"; message: string; title: string };
    };
  };
};

export type UnsubscribeEmailMutationVariables = Exact<{
  input: UnsubscribeInput;
}>;

export type UnsubscribeEmailMutation = {
  __typename?: "Mutation";
  unsubscribe?: { __typename?: "UnsubscribePayload"; email?: string | null; errors: Array<string> } | null;
};

export type ReferralRewardFragment = {
  __typename?: "ReferralProgramReward";
  action?: string | null;
  expiresSoon: boolean;
  id: string;
  label: string;
  subLabel: string;
};

export type ReferralProgramFragment = {
  __typename?: "ReferralProgram";
  availableRewardsCount: number;
  id: string;
  promotionAmount: number;
  promotionCode: string;
  promotionCurrency: Currency;
};

export type ReferralProgramQueryVariables = Exact<{
  accountId: Scalars["ID"]["input"];
}>;

export type ReferralProgramQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    account?: {
      __typename?: "Account";
      accountUser?: {
        __typename?: "AccountUser";
        id: string;
        referralProgram?: {
          __typename?: "ReferralProgram";
          availableRewardsCount: number;
          id: string;
          promotionAmount: number;
          promotionCode: string;
          promotionCurrency: Currency;
        } | null;
        referralRewards: Array<{
          __typename?: "ReferralProgramReward";
          action?: string | null;
          expiresSoon: boolean;
          id: string;
          label: string;
          subLabel: string;
        }>;
      } | null;
      id: string;
      pro: boolean;
    } | null;
    id: string;
    onboardingStatus?: { __typename?: "AccountOnboardingStatus"; proType?: ProAccountType | null } | null;
  } | null;
};

export type RecapMetricFragment = {
  __typename?: "AccountUserMetric";
  networkShare: number;
  preferredStations: Array<{ __typename?: "AccountUserMetricStation"; chargesCount: number; name: string }>;
  topTierAccountUsersPercentage: number;
  totalChargesCount: number;
  totalDistance: number;
  totalEnergy: number;
  year: number;
};

export type RecapMetricPreferredStationFragment = {
  __typename?: "AccountUserMetricStation";
  chargesCount: number;
  name: string;
};

export type RecapMetricsQueryVariables = Exact<{
  accountId: Scalars["ID"]["input"];
}>;

export type RecapMetricsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    account?: {
      __typename?: "Account";
      accountUser?: {
        __typename?: "AccountUser";
        firstname: string;
        id: string;
        recapMetrics?: {
          __typename?: "AccountUserMetric";
          id: string;
          networkShare: number;
          preferredStations: Array<{ __typename?: "AccountUserMetricStation"; chargesCount: number; name: string }>;
          topTierAccountUsersPercentage: number;
          totalChargesCount: number;
          totalDistance: number;
          totalEnergy: number;
          year: number;
        } | null;
      } | null;
      id: string;
    } | null;
    id: string;
  } | null;
};

export const AccountSelector_AccountFragmentDoc = gql`
  fragment AccountSelector_AccountFragment on Account {
    id
    label
  }
`;
export const AccountSelectorBottomSheet_AccountFragmentDoc = gql`
  fragment AccountSelectorBottomSheet_AccountFragment on Account {
    id
    label
    pro
  }
`;
export const StationStatusBadge_StationFragmentDoc = gql`
  fragment StationStatusBadge_StationFragment on Station {
    availableSpotsCount @client
    comingSoonLabel
    id
    publicStatus @client
    spots {
      id
      publicAvailability
    }
    spotsCount
  }
`;
export const StationSummary_StationFragmentDoc = gql`
  fragment StationSummary_StationFragment on Station {
    id
    publicAvailablePower
    parkingTypeLabel
  }
`;
export const StationsPageStationSheet_StationFragmentDoc = gql`
  fragment StationsPageStationSheet_StationFragment on Station {
    address
    fastSpeedPricingDetail(accountId: $accountId) {
      chargeType
      currency
      maxPower
      priceCents
    }
    latitude
    longitude
    name
    nextClosing
    nextOpening
    status
    ...StationStatusBadge_StationFragment
    ...StationSummary_StationFragment
  }
  ${StationStatusBadge_StationFragmentDoc}
  ${StationSummary_StationFragmentDoc}
`;
export const Drawer_AccountUserFragmentDoc = gql`
  fragment Drawer_AccountUserFragment on AccountUser {
    id
    firstname
  }
`;
export const OnboardingStatusFragmentDoc = gql`
  fragment OnboardingStatusFragment on AccountOnboardingStatus {
    status
    missingInfos
    proType
    proTypeLabel @client
    country {
      key
    }
  }
`;
export const MembersFragmentDoc = gql`
  fragment MembersFragment on Account {
    members: accountUsers(first: 1, role: MEMBER) {
      nodes {
        id
      }
    }
    invitations(first: 1) {
      nodes {
        id
      }
    }
  }
`;
export const AccountUserFragmentDoc = gql`
  fragment AccountUserFragment on AccountUser {
    id
    canEdit
    canEditRole
    canListUsers
    canShowDashboard
    firstname
    role
    bulkInvitationFileUrl
  }
`;
export const AccountFragmentDoc = gql`
  fragment AccountFragment on Account {
    id
    ...MembersFragment
    accountUser {
      id
      ...AccountUserFragment
    }
    label
    pro
  }
  ${MembersFragmentDoc}
  ${AccountUserFragmentDoc}
`;
export const LayoutFragmentDoc = gql`
  fragment LayoutFragment on User {
    id
    phoneNumber
    onboardingStatus {
      ...OnboardingStatusFragment
    }
    accounts(first: 50) {
      nodes {
        ...AccountFragment
      }
    }
  }
  ${OnboardingStatusFragmentDoc}
  ${AccountFragmentDoc}
`;
export const ChargesPage_ChargeFragmentDoc = gql`
  fragment ChargesPage_ChargeFragment on Charge {
    id
    canShowPricing
    car {
      id
      friendlyIdentifier
      friendlyName
    }
    source
    currency
    date
    driverFullname
    station {
      id
      name
    }
    driverPhoneNumber
    accountTagLabel
    totalAmountCents
    totalEnergy
    totalDuration
    invoice {
      id
      status
      fileUrl
    }
    startSoc
    endSoc
  }
`;
export const ChargesPage_PageInfoFragmentDoc = gql`
  fragment ChargesPage_PageInfoFragment on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;
export const InvitationPage_InvitationFragmentDoc = gql`
  fragment InvitationPage_InvitationFragment on Invitation {
    id
    confirmedAt
    createdBy {
      id
      fullname
    }
    firstname
    fullname
    lastname
    phoneNumber
    status
    canReinvite
    reinvitationErrorMessage
  }
`;
export const CompanyScreen_CountryFragmentDoc = gql`
  fragment CompanyScreen_CountryFragment on CountryObject {
    name
    key
  }
`;
export const CompanyScreen_DocumentFragmentDoc = gql`
  fragment CompanyScreen_DocumentFragment on BusinessDocument {
    label
    sublabel
    documentType
  }
`;
export const SettingsPageScreen_AccountFragmentDoc = gql`
  fragment SettingsPageScreen_AccountFragment on Account {
    id
    accountUser {
      id
      canDelete
    }
  }
`;
export const SupportPageScreen_SupportSectionFragmentDoc = gql`
  fragment SupportPageScreen_SupportSectionFragment on SupportSection {
    label
    children {
      ... on SupportMethod {
        __typename
        deeplink
        label
        subLabel
        type
      }
      ... on SupportSection {
        __typename
      }
    }
  }
`;
export const UserDeletionIntentPage_UserDeletionIntentFragmentDoc = gql`
  fragment UserDeletionIntentPage_UserDeletionIntentFragment on UserDeletionIntent {
    id
    message
  }
`;
export const UserPageScreen_AccountUserFragmentDoc = gql`
  fragment UserPageScreen_AccountUserFragment on AccountUser {
    id
    active
    canEdit
    canEditRole
    firstname
    fullname
    invitedBy {
      id
      fullname
    }
    joinedAt
    lastname
    role
    user {
      id
      phoneNumber
    }
  }
`;
export const InvitationNewPage_InvitationFragmentDoc = gql`
  fragment InvitationNewPage_InvitationFragment on Invitation {
    id
    firstname
    lastname
    phoneNumber
  }
`;
export const UsersPageScreen_AccountUserFragmentDoc = gql`
  fragment UsersPageScreen_AccountUserFragment on AccountUser {
    id
    active
    fullname
    role
    user {
      id
    }
  }
`;
export const UsersPageScreen_InvitationFragmentDoc = gql`
  fragment UsersPageScreen_InvitationFragment on Invitation {
    id
    fullname
    refusedAt
    canReinvite
  }
`;
export const PhoneNumberScreen_CountryObjectFragmentDoc = gql`
  fragment PhoneNumberScreen_CountryObjectFragment on CountryObject {
    id
    isHighlighted
    key
    name
    callingCode
    selfServeAvailable
  }
`;
export const PaymentIntentAuthorizeBottomSheet_PaymentIntentFragmentDoc = gql`
  fragment PaymentIntentAuthorizeBottomSheet_PaymentIntentFragment on PaymentIntent {
    authorizationStatus
    authorizationAmountCents
    currency
    id
    processoutInvoiceRef
    processoutMitEnabled
  }
`;
export const AnonymousAccessPage_StationAccessFragmentDoc = gql`
  fragment AnonymousAccessPage_StationAccessFragment on StationAccess {
    stationName
    stationCountryCode
  }
`;
export const AnonymousAccessPage_CountryObjectFragmentDoc = gql`
  fragment AnonymousAccessPage_CountryObjectFragment on CountryObject {
    id
    key
    name
    callingCode
  }
`;
export const ChargePointPage_ConnectorFragmentDoc = gql`
  fragment ChargePointPage_ConnectorFragment on Connector {
    id
    plugShortName
    availability(forAnonymousSession: $forAnonymousSession)
    anonymouslyOccupied(forAnonymousSession: $forAnonymousSession)
    chargeType
    number
    plug
    maxPowerWatts
    spot {
      id
      number
      publicLabel
      reducedMobility
    }
    pricingDetail
    readyToCharge
  }
`;
export const ChargePointPage_ChargePointFragmentDoc = gql`
  fragment ChargePointPage_ChargePointFragment on ChargePoint {
    station {
      id
      name
      status
    }
    connectors {
      ...ChargePointPage_ConnectorFragment
    }
  }
  ${ChargePointPage_ConnectorFragmentDoc}
`;
export const CurrentRemoteChargeFragmentDoc = gql`
  fragment CurrentRemoteChargeFragment on AnonymousSession {
    id
    currentRemoteCharge {
      id
    }
  }
`;
export const HomePageScreen_RemoteChargeFragmentDoc = gql`
  fragment HomePageScreen_RemoteChargeFragment on RemoteCharge {
    id
    station {
      id
      name
      nextClosing
    }
    status
  }
`;
export const HomePageScreen_AccountUserFragmentDoc = gql`
  fragment HomePageScreen_AccountUserFragment on AccountUser {
    id
    currentRemoteCharge {
      ...HomePageScreen_RemoteChargeFragment
    }
  }
  ${HomePageScreen_RemoteChargeFragmentDoc}
`;
export const InvoicePage_ChargeFragmentDoc = gql`
  fragment InvoicePage_ChargeFragment on Charge {
    id
    currency
    date
    station {
      name
    }
    totalAmountCents
    totalEnergy
  }
`;
export const PaymentMethodPage_AccountUserFragmentDoc = gql`
  fragment PaymentMethodPage_AccountUserFragment on AccountUser {
    processoutCustomerRef
    processoutTokenToVerify
  }
`;
export const PaymentMethodPage_PaymentMethodFragmentDoc = gql`
  fragment PaymentMethodPage_PaymentMethodFragment on PaymentMethod {
    cardLast4Digits
    cardScheme
    id
    processoutTokenRef
  }
`;
export const PaymentMethodPage_AnonymousSessionFragmentDoc = gql`
  fragment PaymentMethodPage_AnonymousSessionFragment on AnonymousSession {
    id
    account {
      id
      accountUser {
        id
        ...PaymentMethodPage_AccountUserFragment
      }
    }
    paymentMethod {
      id
      ...PaymentMethodPage_PaymentMethodFragment
    }
    instantChargeIntent {
      id
      connector {
        chargePoint {
          publicCode
        }
      }
    }
  }
  ${PaymentMethodPage_AccountUserFragmentDoc}
  ${PaymentMethodPage_PaymentMethodFragmentDoc}
`;
export const CardFragmentDoc = gql`
  fragment CardFragment on Card {
    action
    actionLabel
    deeplink
    overtitle
    picture
    text
    theme
    title
  }
`;
export const RemoteChargePage_RemoteChargeActionStepFragmentDoc = gql`
  fragment RemoteChargePage_RemoteChargeActionStep on RemoteChargeActionStep {
    __typename
    actionConfirmMessage
    actionHeaderMessage
    actionKey
    actionLabel
    actionType
    message
    subTitle
    title
  }
`;
export const RemoteChargePage_RemoteChargeChargingStepFragmentDoc = gql`
  fragment RemoteChargePage_RemoteChargeChargingStep on RemoteChargeChargingStep {
    __typename
    actionConfirmMessage
    actionKey
    actionLabel
    currency
    currentPower
    currentPriceCents
    currentSoc
    energyDelivered
    estimatedDurationLabel
    estimatedRemainingDurationLabel
    estimatedRemainingDurationComputing
    targetSoc
    canShowPricing
  }
`;
export const RemoteChargePage_RemoteChargeCompletedStepFragmentDoc = gql`
  fragment RemoteChargePage_RemoteChargeCompletedStep on RemoteChargeCompletedStep {
    __typename
    currency
    details {
      label
      value
    }
    energyDelivered
    message
    priceCents
    priceLabel
    title
    canShowPricing
    totalDurationInSeconds
  }
`;
export const RemoteChargePage_RemoteChargeWaitStepFragmentDoc = gql`
  fragment RemoteChargePage_RemoteChargeWaitStep on RemoteChargeWaitStep {
    __typename
    message
    title
  }
`;
export const RemoteChargePage_RemoteChargeFragmentDoc = gql`
  fragment RemoteChargePage_RemoteChargeFragment on RemoteCharge {
    id
    cards {
      ...CardFragment
    }
    connector {
      plug
    }
    charge {
      id
    }
    step {
      ...RemoteChargePage_RemoteChargeActionStep
      ...RemoteChargePage_RemoteChargeChargingStep
      ...RemoteChargePage_RemoteChargeCompletedStep
      ...RemoteChargePage_RemoteChargeWaitStep
    }
  }
  ${CardFragmentDoc}
  ${RemoteChargePage_RemoteChargeActionStepFragmentDoc}
  ${RemoteChargePage_RemoteChargeChargingStepFragmentDoc}
  ${RemoteChargePage_RemoteChargeCompletedStepFragmentDoc}
  ${RemoteChargePage_RemoteChargeWaitStepFragmentDoc}
`;
export const RemoteChargePage_InvoiceFragmentDoc = gql`
  fragment RemoteChargePage_InvoiceFragment on Invoice {
    id
    fileUrl
    status
  }
`;
export const CompletedScreen_AnonymousSessionFragmentDoc = gql`
  fragment CompletedScreen_AnonymousSessionFragment on AnonymousSession {
    id
    account {
      id
      accountUser {
        id
        charge(id: $chargeId) {
          id
          invoice {
            id
            ...RemoteChargePage_InvoiceFragment
          }
        }
      }
    }
  }
  ${RemoteChargePage_InvoiceFragmentDoc}
`;
export const ReferralRewardFragmentDoc = gql`
  fragment ReferralRewardFragment on ReferralProgramReward {
    id
    label
    subLabel
    expiresSoon
    action
  }
`;
export const ReferralProgramFragmentDoc = gql`
  fragment ReferralProgramFragment on ReferralProgram {
    id
    availableRewardsCount
    promotionCode
    promotionAmount
    promotionCurrency
  }
`;
export const RecapMetricPreferredStationFragmentDoc = gql`
  fragment RecapMetricPreferredStationFragment on AccountUserMetricStation {
    name
    chargesCount
  }
`;
export const RecapMetricFragmentDoc = gql`
  fragment RecapMetricFragment on AccountUserMetric {
    totalChargesCount
    totalEnergy
    totalDistance
    networkShare
    preferredStations {
      ...RecapMetricPreferredStationFragment
    }
    topTierAccountUsersPercentage
    year
  }
  ${RecapMetricPreferredStationFragmentDoc}
`;
export const SwitchAccountDocument = gql`
  mutation SwitchAccount($input: SwitchAccountInput!) {
    switchAccount(input: $input) {
      account {
        id
      }
      accountUser {
        id
      }
      errors
    }
  }
`;
export type SwitchAccountMutationFn = Apollo.MutationFunction<SwitchAccountMutation, SwitchAccountMutationVariables>;

/**
 * __useSwitchAccountMutation__
 *
 * To run a mutation, you first call `useSwitchAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchAccountMutation, { data, loading, error }] = useSwitchAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwitchAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<SwitchAccountMutation, SwitchAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SwitchAccountMutation, SwitchAccountMutationVariables>(SwitchAccountDocument, options);
}
export type SwitchAccountMutationHookResult = ReturnType<typeof useSwitchAccountMutation>;
export type SwitchAccountMutationResult = Apollo.MutationResult<SwitchAccountMutation>;
export type SwitchAccountMutationOptions = Apollo.BaseMutationOptions<
  SwitchAccountMutation,
  SwitchAccountMutationVariables
>;
export const LayoutDocument = gql`
  query Layout {
    me {
      ...LayoutFragment
    }
  }
  ${LayoutFragmentDoc}
`;

/**
 * __useLayoutQuery__
 *
 * To run a query within a React component, call `useLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
}
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
}
export function useLayoutSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LayoutQuery, LayoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
}
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutSuspenseQueryHookResult = ReturnType<typeof useLayoutSuspenseQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const ExportProChargesDocument = gql`
  mutation ExportProCharges($input: ExportProChargesInput!) {
    exportProCharges(input: $input) {
      errors
      successMessage
    }
  }
`;
export type ExportProChargesMutationFn = Apollo.MutationFunction<
  ExportProChargesMutation,
  ExportProChargesMutationVariables
>;

/**
 * __useExportProChargesMutation__
 *
 * To run a mutation, you first call `useExportProChargesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportProChargesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportProChargesMutation, { data, loading, error }] = useExportProChargesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportProChargesMutation(
  baseOptions?: Apollo.MutationHookOptions<ExportProChargesMutation, ExportProChargesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportProChargesMutation, ExportProChargesMutationVariables>(
    ExportProChargesDocument,
    options
  );
}
export type ExportProChargesMutationHookResult = ReturnType<typeof useExportProChargesMutation>;
export type ExportProChargesMutationResult = Apollo.MutationResult<ExportProChargesMutation>;
export type ExportProChargesMutationOptions = Apollo.BaseMutationOptions<
  ExportProChargesMutation,
  ExportProChargesMutationVariables
>;
export const ChargesPageDocument = gql`
  query ChargesPage($accountId: ID!, $first: Int, $last: Int, $after: String, $before: String, $sort: ChargeSortType) {
    me {
      id
      account(id: $accountId) {
        id
        chargesCount
        charges(first: $first, last: $last, after: $after, before: $before, sort: $sort) {
          pageInfo {
            ...ChargesPage_PageInfoFragment
          }
          nodes {
            ...ChargesPage_ChargeFragment
          }
        }
      }
    }
  }
  ${ChargesPage_PageInfoFragmentDoc}
  ${ChargesPage_ChargeFragmentDoc}
`;

/**
 * __useChargesPageQuery__
 *
 * To run a query within a React component, call `useChargesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargesPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useChargesPageQuery(
  baseOptions: Apollo.QueryHookOptions<ChargesPageQuery, ChargesPageQueryVariables> &
    ({ skip?: boolean; variables: ChargesPageQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChargesPageQuery, ChargesPageQueryVariables>(ChargesPageDocument, options);
}
export function useChargesPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChargesPageQuery, ChargesPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChargesPageQuery, ChargesPageQueryVariables>(ChargesPageDocument, options);
}
export function useChargesPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ChargesPageQuery, ChargesPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ChargesPageQuery, ChargesPageQueryVariables>(ChargesPageDocument, options);
}
export type ChargesPageQueryHookResult = ReturnType<typeof useChargesPageQuery>;
export type ChargesPageLazyQueryHookResult = ReturnType<typeof useChargesPageLazyQuery>;
export type ChargesPageSuspenseQueryHookResult = ReturnType<typeof useChargesPageSuspenseQuery>;
export type ChargesPageQueryResult = Apollo.QueryResult<ChargesPageQuery, ChargesPageQueryVariables>;
export const DashboardPageDocument = gql`
  query DashboardPage($accountId: ID!) {
    me {
      account(id: $accountId) {
        dashboardIframeUrl
      }
    }
  }
`;

/**
 * __useDashboardPageQuery__
 *
 * To run a query within a React component, call `useDashboardPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDashboardPageQuery(
  baseOptions: Apollo.QueryHookOptions<DashboardPageQuery, DashboardPageQueryVariables> &
    ({ skip?: boolean; variables: DashboardPageQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardPageQuery, DashboardPageQueryVariables>(DashboardPageDocument, options);
}
export function useDashboardPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DashboardPageQuery, DashboardPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardPageQuery, DashboardPageQueryVariables>(DashboardPageDocument, options);
}
export function useDashboardPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardPageQuery, DashboardPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DashboardPageQuery, DashboardPageQueryVariables>(DashboardPageDocument, options);
}
export type DashboardPageQueryHookResult = ReturnType<typeof useDashboardPageQuery>;
export type DashboardPageLazyQueryHookResult = ReturnType<typeof useDashboardPageLazyQuery>;
export type DashboardPageSuspenseQueryHookResult = ReturnType<typeof useDashboardPageSuspenseQuery>;
export type DashboardPageQueryResult = Apollo.QueryResult<DashboardPageQuery, DashboardPageQueryVariables>;
export const SessionlessConfirmEmailDocument = gql`
  mutation SessionlessConfirmEmail($input: SessionlessConfirmEmailInput!) {
    sessionlessConfirmEmail(input: $input) {
      success
      errors
    }
  }
`;
export type SessionlessConfirmEmailMutationFn = Apollo.MutationFunction<
  SessionlessConfirmEmailMutation,
  SessionlessConfirmEmailMutationVariables
>;

/**
 * __useSessionlessConfirmEmailMutation__
 *
 * To run a mutation, you first call `useSessionlessConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSessionlessConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sessionlessConfirmEmailMutation, { data, loading, error }] = useSessionlessConfirmEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSessionlessConfirmEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SessionlessConfirmEmailMutation, SessionlessConfirmEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SessionlessConfirmEmailMutation, SessionlessConfirmEmailMutationVariables>(
    SessionlessConfirmEmailDocument,
    options
  );
}
export type SessionlessConfirmEmailMutationHookResult = ReturnType<typeof useSessionlessConfirmEmailMutation>;
export type SessionlessConfirmEmailMutationResult = Apollo.MutationResult<SessionlessConfirmEmailMutation>;
export type SessionlessConfirmEmailMutationOptions = Apollo.BaseMutationOptions<
  SessionlessConfirmEmailMutation,
  SessionlessConfirmEmailMutationVariables
>;
export const ReinviteUserDocument = gql`
  mutation ReinviteUser($input: ReinviteUserInput!) {
    reinviteUser(input: $input) {
      invitation {
        id
      }
      errors
      successMessage
      successTitle
    }
  }
`;
export type ReinviteUserMutationFn = Apollo.MutationFunction<ReinviteUserMutation, ReinviteUserMutationVariables>;

/**
 * __useReinviteUserMutation__
 *
 * To run a mutation, you first call `useReinviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReinviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reinviteUserMutation, { data, loading, error }] = useReinviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReinviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<ReinviteUserMutation, ReinviteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReinviteUserMutation, ReinviteUserMutationVariables>(ReinviteUserDocument, options);
}
export type ReinviteUserMutationHookResult = ReturnType<typeof useReinviteUserMutation>;
export type ReinviteUserMutationResult = Apollo.MutationResult<ReinviteUserMutation>;
export type ReinviteUserMutationOptions = Apollo.BaseMutationOptions<
  ReinviteUserMutation,
  ReinviteUserMutationVariables
>;
export const DeleteInvitationDocument = gql`
  mutation DeleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input) {
      errors
      successMessage
    }
  }
`;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInvitationMutation, DeleteInvitationMutationVariables>(
    DeleteInvitationDocument,
    options
  );
}
export type DeleteInvitationMutationHookResult = ReturnType<typeof useDeleteInvitationMutation>;
export type DeleteInvitationMutationResult = Apollo.MutationResult<DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;
export const InvitationPageDocument = gql`
  query InvitationPage($accountId: ID!, $invitationId: ID!) {
    me {
      id
      account(id: $accountId) {
        id
        invitation(id: $invitationId) {
          ...InvitationPage_InvitationFragment
        }
      }
    }
  }
  ${InvitationPage_InvitationFragmentDoc}
`;

/**
 * __useInvitationPageQuery__
 *
 * To run a query within a React component, call `useInvitationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useInvitationPageQuery(
  baseOptions: Apollo.QueryHookOptions<InvitationPageQuery, InvitationPageQueryVariables> &
    ({ skip?: boolean; variables: InvitationPageQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvitationPageQuery, InvitationPageQueryVariables>(InvitationPageDocument, options);
}
export function useInvitationPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvitationPageQuery, InvitationPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvitationPageQuery, InvitationPageQueryVariables>(InvitationPageDocument, options);
}
export function useInvitationPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InvitationPageQuery, InvitationPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvitationPageQuery, InvitationPageQueryVariables>(InvitationPageDocument, options);
}
export type InvitationPageQueryHookResult = ReturnType<typeof useInvitationPageQuery>;
export type InvitationPageLazyQueryHookResult = ReturnType<typeof useInvitationPageLazyQuery>;
export type InvitationPageSuspenseQueryHookResult = ReturnType<typeof useInvitationPageSuspenseQuery>;
export type InvitationPageQueryResult = Apollo.QueryResult<InvitationPageQuery, InvitationPageQueryVariables>;
export const RegisterPageStartPhoneChallengeDocument = gql`
  mutation RegisterPageStartPhoneChallenge($input: StartPhoneChallengeInput!) {
    startPhoneChallenge(input: $input) {
      challengeToken
      errors
    }
  }
`;
export type RegisterPageStartPhoneChallengeMutationFn = Apollo.MutationFunction<
  RegisterPageStartPhoneChallengeMutation,
  RegisterPageStartPhoneChallengeMutationVariables
>;

/**
 * __useRegisterPageStartPhoneChallengeMutation__
 *
 * To run a mutation, you first call `useRegisterPageStartPhoneChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageStartPhoneChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPageStartPhoneChallengeMutation, { data, loading, error }] = useRegisterPageStartPhoneChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPageStartPhoneChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPageStartPhoneChallengeMutation,
    RegisterPageStartPhoneChallengeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterPageStartPhoneChallengeMutation, RegisterPageStartPhoneChallengeMutationVariables>(
    RegisterPageStartPhoneChallengeDocument,
    options
  );
}
export type RegisterPageStartPhoneChallengeMutationHookResult = ReturnType<
  typeof useRegisterPageStartPhoneChallengeMutation
>;
export type RegisterPageStartPhoneChallengeMutationResult =
  Apollo.MutationResult<RegisterPageStartPhoneChallengeMutation>;
export type RegisterPageStartPhoneChallengeMutationOptions = Apollo.BaseMutationOptions<
  RegisterPageStartPhoneChallengeMutation,
  RegisterPageStartPhoneChallengeMutationVariables
>;
export const RegisterPageCompletePhoneChallengeDocument = gql`
  mutation RegisterPageCompletePhoneChallenge($input: CompletePhoneChallengeInput!) {
    completePhoneChallenge(input: $input) {
      completionToken
      errors
    }
  }
`;
export type RegisterPageCompletePhoneChallengeMutationFn = Apollo.MutationFunction<
  RegisterPageCompletePhoneChallengeMutation,
  RegisterPageCompletePhoneChallengeMutationVariables
>;

/**
 * __useRegisterPageCompletePhoneChallengeMutation__
 *
 * To run a mutation, you first call `useRegisterPageCompletePhoneChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageCompletePhoneChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPageCompletePhoneChallengeMutation, { data, loading, error }] = useRegisterPageCompletePhoneChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPageCompletePhoneChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPageCompletePhoneChallengeMutation,
    RegisterPageCompletePhoneChallengeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterPageCompletePhoneChallengeMutation,
    RegisterPageCompletePhoneChallengeMutationVariables
  >(RegisterPageCompletePhoneChallengeDocument, options);
}
export type RegisterPageCompletePhoneChallengeMutationHookResult = ReturnType<
  typeof useRegisterPageCompletePhoneChallengeMutation
>;
export type RegisterPageCompletePhoneChallengeMutationResult =
  Apollo.MutationResult<RegisterPageCompletePhoneChallengeMutation>;
export type RegisterPageCompletePhoneChallengeMutationOptions = Apollo.BaseMutationOptions<
  RegisterPageCompletePhoneChallengeMutation,
  RegisterPageCompletePhoneChallengeMutationVariables
>;
export const RegisterPageCreateProAccountDocument = gql`
  mutation RegisterPageCreateProAccount($input: CreateProAccountInput!) {
    createProAccount(input: $input) {
      account {
        id
        ...MembersFragment
      }
      onboardingStatus {
        ...OnboardingStatusFragment
      }
      sessionToken
      errors
    }
  }
  ${MembersFragmentDoc}
  ${OnboardingStatusFragmentDoc}
`;
export type RegisterPageCreateProAccountMutationFn = Apollo.MutationFunction<
  RegisterPageCreateProAccountMutation,
  RegisterPageCreateProAccountMutationVariables
>;

/**
 * __useRegisterPageCreateProAccountMutation__
 *
 * To run a mutation, you first call `useRegisterPageCreateProAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageCreateProAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPageCreateProAccountMutation, { data, loading, error }] = useRegisterPageCreateProAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPageCreateProAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPageCreateProAccountMutation,
    RegisterPageCreateProAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterPageCreateProAccountMutation, RegisterPageCreateProAccountMutationVariables>(
    RegisterPageCreateProAccountDocument,
    options
  );
}
export type RegisterPageCreateProAccountMutationHookResult = ReturnType<typeof useRegisterPageCreateProAccountMutation>;
export type RegisterPageCreateProAccountMutationResult = Apollo.MutationResult<RegisterPageCreateProAccountMutation>;
export type RegisterPageCreateProAccountMutationOptions = Apollo.BaseMutationOptions<
  RegisterPageCreateProAccountMutation,
  RegisterPageCreateProAccountMutationVariables
>;
export const RegisterPageSearchBusinessCompanyDocument = gql`
  mutation RegisterPageSearchBusinessCompany($input: SearchBusinessCompanyInput!) {
    searchBusinessCompany(input: $input) {
      address
      city
      country {
        name
        id
      }
      name
      postCode
      errorKeys
      errors
      companyIdentificationNumber
      companyIdentificationType
    }
  }
`;
export type RegisterPageSearchBusinessCompanyMutationFn = Apollo.MutationFunction<
  RegisterPageSearchBusinessCompanyMutation,
  RegisterPageSearchBusinessCompanyMutationVariables
>;

/**
 * __useRegisterPageSearchBusinessCompanyMutation__
 *
 * To run a mutation, you first call `useRegisterPageSearchBusinessCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageSearchBusinessCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPageSearchBusinessCompanyMutation, { data, loading, error }] = useRegisterPageSearchBusinessCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPageSearchBusinessCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPageSearchBusinessCompanyMutation,
    RegisterPageSearchBusinessCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterPageSearchBusinessCompanyMutation,
    RegisterPageSearchBusinessCompanyMutationVariables
  >(RegisterPageSearchBusinessCompanyDocument, options);
}
export type RegisterPageSearchBusinessCompanyMutationHookResult = ReturnType<
  typeof useRegisterPageSearchBusinessCompanyMutation
>;
export type RegisterPageSearchBusinessCompanyMutationResult =
  Apollo.MutationResult<RegisterPageSearchBusinessCompanyMutation>;
export type RegisterPageSearchBusinessCompanyMutationOptions = Apollo.BaseMutationOptions<
  RegisterPageSearchBusinessCompanyMutation,
  RegisterPageSearchBusinessCompanyMutationVariables
>;
export const RegisterPageUpdateBusinessCompanyDocument = gql`
  mutation RegisterPageUpdateBusinessCompany($input: UpdateBusinessCompanyInput!) {
    updateBusinessCompany(input: $input) {
      onboardingStatus {
        ...OnboardingStatusFragment
      }
      errors
    }
  }
  ${OnboardingStatusFragmentDoc}
`;
export type RegisterPageUpdateBusinessCompanyMutationFn = Apollo.MutationFunction<
  RegisterPageUpdateBusinessCompanyMutation,
  RegisterPageUpdateBusinessCompanyMutationVariables
>;

/**
 * __useRegisterPageUpdateBusinessCompanyMutation__
 *
 * To run a mutation, you first call `useRegisterPageUpdateBusinessCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageUpdateBusinessCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPageUpdateBusinessCompanyMutation, { data, loading, error }] = useRegisterPageUpdateBusinessCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPageUpdateBusinessCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPageUpdateBusinessCompanyMutation,
    RegisterPageUpdateBusinessCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterPageUpdateBusinessCompanyMutation,
    RegisterPageUpdateBusinessCompanyMutationVariables
  >(RegisterPageUpdateBusinessCompanyDocument, options);
}
export type RegisterPageUpdateBusinessCompanyMutationHookResult = ReturnType<
  typeof useRegisterPageUpdateBusinessCompanyMutation
>;
export type RegisterPageUpdateBusinessCompanyMutationResult =
  Apollo.MutationResult<RegisterPageUpdateBusinessCompanyMutation>;
export type RegisterPageUpdateBusinessCompanyMutationOptions = Apollo.BaseMutationOptions<
  RegisterPageUpdateBusinessCompanyMutation,
  RegisterPageUpdateBusinessCompanyMutationVariables
>;
export const RegisterPageUploadBusinessDocumentsDocument = gql`
  mutation RegisterPageUploadBusinessDocuments($input: UploadBusinessDocumentsInput!) {
    uploadBusinessDocuments(input: $input) {
      onboardingStatus {
        ...OnboardingStatusFragment
      }
      errors
    }
  }
  ${OnboardingStatusFragmentDoc}
`;
export type RegisterPageUploadBusinessDocumentsMutationFn = Apollo.MutationFunction<
  RegisterPageUploadBusinessDocumentsMutation,
  RegisterPageUploadBusinessDocumentsMutationVariables
>;

/**
 * __useRegisterPageUploadBusinessDocumentsMutation__
 *
 * To run a mutation, you first call `useRegisterPageUploadBusinessDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageUploadBusinessDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPageUploadBusinessDocumentsMutation, { data, loading, error }] = useRegisterPageUploadBusinessDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPageUploadBusinessDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPageUploadBusinessDocumentsMutation,
    RegisterPageUploadBusinessDocumentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterPageUploadBusinessDocumentsMutation,
    RegisterPageUploadBusinessDocumentsMutationVariables
  >(RegisterPageUploadBusinessDocumentsDocument, options);
}
export type RegisterPageUploadBusinessDocumentsMutationHookResult = ReturnType<
  typeof useRegisterPageUploadBusinessDocumentsMutation
>;
export type RegisterPageUploadBusinessDocumentsMutationResult =
  Apollo.MutationResult<RegisterPageUploadBusinessDocumentsMutation>;
export type RegisterPageUploadBusinessDocumentsMutationOptions = Apollo.BaseMutationOptions<
  RegisterPageUploadBusinessDocumentsMutation,
  RegisterPageUploadBusinessDocumentsMutationVariables
>;
export const RegisterPageQueryDocument = gql`
  query RegisterPageQuery {
    me {
      id
      onboardingStatus {
        ...OnboardingStatusFragment
      }
    }
  }
  ${OnboardingStatusFragmentDoc}
`;

/**
 * __useRegisterPageQuery__
 *
 * To run a query within a React component, call `useRegisterPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegisterPageQuery(
  baseOptions?: Apollo.QueryHookOptions<RegisterPageQuery, RegisterPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegisterPageQuery, RegisterPageQueryVariables>(RegisterPageQueryDocument, options);
}
export function useRegisterPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegisterPageQuery, RegisterPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegisterPageQuery, RegisterPageQueryVariables>(RegisterPageQueryDocument, options);
}
export function useRegisterPageQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RegisterPageQuery, RegisterPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RegisterPageQuery, RegisterPageQueryVariables>(RegisterPageQueryDocument, options);
}
export type RegisterPageQueryHookResult = ReturnType<typeof useRegisterPageQuery>;
export type RegisterPageQueryLazyQueryHookResult = ReturnType<typeof useRegisterPageQueryLazyQuery>;
export type RegisterPageQuerySuspenseQueryHookResult = ReturnType<typeof useRegisterPageQuerySuspenseQuery>;
export type RegisterPageQueryQueryResult = Apollo.QueryResult<RegisterPageQuery, RegisterPageQueryVariables>;
export const DocumentsScreenDocument = gql`
  query DocumentsScreen($countryKey: Country!) {
    businessCompanyRequiredDocuments(countryKey: $countryKey) {
      ...CompanyScreen_DocumentFragment
    }
  }
  ${CompanyScreen_DocumentFragmentDoc}
`;

/**
 * __useDocumentsScreenQuery__
 *
 * To run a query within a React component, call `useDocumentsScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsScreenQuery({
 *   variables: {
 *      countryKey: // value for 'countryKey'
 *   },
 * });
 */
export function useDocumentsScreenQuery(
  baseOptions: Apollo.QueryHookOptions<DocumentsScreenQuery, DocumentsScreenQueryVariables> &
    ({ skip?: boolean; variables: DocumentsScreenQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DocumentsScreenQuery, DocumentsScreenQueryVariables>(DocumentsScreenDocument, options);
}
export function useDocumentsScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DocumentsScreenQuery, DocumentsScreenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DocumentsScreenQuery, DocumentsScreenQueryVariables>(DocumentsScreenDocument, options);
}
export function useDocumentsScreenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentsScreenQuery, DocumentsScreenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DocumentsScreenQuery, DocumentsScreenQueryVariables>(DocumentsScreenDocument, options);
}
export type DocumentsScreenQueryHookResult = ReturnType<typeof useDocumentsScreenQuery>;
export type DocumentsScreenLazyQueryHookResult = ReturnType<typeof useDocumentsScreenLazyQuery>;
export type DocumentsScreenSuspenseQueryHookResult = ReturnType<typeof useDocumentsScreenSuspenseQuery>;
export type DocumentsScreenQueryResult = Apollo.QueryResult<DocumentsScreenQuery, DocumentsScreenQueryVariables>;
export const SettingsPageDocument = gql`
  query SettingsPage($accountId: ID!) {
    me {
      id
      account(id: $accountId) {
        id
        ...SettingsPageScreen_AccountFragment
      }
    }
  }
  ${SettingsPageScreen_AccountFragmentDoc}
`;

/**
 * __useSettingsPageQuery__
 *
 * To run a query within a React component, call `useSettingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useSettingsPageQuery(
  baseOptions: Apollo.QueryHookOptions<SettingsPageQuery, SettingsPageQueryVariables> &
    ({ skip?: boolean; variables: SettingsPageQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SettingsPageQuery, SettingsPageQueryVariables>(SettingsPageDocument, options);
}
export function useSettingsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SettingsPageQuery, SettingsPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SettingsPageQuery, SettingsPageQueryVariables>(SettingsPageDocument, options);
}
export function useSettingsPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SettingsPageQuery, SettingsPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SettingsPageQuery, SettingsPageQueryVariables>(SettingsPageDocument, options);
}
export type SettingsPageQueryHookResult = ReturnType<typeof useSettingsPageQuery>;
export type SettingsPageLazyQueryHookResult = ReturnType<typeof useSettingsPageLazyQuery>;
export type SettingsPageSuspenseQueryHookResult = ReturnType<typeof useSettingsPageSuspenseQuery>;
export type SettingsPageQueryResult = Apollo.QueryResult<SettingsPageQuery, SettingsPageQueryVariables>;
export const SignInPageStartPhoneChallengeDocument = gql`
  mutation SignInPageStartPhoneChallenge($input: StartPhoneChallengeInput!) {
    startPhoneChallenge(input: $input) {
      challengeToken
      errors
    }
  }
`;
export type SignInPageStartPhoneChallengeMutationFn = Apollo.MutationFunction<
  SignInPageStartPhoneChallengeMutation,
  SignInPageStartPhoneChallengeMutationVariables
>;

/**
 * __useSignInPageStartPhoneChallengeMutation__
 *
 * To run a mutation, you first call `useSignInPageStartPhoneChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInPageStartPhoneChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInPageStartPhoneChallengeMutation, { data, loading, error }] = useSignInPageStartPhoneChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInPageStartPhoneChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInPageStartPhoneChallengeMutation,
    SignInPageStartPhoneChallengeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInPageStartPhoneChallengeMutation, SignInPageStartPhoneChallengeMutationVariables>(
    SignInPageStartPhoneChallengeDocument,
    options
  );
}
export type SignInPageStartPhoneChallengeMutationHookResult = ReturnType<
  typeof useSignInPageStartPhoneChallengeMutation
>;
export type SignInPageStartPhoneChallengeMutationResult = Apollo.MutationResult<SignInPageStartPhoneChallengeMutation>;
export type SignInPageStartPhoneChallengeMutationOptions = Apollo.BaseMutationOptions<
  SignInPageStartPhoneChallengeMutation,
  SignInPageStartPhoneChallengeMutationVariables
>;
export const SignInPageCompletePhoneChallengeDocument = gql`
  mutation SignInPageCompletePhoneChallenge($input: CompletePhoneChallengeInput!) {
    completePhoneChallenge(input: $input) {
      completionToken
      errors
    }
  }
`;
export type SignInPageCompletePhoneChallengeMutationFn = Apollo.MutationFunction<
  SignInPageCompletePhoneChallengeMutation,
  SignInPageCompletePhoneChallengeMutationVariables
>;

/**
 * __useSignInPageCompletePhoneChallengeMutation__
 *
 * To run a mutation, you first call `useSignInPageCompletePhoneChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInPageCompletePhoneChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInPageCompletePhoneChallengeMutation, { data, loading, error }] = useSignInPageCompletePhoneChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInPageCompletePhoneChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInPageCompletePhoneChallengeMutation,
    SignInPageCompletePhoneChallengeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignInPageCompletePhoneChallengeMutation,
    SignInPageCompletePhoneChallengeMutationVariables
  >(SignInPageCompletePhoneChallengeDocument, options);
}
export type SignInPageCompletePhoneChallengeMutationHookResult = ReturnType<
  typeof useSignInPageCompletePhoneChallengeMutation
>;
export type SignInPageCompletePhoneChallengeMutationResult =
  Apollo.MutationResult<SignInPageCompletePhoneChallengeMutation>;
export type SignInPageCompletePhoneChallengeMutationOptions = Apollo.BaseMutationOptions<
  SignInPageCompletePhoneChallengeMutation,
  SignInPageCompletePhoneChallengeMutationVariables
>;
export const SignInPageDocument = gql`
  mutation SignInPage($input: SignInInput!) {
    signIn(input: $input) {
      sessionToken
      user {
        id
        ...LayoutFragment
      }
      errors
    }
  }
  ${LayoutFragmentDoc}
`;
export type SignInPageMutationFn = Apollo.MutationFunction<SignInPageMutation, SignInPageMutationVariables>;

/**
 * __useSignInPageMutation__
 *
 * To run a mutation, you first call `useSignInPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInPageMutation, { data, loading, error }] = useSignInPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInPageMutation(
  baseOptions?: Apollo.MutationHookOptions<SignInPageMutation, SignInPageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInPageMutation, SignInPageMutationVariables>(SignInPageDocument, options);
}
export type SignInPageMutationHookResult = ReturnType<typeof useSignInPageMutation>;
export type SignInPageMutationResult = Apollo.MutationResult<SignInPageMutation>;
export type SignInPageMutationOptions = Apollo.BaseMutationOptions<SignInPageMutation, SignInPageMutationVariables>;
export const SignOutPageDocument = gql`
  mutation SignOutPage($input: SignOutInput!) {
    signOut(input: $input) {
      signedOut
      errors
    }
  }
`;
export type SignOutPageMutationFn = Apollo.MutationFunction<SignOutPageMutation, SignOutPageMutationVariables>;

/**
 * __useSignOutPageMutation__
 *
 * To run a mutation, you first call `useSignOutPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutPageMutation, { data, loading, error }] = useSignOutPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignOutPageMutation(
  baseOptions?: Apollo.MutationHookOptions<SignOutPageMutation, SignOutPageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignOutPageMutation, SignOutPageMutationVariables>(SignOutPageDocument, options);
}
export type SignOutPageMutationHookResult = ReturnType<typeof useSignOutPageMutation>;
export type SignOutPageMutationResult = Apollo.MutationResult<SignOutPageMutation>;
export type SignOutPageMutationOptions = Apollo.BaseMutationOptions<SignOutPageMutation, SignOutPageMutationVariables>;
export const StationsPageDocument = gql`
  query StationsPage($accountId: ID) {
    stations(first: 500) {
      nodes {
        id
        ...StationsPageStationSheet_StationFragment
      }
    }
  }
  ${StationsPageStationSheet_StationFragmentDoc}
`;

/**
 * __useStationsPageQuery__
 *
 * To run a query within a React component, call `useStationsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStationsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationsPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useStationsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<StationsPageQuery, StationsPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StationsPageQuery, StationsPageQueryVariables>(StationsPageDocument, options);
}
export function useStationsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StationsPageQuery, StationsPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StationsPageQuery, StationsPageQueryVariables>(StationsPageDocument, options);
}
export function useStationsPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<StationsPageQuery, StationsPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StationsPageQuery, StationsPageQueryVariables>(StationsPageDocument, options);
}
export type StationsPageQueryHookResult = ReturnType<typeof useStationsPageQuery>;
export type StationsPageLazyQueryHookResult = ReturnType<typeof useStationsPageLazyQuery>;
export type StationsPageSuspenseQueryHookResult = ReturnType<typeof useStationsPageSuspenseQuery>;
export type StationsPageQueryResult = Apollo.QueryResult<StationsPageQuery, StationsPageQueryVariables>;
export const StationsPageSpotDocument = gql`
  subscription StationsPageSpot {
    spotChanged {
      spot {
        id
        publicAvailability
      }
    }
  }
`;

/**
 * __useStationsPageSpotSubscription__
 *
 * To run a query within a React component, call `useStationsPageSpotSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStationsPageSpotSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStationsPageSpotSubscription({
 *   variables: {
 *   },
 * });
 */
export function useStationsPageSpotSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<StationsPageSpotSubscription, StationsPageSpotSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<StationsPageSpotSubscription, StationsPageSpotSubscriptionVariables>(
    StationsPageSpotDocument,
    options
  );
}
export type StationsPageSpotSubscriptionHookResult = ReturnType<typeof useStationsPageSpotSubscription>;
export type StationsPageSpotSubscriptionResult = Apollo.SubscriptionResult<StationsPageSpotSubscription>;
export const SupportPageDocument = gql`
  query SupportPage {
    supportSections {
      ...SupportPageScreen_SupportSectionFragment
    }
  }
  ${SupportPageScreen_SupportSectionFragmentDoc}
`;

/**
 * __useSupportPageQuery__
 *
 * To run a query within a React component, call `useSupportPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupportPageQuery(
  baseOptions?: Apollo.QueryHookOptions<SupportPageQuery, SupportPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SupportPageQuery, SupportPageQueryVariables>(SupportPageDocument, options);
}
export function useSupportPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SupportPageQuery, SupportPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SupportPageQuery, SupportPageQueryVariables>(SupportPageDocument, options);
}
export function useSupportPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SupportPageQuery, SupportPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SupportPageQuery, SupportPageQueryVariables>(SupportPageDocument, options);
}
export type SupportPageQueryHookResult = ReturnType<typeof useSupportPageQuery>;
export type SupportPageLazyQueryHookResult = ReturnType<typeof useSupportPageLazyQuery>;
export type SupportPageSuspenseQueryHookResult = ReturnType<typeof useSupportPageSuspenseQuery>;
export type SupportPageQueryResult = Apollo.QueryResult<SupportPageQuery, SupportPageQueryVariables>;
export const CreateUserDeletionIntentDocument = gql`
  mutation CreateUserDeletionIntent($input: CreateUserDeletionIntentInput!) {
    createUserDeletionIntent(input: $input) {
      userDeletionIntent {
        id
        ...UserDeletionIntentPage_UserDeletionIntentFragment
      }
      errors
    }
  }
  ${UserDeletionIntentPage_UserDeletionIntentFragmentDoc}
`;
export type CreateUserDeletionIntentMutationFn = Apollo.MutationFunction<
  CreateUserDeletionIntentMutation,
  CreateUserDeletionIntentMutationVariables
>;

/**
 * __useCreateUserDeletionIntentMutation__
 *
 * To run a mutation, you first call `useCreateUserDeletionIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserDeletionIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserDeletionIntentMutation, { data, loading, error }] = useCreateUserDeletionIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserDeletionIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserDeletionIntentMutation, CreateUserDeletionIntentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserDeletionIntentMutation, CreateUserDeletionIntentMutationVariables>(
    CreateUserDeletionIntentDocument,
    options
  );
}
export type CreateUserDeletionIntentMutationHookResult = ReturnType<typeof useCreateUserDeletionIntentMutation>;
export type CreateUserDeletionIntentMutationResult = Apollo.MutationResult<CreateUserDeletionIntentMutation>;
export type CreateUserDeletionIntentMutationOptions = Apollo.BaseMutationOptions<
  CreateUserDeletionIntentMutation,
  CreateUserDeletionIntentMutationVariables
>;
export const ConfirmUserDeletionIntentDocument = gql`
  mutation ConfirmUserDeletionIntent($input: ConfirmUserDeletionIntentInput!) {
    confirmUserDeletionIntent(input: $input) {
      deleted
      errors
    }
  }
`;
export type ConfirmUserDeletionIntentMutationFn = Apollo.MutationFunction<
  ConfirmUserDeletionIntentMutation,
  ConfirmUserDeletionIntentMutationVariables
>;

/**
 * __useConfirmUserDeletionIntentMutation__
 *
 * To run a mutation, you first call `useConfirmUserDeletionIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserDeletionIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserDeletionIntentMutation, { data, loading, error }] = useConfirmUserDeletionIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmUserDeletionIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmUserDeletionIntentMutation,
    ConfirmUserDeletionIntentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmUserDeletionIntentMutation, ConfirmUserDeletionIntentMutationVariables>(
    ConfirmUserDeletionIntentDocument,
    options
  );
}
export type ConfirmUserDeletionIntentMutationHookResult = ReturnType<typeof useConfirmUserDeletionIntentMutation>;
export type ConfirmUserDeletionIntentMutationResult = Apollo.MutationResult<ConfirmUserDeletionIntentMutation>;
export type ConfirmUserDeletionIntentMutationOptions = Apollo.BaseMutationOptions<
  ConfirmUserDeletionIntentMutation,
  ConfirmUserDeletionIntentMutationVariables
>;
export const UserDeletionIntentPageDocument = gql`
  query UserDeletionIntentPage($accountId: ID!, $userDeletionIntentId: ID!) {
    me {
      id
      account(id: $accountId) {
        id
        userDeletionIntent(id: $userDeletionIntentId) {
          id
          ...UserDeletionIntentPage_UserDeletionIntentFragment
        }
      }
    }
  }
  ${UserDeletionIntentPage_UserDeletionIntentFragmentDoc}
`;

/**
 * __useUserDeletionIntentPageQuery__
 *
 * To run a query within a React component, call `useUserDeletionIntentPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDeletionIntentPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDeletionIntentPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      userDeletionIntentId: // value for 'userDeletionIntentId'
 *   },
 * });
 */
export function useUserDeletionIntentPageQuery(
  baseOptions: Apollo.QueryHookOptions<UserDeletionIntentPageQuery, UserDeletionIntentPageQueryVariables> &
    ({ skip?: boolean; variables: UserDeletionIntentPageQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserDeletionIntentPageQuery, UserDeletionIntentPageQueryVariables>(
    UserDeletionIntentPageDocument,
    options
  );
}
export function useUserDeletionIntentPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserDeletionIntentPageQuery, UserDeletionIntentPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserDeletionIntentPageQuery, UserDeletionIntentPageQueryVariables>(
    UserDeletionIntentPageDocument,
    options
  );
}
export function useUserDeletionIntentPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserDeletionIntentPageQuery, UserDeletionIntentPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserDeletionIntentPageQuery, UserDeletionIntentPageQueryVariables>(
    UserDeletionIntentPageDocument,
    options
  );
}
export type UserDeletionIntentPageQueryHookResult = ReturnType<typeof useUserDeletionIntentPageQuery>;
export type UserDeletionIntentPageLazyQueryHookResult = ReturnType<typeof useUserDeletionIntentPageLazyQuery>;
export type UserDeletionIntentPageSuspenseQueryHookResult = ReturnType<typeof useUserDeletionIntentPageSuspenseQuery>;
export type UserDeletionIntentPageQueryResult = Apollo.QueryResult<
  UserDeletionIntentPageQuery,
  UserDeletionIntentPageQueryVariables
>;
export const UserInfoPageDocument = gql`
  query UserInfoPage($accountId: ID!, $accountUserId: ID!) {
    me {
      id
      account(id: $accountId) {
        id
        accountUser(id: $accountUserId) {
          ...UserPageScreen_AccountUserFragment
        }
        pro
      }
    }
  }
  ${UserPageScreen_AccountUserFragmentDoc}
`;

/**
 * __useUserInfoPageQuery__
 *
 * To run a query within a React component, call `useUserInfoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      accountUserId: // value for 'accountUserId'
 *   },
 * });
 */
export function useUserInfoPageQuery(
  baseOptions: Apollo.QueryHookOptions<UserInfoPageQuery, UserInfoPageQueryVariables> &
    ({ skip?: boolean; variables: UserInfoPageQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserInfoPageQuery, UserInfoPageQueryVariables>(UserInfoPageDocument, options);
}
export function useUserInfoPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserInfoPageQuery, UserInfoPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserInfoPageQuery, UserInfoPageQueryVariables>(UserInfoPageDocument, options);
}
export function useUserInfoPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserInfoPageQuery, UserInfoPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserInfoPageQuery, UserInfoPageQueryVariables>(UserInfoPageDocument, options);
}
export type UserInfoPageQueryHookResult = ReturnType<typeof useUserInfoPageQuery>;
export type UserInfoPageLazyQueryHookResult = ReturnType<typeof useUserInfoPageLazyQuery>;
export type UserInfoPageSuspenseQueryHookResult = ReturnType<typeof useUserInfoPageSuspenseQuery>;
export type UserInfoPageQueryResult = Apollo.QueryResult<UserInfoPageQuery, UserInfoPageQueryVariables>;
export const UpdateAccountUserPageDocument = gql`
  mutation UpdateAccountUserPage($input: UpdateAccountUserInput!) {
    updateAccountUser(input: $input) {
      accountUser {
        ...UserPageScreen_AccountUserFragment
      }
      errors
    }
  }
  ${UserPageScreen_AccountUserFragmentDoc}
`;
export type UpdateAccountUserPageMutationFn = Apollo.MutationFunction<
  UpdateAccountUserPageMutation,
  UpdateAccountUserPageMutationVariables
>;

/**
 * __useUpdateAccountUserPageMutation__
 *
 * To run a mutation, you first call `useUpdateAccountUserPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountUserPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountUserPageMutation, { data, loading, error }] = useUpdateAccountUserPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountUserPageMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountUserPageMutation, UpdateAccountUserPageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountUserPageMutation, UpdateAccountUserPageMutationVariables>(
    UpdateAccountUserPageDocument,
    options
  );
}
export type UpdateAccountUserPageMutationHookResult = ReturnType<typeof useUpdateAccountUserPageMutation>;
export type UpdateAccountUserPageMutationResult = Apollo.MutationResult<UpdateAccountUserPageMutation>;
export type UpdateAccountUserPageMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountUserPageMutation,
  UpdateAccountUserPageMutationVariables
>;
export const UserPageDocument = gql`
  query UserPage($accountId: ID!, $accountUserId: ID!) {
    me {
      id
      account(id: $accountId) {
        id
        accountUser(id: $accountUserId) {
          ...UserPageScreen_AccountUserFragment
        }
      }
    }
  }
  ${UserPageScreen_AccountUserFragmentDoc}
`;

/**
 * __useUserPageQuery__
 *
 * To run a query within a React component, call `useUserPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      accountUserId: // value for 'accountUserId'
 *   },
 * });
 */
export function useUserPageQuery(
  baseOptions: Apollo.QueryHookOptions<UserPageQuery, UserPageQueryVariables> &
    ({ skip?: boolean; variables: UserPageQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserPageQuery, UserPageQueryVariables>(UserPageDocument, options);
}
export function useUserPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPageQuery, UserPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserPageQuery, UserPageQueryVariables>(UserPageDocument, options);
}
export function useUserPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserPageQuery, UserPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserPageQuery, UserPageQueryVariables>(UserPageDocument, options);
}
export type UserPageQueryHookResult = ReturnType<typeof useUserPageQuery>;
export type UserPageLazyQueryHookResult = ReturnType<typeof useUserPageLazyQuery>;
export type UserPageSuspenseQueryHookResult = ReturnType<typeof useUserPageSuspenseQuery>;
export type UserPageQueryResult = Apollo.QueryResult<UserPageQuery, UserPageQueryVariables>;
export const CreateMultipleInvitationDocument = gql`
  mutation CreateMultipleInvitation($input: CreateMultipleInvitationsInput!) {
    createMultipleInvitations(input: $input) {
      account {
        id
      }
      errors
      successMessage
      errorMessage
      sentInvitationsCount
    }
  }
`;
export type CreateMultipleInvitationMutationFn = Apollo.MutationFunction<
  CreateMultipleInvitationMutation,
  CreateMultipleInvitationMutationVariables
>;

/**
 * __useCreateMultipleInvitationMutation__
 *
 * To run a mutation, you first call `useCreateMultipleInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultipleInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultipleInvitationMutation, { data, loading, error }] = useCreateMultipleInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMultipleInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMultipleInvitationMutation, CreateMultipleInvitationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMultipleInvitationMutation, CreateMultipleInvitationMutationVariables>(
    CreateMultipleInvitationDocument,
    options
  );
}
export type CreateMultipleInvitationMutationHookResult = ReturnType<typeof useCreateMultipleInvitationMutation>;
export type CreateMultipleInvitationMutationResult = Apollo.MutationResult<CreateMultipleInvitationMutation>;
export type CreateMultipleInvitationMutationOptions = Apollo.BaseMutationOptions<
  CreateMultipleInvitationMutation,
  CreateMultipleInvitationMutationVariables
>;
export const CreateInvitationDocument = gql`
  mutation CreateInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      invitation {
        ...InvitationNewPage_InvitationFragment
      }
      errors
      successMessage
      successTitle
    }
  }
  ${InvitationNewPage_InvitationFragmentDoc}
`;
export type CreateInvitationMutationFn = Apollo.MutationFunction<
  CreateInvitationMutation,
  CreateInvitationMutationVariables
>;

/**
 * __useCreateInvitationMutation__
 *
 * To run a mutation, you first call `useCreateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitationMutation, { data, loading, error }] = useCreateInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInvitationMutation, CreateInvitationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInvitationMutation, CreateInvitationMutationVariables>(
    CreateInvitationDocument,
    options
  );
}
export type CreateInvitationMutationHookResult = ReturnType<typeof useCreateInvitationMutation>;
export type CreateInvitationMutationResult = Apollo.MutationResult<CreateInvitationMutation>;
export type CreateInvitationMutationOptions = Apollo.BaseMutationOptions<
  CreateInvitationMutation,
  CreateInvitationMutationVariables
>;
export const BulkReinviteUsersDocument = gql`
  mutation BulkReinviteUsers($input: BulkReinviteUsersInput!) {
    bulkReinviteUsers(input: $input) {
      sentReinvitationsCount
      errors
      successMessage
      errorMessage
      warningMessage
    }
  }
`;
export type BulkReinviteUsersMutationFn = Apollo.MutationFunction<
  BulkReinviteUsersMutation,
  BulkReinviteUsersMutationVariables
>;

/**
 * __useBulkReinviteUsersMutation__
 *
 * To run a mutation, you first call `useBulkReinviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkReinviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkReinviteUsersMutation, { data, loading, error }] = useBulkReinviteUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkReinviteUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<BulkReinviteUsersMutation, BulkReinviteUsersMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkReinviteUsersMutation, BulkReinviteUsersMutationVariables>(
    BulkReinviteUsersDocument,
    options
  );
}
export type BulkReinviteUsersMutationHookResult = ReturnType<typeof useBulkReinviteUsersMutation>;
export type BulkReinviteUsersMutationResult = Apollo.MutationResult<BulkReinviteUsersMutation>;
export type BulkReinviteUsersMutationOptions = Apollo.BaseMutationOptions<
  BulkReinviteUsersMutation,
  BulkReinviteUsersMutationVariables
>;
export const UsersPageDocument = gql`
  query UsersPage($accountId: ID!, $search: String) {
    me {
      id
      account(id: $accountId) {
        id
        admins: accountUsers(role: ADMIN, search: $search) {
          nodes {
            ...UsersPageScreen_AccountUserFragment
          }
        }
        members: accountUsers(first: 1000, role: MEMBER, search: $search) {
          nodes {
            ...UsersPageScreen_AccountUserFragment
          }
        }
        invitations(first: 1000, search: $search) {
          nodes {
            ...UsersPageScreen_InvitationFragment
          }
        }
      }
    }
  }
  ${UsersPageScreen_AccountUserFragmentDoc}
  ${UsersPageScreen_InvitationFragmentDoc}
`;

/**
 * __useUsersPageQuery__
 *
 * To run a query within a React component, call `useUsersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useUsersPageQuery(
  baseOptions: Apollo.QueryHookOptions<UsersPageQuery, UsersPageQueryVariables> &
    ({ skip?: boolean; variables: UsersPageQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersPageQuery, UsersPageQueryVariables>(UsersPageDocument, options);
}
export function useUsersPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersPageQuery, UsersPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersPageQuery, UsersPageQueryVariables>(UsersPageDocument, options);
}
export function useUsersPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UsersPageQuery, UsersPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UsersPageQuery, UsersPageQueryVariables>(UsersPageDocument, options);
}
export type UsersPageQueryHookResult = ReturnType<typeof useUsersPageQuery>;
export type UsersPageLazyQueryHookResult = ReturnType<typeof useUsersPageLazyQuery>;
export type UsersPageSuspenseQueryHookResult = ReturnType<typeof useUsersPageSuspenseQuery>;
export type UsersPageQueryResult = Apollo.QueryResult<UsersPageQuery, UsersPageQueryVariables>;
export const PhoneNumberScreenDocument = gql`
  query PhoneNumberScreen {
    countries {
      ...PhoneNumberScreen_CountryObjectFragment
    }
  }
  ${PhoneNumberScreen_CountryObjectFragmentDoc}
`;

/**
 * __usePhoneNumberScreenQuery__
 *
 * To run a query within a React component, call `usePhoneNumberScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhoneNumberScreenQuery(
  baseOptions?: Apollo.QueryHookOptions<PhoneNumberScreenQuery, PhoneNumberScreenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhoneNumberScreenQuery, PhoneNumberScreenQueryVariables>(PhoneNumberScreenDocument, options);
}
export function usePhoneNumberScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumberScreenQuery, PhoneNumberScreenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhoneNumberScreenQuery, PhoneNumberScreenQueryVariables>(
    PhoneNumberScreenDocument,
    options
  );
}
export function usePhoneNumberScreenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PhoneNumberScreenQuery, PhoneNumberScreenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PhoneNumberScreenQuery, PhoneNumberScreenQueryVariables>(
    PhoneNumberScreenDocument,
    options
  );
}
export type PhoneNumberScreenQueryHookResult = ReturnType<typeof usePhoneNumberScreenQuery>;
export type PhoneNumberScreenLazyQueryHookResult = ReturnType<typeof usePhoneNumberScreenLazyQuery>;
export type PhoneNumberScreenSuspenseQueryHookResult = ReturnType<typeof usePhoneNumberScreenSuspenseQuery>;
export type PhoneNumberScreenQueryResult = Apollo.QueryResult<PhoneNumberScreenQuery, PhoneNumberScreenQueryVariables>;
export const AnonymousAccessPageCreateAnonymousRightDocument = gql`
  mutation AnonymousAccessPageCreateAnonymousRight($input: CreateAnonymousRightInput!) {
    createAnonymousRight(input: $input) {
      success
      errors
    }
  }
`;
export type AnonymousAccessPageCreateAnonymousRightMutationFn = Apollo.MutationFunction<
  AnonymousAccessPageCreateAnonymousRightMutation,
  AnonymousAccessPageCreateAnonymousRightMutationVariables
>;

/**
 * __useAnonymousAccessPageCreateAnonymousRightMutation__
 *
 * To run a mutation, you first call `useAnonymousAccessPageCreateAnonymousRightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnonymousAccessPageCreateAnonymousRightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anonymousAccessPageCreateAnonymousRightMutation, { data, loading, error }] = useAnonymousAccessPageCreateAnonymousRightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnonymousAccessPageCreateAnonymousRightMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnonymousAccessPageCreateAnonymousRightMutation,
    AnonymousAccessPageCreateAnonymousRightMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnonymousAccessPageCreateAnonymousRightMutation,
    AnonymousAccessPageCreateAnonymousRightMutationVariables
  >(AnonymousAccessPageCreateAnonymousRightDocument, options);
}
export type AnonymousAccessPageCreateAnonymousRightMutationHookResult = ReturnType<
  typeof useAnonymousAccessPageCreateAnonymousRightMutation
>;
export type AnonymousAccessPageCreateAnonymousRightMutationResult =
  Apollo.MutationResult<AnonymousAccessPageCreateAnonymousRightMutation>;
export type AnonymousAccessPageCreateAnonymousRightMutationOptions = Apollo.BaseMutationOptions<
  AnonymousAccessPageCreateAnonymousRightMutation,
  AnonymousAccessPageCreateAnonymousRightMutationVariables
>;
export const AnonymousAccessPageDocument = gql`
  query AnonymousAccessPage($accessId: ID!) {
    stationAccess(id: $accessId) {
      ...AnonymousAccessPage_StationAccessFragment
    }
    countries {
      ...AnonymousAccessPage_CountryObjectFragment
    }
  }
  ${AnonymousAccessPage_StationAccessFragmentDoc}
  ${AnonymousAccessPage_CountryObjectFragmentDoc}
`;

/**
 * __useAnonymousAccessPageQuery__
 *
 * To run a query within a React component, call `useAnonymousAccessPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnonymousAccessPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnonymousAccessPageQuery({
 *   variables: {
 *      accessId: // value for 'accessId'
 *   },
 * });
 */
export function useAnonymousAccessPageQuery(
  baseOptions: Apollo.QueryHookOptions<AnonymousAccessPageQuery, AnonymousAccessPageQueryVariables> &
    ({ skip?: boolean; variables: AnonymousAccessPageQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnonymousAccessPageQuery, AnonymousAccessPageQueryVariables>(
    AnonymousAccessPageDocument,
    options
  );
}
export function useAnonymousAccessPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AnonymousAccessPageQuery, AnonymousAccessPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AnonymousAccessPageQuery, AnonymousAccessPageQueryVariables>(
    AnonymousAccessPageDocument,
    options
  );
}
export function useAnonymousAccessPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AnonymousAccessPageQuery, AnonymousAccessPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AnonymousAccessPageQuery, AnonymousAccessPageQueryVariables>(
    AnonymousAccessPageDocument,
    options
  );
}
export type AnonymousAccessPageQueryHookResult = ReturnType<typeof useAnonymousAccessPageQuery>;
export type AnonymousAccessPageLazyQueryHookResult = ReturnType<typeof useAnonymousAccessPageLazyQuery>;
export type AnonymousAccessPageSuspenseQueryHookResult = ReturnType<typeof useAnonymousAccessPageSuspenseQuery>;
export type AnonymousAccessPageQueryResult = Apollo.QueryResult<
  AnonymousAccessPageQuery,
  AnonymousAccessPageQueryVariables
>;
export const CreateAnonymousChargeIntentDocument = gql`
  mutation CreateAnonymousChargeIntent($input: CreateAnonymousChargeIntentInput!) {
    createAnonymousChargeIntent(input: $input) {
      sessionToken
      accountId
      errors
    }
  }
`;
export type CreateAnonymousChargeIntentMutationFn = Apollo.MutationFunction<
  CreateAnonymousChargeIntentMutation,
  CreateAnonymousChargeIntentMutationVariables
>;

/**
 * __useCreateAnonymousChargeIntentMutation__
 *
 * To run a mutation, you first call `useCreateAnonymousChargeIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnonymousChargeIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnonymousChargeIntentMutation, { data, loading, error }] = useCreateAnonymousChargeIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnonymousChargeIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnonymousChargeIntentMutation,
    CreateAnonymousChargeIntentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAnonymousChargeIntentMutation, CreateAnonymousChargeIntentMutationVariables>(
    CreateAnonymousChargeIntentDocument,
    options
  );
}
export type CreateAnonymousChargeIntentMutationHookResult = ReturnType<typeof useCreateAnonymousChargeIntentMutation>;
export type CreateAnonymousChargeIntentMutationResult = Apollo.MutationResult<CreateAnonymousChargeIntentMutation>;
export type CreateAnonymousChargeIntentMutationOptions = Apollo.BaseMutationOptions<
  CreateAnonymousChargeIntentMutation,
  CreateAnonymousChargeIntentMutationVariables
>;
export const ChargePointPageDocument = gql`
  query ChargePointPage($publicCode: String!, $forAnonymousSession: Boolean) {
    chargePoint(publicCode: $publicCode) {
      id
      ...ChargePointPage_ChargePointFragment
    }
  }
  ${ChargePointPage_ChargePointFragmentDoc}
`;

/**
 * __useChargePointPageQuery__
 *
 * To run a query within a React component, call `useChargePointPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargePointPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargePointPageQuery({
 *   variables: {
 *      publicCode: // value for 'publicCode'
 *      forAnonymousSession: // value for 'forAnonymousSession'
 *   },
 * });
 */
export function useChargePointPageQuery(
  baseOptions: Apollo.QueryHookOptions<ChargePointPageQuery, ChargePointPageQueryVariables> &
    ({ skip?: boolean; variables: ChargePointPageQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChargePointPageQuery, ChargePointPageQueryVariables>(ChargePointPageDocument, options);
}
export function useChargePointPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChargePointPageQuery, ChargePointPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChargePointPageQuery, ChargePointPageQueryVariables>(ChargePointPageDocument, options);
}
export function useChargePointPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ChargePointPageQuery, ChargePointPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ChargePointPageQuery, ChargePointPageQueryVariables>(ChargePointPageDocument, options);
}
export type ChargePointPageQueryHookResult = ReturnType<typeof useChargePointPageQuery>;
export type ChargePointPageLazyQueryHookResult = ReturnType<typeof useChargePointPageLazyQuery>;
export type ChargePointPageSuspenseQueryHookResult = ReturnType<typeof useChargePointPageSuspenseQuery>;
export type ChargePointPageQueryResult = Apollo.QueryResult<ChargePointPageQuery, ChargePointPageQueryVariables>;
export const CurrentRemoteChargeDocument = gql`
  query CurrentRemoteCharge {
    anonymousSession {
      ...CurrentRemoteChargeFragment
    }
  }
  ${CurrentRemoteChargeFragmentDoc}
`;

/**
 * __useCurrentRemoteChargeQuery__
 *
 * To run a query within a React component, call `useCurrentRemoteChargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentRemoteChargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentRemoteChargeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentRemoteChargeQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentRemoteChargeQuery, CurrentRemoteChargeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentRemoteChargeQuery, CurrentRemoteChargeQueryVariables>(
    CurrentRemoteChargeDocument,
    options
  );
}
export function useCurrentRemoteChargeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentRemoteChargeQuery, CurrentRemoteChargeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentRemoteChargeQuery, CurrentRemoteChargeQueryVariables>(
    CurrentRemoteChargeDocument,
    options
  );
}
export function useCurrentRemoteChargeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentRemoteChargeQuery, CurrentRemoteChargeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CurrentRemoteChargeQuery, CurrentRemoteChargeQueryVariables>(
    CurrentRemoteChargeDocument,
    options
  );
}
export type CurrentRemoteChargeQueryHookResult = ReturnType<typeof useCurrentRemoteChargeQuery>;
export type CurrentRemoteChargeLazyQueryHookResult = ReturnType<typeof useCurrentRemoteChargeLazyQuery>;
export type CurrentRemoteChargeSuspenseQueryHookResult = ReturnType<typeof useCurrentRemoteChargeSuspenseQuery>;
export type CurrentRemoteChargeQueryResult = Apollo.QueryResult<
  CurrentRemoteChargeQuery,
  CurrentRemoteChargeQueryVariables
>;
export const RetrieveAnonymousSessionDocument = gql`
  mutation RetrieveAnonymousSession($input: RetrieveAnonymousSessionInput!) {
    retrieveAnonymousSession(input: $input) {
      sessionToken
      errors
    }
  }
`;
export type RetrieveAnonymousSessionMutationFn = Apollo.MutationFunction<
  RetrieveAnonymousSessionMutation,
  RetrieveAnonymousSessionMutationVariables
>;

/**
 * __useRetrieveAnonymousSessionMutation__
 *
 * To run a mutation, you first call `useRetrieveAnonymousSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrieveAnonymousSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrieveAnonymousSessionMutation, { data, loading, error }] = useRetrieveAnonymousSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetrieveAnonymousSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<RetrieveAnonymousSessionMutation, RetrieveAnonymousSessionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RetrieveAnonymousSessionMutation, RetrieveAnonymousSessionMutationVariables>(
    RetrieveAnonymousSessionDocument,
    options
  );
}
export type RetrieveAnonymousSessionMutationHookResult = ReturnType<typeof useRetrieveAnonymousSessionMutation>;
export type RetrieveAnonymousSessionMutationResult = Apollo.MutationResult<RetrieveAnonymousSessionMutation>;
export type RetrieveAnonymousSessionMutationOptions = Apollo.BaseMutationOptions<
  RetrieveAnonymousSessionMutation,
  RetrieveAnonymousSessionMutationVariables
>;
export const HomePageDocument = gql`
  query HomePage($accountId: ID!) {
    me {
      id
      account(id: $accountId) {
        id
        accountUser {
          ...HomePageScreen_AccountUserFragment
        }
      }
    }
  }
  ${HomePageScreen_AccountUserFragmentDoc}
`;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useHomePageQuery(
  baseOptions: Apollo.QueryHookOptions<HomePageQuery, HomePageQueryVariables> &
    ({ skip?: boolean; variables: HomePageQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
}
export function useHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
}
export function useHomePageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<HomePageQuery, HomePageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
}
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<typeof useHomePageLazyQuery>;
export type HomePageSuspenseQueryHookResult = ReturnType<typeof useHomePageSuspenseQuery>;
export type HomePageQueryResult = Apollo.QueryResult<HomePageQuery, HomePageQueryVariables>;
export const RetrieveBusinessChargesDocument = gql`
  mutation RetrieveBusinessCharges($input: RetrieveBusinessChargesInput!) {
    retrieveBusinessCharges(input: $input) {
      charges {
        ...InvoicePage_ChargeFragment
      }
      errors
    }
  }
  ${InvoicePage_ChargeFragmentDoc}
`;
export type RetrieveBusinessChargesMutationFn = Apollo.MutationFunction<
  RetrieveBusinessChargesMutation,
  RetrieveBusinessChargesMutationVariables
>;

/**
 * __useRetrieveBusinessChargesMutation__
 *
 * To run a mutation, you first call `useRetrieveBusinessChargesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrieveBusinessChargesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrieveBusinessChargesMutation, { data, loading, error }] = useRetrieveBusinessChargesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetrieveBusinessChargesMutation(
  baseOptions?: Apollo.MutationHookOptions<RetrieveBusinessChargesMutation, RetrieveBusinessChargesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RetrieveBusinessChargesMutation, RetrieveBusinessChargesMutationVariables>(
    RetrieveBusinessChargesDocument,
    options
  );
}
export type RetrieveBusinessChargesMutationHookResult = ReturnType<typeof useRetrieveBusinessChargesMutation>;
export type RetrieveBusinessChargesMutationResult = Apollo.MutationResult<RetrieveBusinessChargesMutation>;
export type RetrieveBusinessChargesMutationOptions = Apollo.BaseMutationOptions<
  RetrieveBusinessChargesMutation,
  RetrieveBusinessChargesMutationVariables
>;
export const SendInvoiceDocument = gql`
  mutation SendInvoice($input: SendInvoiceInput!) {
    sendInvoice(input: $input) {
      errors
    }
  }
`;
export type SendInvoiceMutationFn = Apollo.MutationFunction<SendInvoiceMutation, SendInvoiceMutationVariables>;

/**
 * __useSendInvoiceMutation__
 *
 * To run a mutation, you first call `useSendInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceMutation, { data, loading, error }] = useSendInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<SendInvoiceMutation, SendInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendInvoiceMutation, SendInvoiceMutationVariables>(SendInvoiceDocument, options);
}
export type SendInvoiceMutationHookResult = ReturnType<typeof useSendInvoiceMutation>;
export type SendInvoiceMutationResult = Apollo.MutationResult<SendInvoiceMutation>;
export type SendInvoiceMutationOptions = Apollo.BaseMutationOptions<SendInvoiceMutation, SendInvoiceMutationVariables>;
export const CreatePaymentMethodDocument = gql`
  mutation CreatePaymentMethod($input: CreatePaymentMethodInput!) {
    createPaymentMethod(input: $input) {
      paymentMethod {
        ...PaymentMethodPage_PaymentMethodFragment
      }
      errors
    }
  }
  ${PaymentMethodPage_PaymentMethodFragmentDoc}
`;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(
    CreatePaymentMethodDocument,
    options
  );
}
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;
export const ConfirmInstantChargeIntentDocument = gql`
  mutation ConfirmInstantChargeIntent($input: ConfirmInstantChargeIntentInput!) {
    confirmInstantChargeIntent(input: $input) {
      remoteCharge {
        id
        ...RemoteChargePage_RemoteChargeFragment
      }
      errors
    }
  }
  ${RemoteChargePage_RemoteChargeFragmentDoc}
`;
export type ConfirmInstantChargeIntentMutationFn = Apollo.MutationFunction<
  ConfirmInstantChargeIntentMutation,
  ConfirmInstantChargeIntentMutationVariables
>;

/**
 * __useConfirmInstantChargeIntentMutation__
 *
 * To run a mutation, you first call `useConfirmInstantChargeIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmInstantChargeIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmInstantChargeIntentMutation, { data, loading, error }] = useConfirmInstantChargeIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmInstantChargeIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmInstantChargeIntentMutation,
    ConfirmInstantChargeIntentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmInstantChargeIntentMutation, ConfirmInstantChargeIntentMutationVariables>(
    ConfirmInstantChargeIntentDocument,
    options
  );
}
export type ConfirmInstantChargeIntentMutationHookResult = ReturnType<typeof useConfirmInstantChargeIntentMutation>;
export type ConfirmInstantChargeIntentMutationResult = Apollo.MutationResult<ConfirmInstantChargeIntentMutation>;
export type ConfirmInstantChargeIntentMutationOptions = Apollo.BaseMutationOptions<
  ConfirmInstantChargeIntentMutation,
  ConfirmInstantChargeIntentMutationVariables
>;
export const InquireInstantChargePaymentIntentDocument = gql`
  mutation InquireInstantChargePaymentIntent($input: InquireInstantChargePaymentIntentInput!) {
    inquireInstantChargePaymentIntent(input: $input) {
      needed
      paymentIntent {
        id
        ...PaymentIntentAuthorizeBottomSheet_PaymentIntentFragment
      }
      errors
    }
  }
  ${PaymentIntentAuthorizeBottomSheet_PaymentIntentFragmentDoc}
`;
export type InquireInstantChargePaymentIntentMutationFn = Apollo.MutationFunction<
  InquireInstantChargePaymentIntentMutation,
  InquireInstantChargePaymentIntentMutationVariables
>;

/**
 * __useInquireInstantChargePaymentIntentMutation__
 *
 * To run a mutation, you first call `useInquireInstantChargePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquireInstantChargePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquireInstantChargePaymentIntentMutation, { data, loading, error }] = useInquireInstantChargePaymentIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInquireInstantChargePaymentIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InquireInstantChargePaymentIntentMutation,
    InquireInstantChargePaymentIntentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InquireInstantChargePaymentIntentMutation,
    InquireInstantChargePaymentIntentMutationVariables
  >(InquireInstantChargePaymentIntentDocument, options);
}
export type InquireInstantChargePaymentIntentMutationHookResult = ReturnType<
  typeof useInquireInstantChargePaymentIntentMutation
>;
export type InquireInstantChargePaymentIntentMutationResult =
  Apollo.MutationResult<InquireInstantChargePaymentIntentMutation>;
export type InquireInstantChargePaymentIntentMutationOptions = Apollo.BaseMutationOptions<
  InquireInstantChargePaymentIntentMutation,
  InquireInstantChargePaymentIntentMutationVariables
>;
export const PaymentMethodPageDocument = gql`
  query PaymentMethodPage {
    anonymousSession {
      ...PaymentMethodPage_AnonymousSessionFragment
    }
  }
  ${PaymentMethodPage_AnonymousSessionFragmentDoc}
`;

/**
 * __usePaymentMethodPageQuery__
 *
 * To run a query within a React component, call `usePaymentMethodPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentMethodPageQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentMethodPageQuery, PaymentMethodPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentMethodPageQuery, PaymentMethodPageQueryVariables>(PaymentMethodPageDocument, options);
}
export function usePaymentMethodPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodPageQuery, PaymentMethodPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentMethodPageQuery, PaymentMethodPageQueryVariables>(
    PaymentMethodPageDocument,
    options
  );
}
export function usePaymentMethodPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PaymentMethodPageQuery, PaymentMethodPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentMethodPageQuery, PaymentMethodPageQueryVariables>(
    PaymentMethodPageDocument,
    options
  );
}
export type PaymentMethodPageQueryHookResult = ReturnType<typeof usePaymentMethodPageQuery>;
export type PaymentMethodPageLazyQueryHookResult = ReturnType<typeof usePaymentMethodPageLazyQuery>;
export type PaymentMethodPageSuspenseQueryHookResult = ReturnType<typeof usePaymentMethodPageSuspenseQuery>;
export type PaymentMethodPageQueryResult = Apollo.QueryResult<PaymentMethodPageQuery, PaymentMethodPageQueryVariables>;
export const RemoteChargePageActionDocument = gql`
  mutation RemoteChargePageAction($input: ReportRemoteChargeActionInput!) {
    reportRemoteChargeAction(input: $input) {
      remoteCharge {
        id
        ...RemoteChargePage_RemoteChargeFragment
      }
      errors
    }
  }
  ${RemoteChargePage_RemoteChargeFragmentDoc}
`;
export type RemoteChargePageActionMutationFn = Apollo.MutationFunction<
  RemoteChargePageActionMutation,
  RemoteChargePageActionMutationVariables
>;

/**
 * __useRemoteChargePageActionMutation__
 *
 * To run a mutation, you first call `useRemoteChargePageActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoteChargePageActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remoteChargePageActionMutation, { data, loading, error }] = useRemoteChargePageActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoteChargePageActionMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoteChargePageActionMutation, RemoteChargePageActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoteChargePageActionMutation, RemoteChargePageActionMutationVariables>(
    RemoteChargePageActionDocument,
    options
  );
}
export type RemoteChargePageActionMutationHookResult = ReturnType<typeof useRemoteChargePageActionMutation>;
export type RemoteChargePageActionMutationResult = Apollo.MutationResult<RemoteChargePageActionMutation>;
export type RemoteChargePageActionMutationOptions = Apollo.BaseMutationOptions<
  RemoteChargePageActionMutation,
  RemoteChargePageActionMutationVariables
>;
export const ChangeRemoteChargeObjectiveDocument = gql`
  mutation ChangeRemoteChargeObjective($input: ChangeRemoteChargeObjectiveInput!) {
    changeRemoteChargeObjective(input: $input) {
      remoteCharge {
        id
        ...RemoteChargePage_RemoteChargeFragment
      }
      errors
    }
  }
  ${RemoteChargePage_RemoteChargeFragmentDoc}
`;
export type ChangeRemoteChargeObjectiveMutationFn = Apollo.MutationFunction<
  ChangeRemoteChargeObjectiveMutation,
  ChangeRemoteChargeObjectiveMutationVariables
>;

/**
 * __useChangeRemoteChargeObjectiveMutation__
 *
 * To run a mutation, you first call `useChangeRemoteChargeObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRemoteChargeObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRemoteChargeObjectiveMutation, { data, loading, error }] = useChangeRemoteChargeObjectiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeRemoteChargeObjectiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeRemoteChargeObjectiveMutation,
    ChangeRemoteChargeObjectiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeRemoteChargeObjectiveMutation, ChangeRemoteChargeObjectiveMutationVariables>(
    ChangeRemoteChargeObjectiveDocument,
    options
  );
}
export type ChangeRemoteChargeObjectiveMutationHookResult = ReturnType<typeof useChangeRemoteChargeObjectiveMutation>;
export type ChangeRemoteChargeObjectiveMutationResult = Apollo.MutationResult<ChangeRemoteChargeObjectiveMutation>;
export type ChangeRemoteChargeObjectiveMutationOptions = Apollo.BaseMutationOptions<
  ChangeRemoteChargeObjectiveMutation,
  ChangeRemoteChargeObjectiveMutationVariables
>;
export const RemoteChargePageDocument = gql`
  query RemoteChargePage {
    anonymousSession {
      id
      currentRemoteCharge {
        id
        ...RemoteChargePage_RemoteChargeFragment
      }
    }
  }
  ${RemoteChargePage_RemoteChargeFragmentDoc}
`;

/**
 * __useRemoteChargePageQuery__
 *
 * To run a query within a React component, call `useRemoteChargePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemoteChargePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoteChargePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRemoteChargePageQuery(
  baseOptions?: Apollo.QueryHookOptions<RemoteChargePageQuery, RemoteChargePageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RemoteChargePageQuery, RemoteChargePageQueryVariables>(RemoteChargePageDocument, options);
}
export function useRemoteChargePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RemoteChargePageQuery, RemoteChargePageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RemoteChargePageQuery, RemoteChargePageQueryVariables>(RemoteChargePageDocument, options);
}
export function useRemoteChargePageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RemoteChargePageQuery, RemoteChargePageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RemoteChargePageQuery, RemoteChargePageQueryVariables>(
    RemoteChargePageDocument,
    options
  );
}
export type RemoteChargePageQueryHookResult = ReturnType<typeof useRemoteChargePageQuery>;
export type RemoteChargePageLazyQueryHookResult = ReturnType<typeof useRemoteChargePageLazyQuery>;
export type RemoteChargePageSuspenseQueryHookResult = ReturnType<typeof useRemoteChargePageSuspenseQuery>;
export type RemoteChargePageQueryResult = Apollo.QueryResult<RemoteChargePageQuery, RemoteChargePageQueryVariables>;
export const CompletedScreenDocument = gql`
  query CompletedScreen($chargeId: ID!) {
    anonymousSession {
      ...CompletedScreen_AnonymousSessionFragment
    }
  }
  ${CompletedScreen_AnonymousSessionFragmentDoc}
`;

/**
 * __useCompletedScreenQuery__
 *
 * To run a query within a React component, call `useCompletedScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedScreenQuery({
 *   variables: {
 *      chargeId: // value for 'chargeId'
 *   },
 * });
 */
export function useCompletedScreenQuery(
  baseOptions: Apollo.QueryHookOptions<CompletedScreenQuery, CompletedScreenQueryVariables> &
    ({ skip?: boolean; variables: CompletedScreenQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompletedScreenQuery, CompletedScreenQueryVariables>(CompletedScreenDocument, options);
}
export function useCompletedScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompletedScreenQuery, CompletedScreenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompletedScreenQuery, CompletedScreenQueryVariables>(CompletedScreenDocument, options);
}
export function useCompletedScreenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CompletedScreenQuery, CompletedScreenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompletedScreenQuery, CompletedScreenQueryVariables>(CompletedScreenDocument, options);
}
export type CompletedScreenQueryHookResult = ReturnType<typeof useCompletedScreenQuery>;
export type CompletedScreenLazyQueryHookResult = ReturnType<typeof useCompletedScreenLazyQuery>;
export type CompletedScreenSuspenseQueryHookResult = ReturnType<typeof useCompletedScreenSuspenseQuery>;
export type CompletedScreenQueryResult = Apollo.QueryResult<CompletedScreenQuery, CompletedScreenQueryVariables>;
export const RemoteChargePageChangedDocument = gql`
  subscription RemoteChargePageChanged($remoteChargeId: ID!) {
    remoteChargeChanged(remoteChargeId: $remoteChargeId) {
      remoteCharge {
        id
        ...RemoteChargePage_RemoteChargeFragment
      }
    }
  }
  ${RemoteChargePage_RemoteChargeFragmentDoc}
`;

/**
 * __useRemoteChargePageChangedSubscription__
 *
 * To run a query within a React component, call `useRemoteChargePageChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRemoteChargePageChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoteChargePageChangedSubscription({
 *   variables: {
 *      remoteChargeId: // value for 'remoteChargeId'
 *   },
 * });
 */
export function useRemoteChargePageChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    RemoteChargePageChangedSubscription,
    RemoteChargePageChangedSubscriptionVariables
  > &
    ({ skip?: boolean; variables: RemoteChargePageChangedSubscriptionVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<RemoteChargePageChangedSubscription, RemoteChargePageChangedSubscriptionVariables>(
    RemoteChargePageChangedDocument,
    options
  );
}
export type RemoteChargePageChangedSubscriptionHookResult = ReturnType<typeof useRemoteChargePageChangedSubscription>;
export type RemoteChargePageChangedSubscriptionResult = Apollo.SubscriptionResult<RemoteChargePageChangedSubscription>;
export const UnsubscribeEmailDocument = gql`
  mutation UnsubscribeEmail($input: UnsubscribeInput!) {
    unsubscribe(input: $input) {
      email
      errors
    }
  }
`;
export type UnsubscribeEmailMutationFn = Apollo.MutationFunction<
  UnsubscribeEmailMutation,
  UnsubscribeEmailMutationVariables
>;

/**
 * __useUnsubscribeEmailMutation__
 *
 * To run a mutation, you first call `useUnsubscribeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeEmailMutation, { data, loading, error }] = useUnsubscribeEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<UnsubscribeEmailMutation, UnsubscribeEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnsubscribeEmailMutation, UnsubscribeEmailMutationVariables>(
    UnsubscribeEmailDocument,
    options
  );
}
export type UnsubscribeEmailMutationHookResult = ReturnType<typeof useUnsubscribeEmailMutation>;
export type UnsubscribeEmailMutationResult = Apollo.MutationResult<UnsubscribeEmailMutation>;
export type UnsubscribeEmailMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeEmailMutation,
  UnsubscribeEmailMutationVariables
>;
export const ReferralProgramDocument = gql`
  query ReferralProgram($accountId: ID!) {
    me {
      id
      onboardingStatus {
        proType
      }
      account(id: $accountId) {
        id
        pro
        accountUser {
          id
          referralProgram {
            ...ReferralProgramFragment
          }
          referralRewards {
            ...ReferralRewardFragment
          }
        }
      }
    }
  }
  ${ReferralProgramFragmentDoc}
  ${ReferralRewardFragmentDoc}
`;

/**
 * __useReferralProgramQuery__
 *
 * To run a query within a React component, call `useReferralProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralProgramQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useReferralProgramQuery(
  baseOptions: Apollo.QueryHookOptions<ReferralProgramQuery, ReferralProgramQueryVariables> &
    ({ skip?: boolean; variables: ReferralProgramQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferralProgramQuery, ReferralProgramQueryVariables>(ReferralProgramDocument, options);
}
export function useReferralProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReferralProgramQuery, ReferralProgramQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferralProgramQuery, ReferralProgramQueryVariables>(ReferralProgramDocument, options);
}
export function useReferralProgramSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ReferralProgramQuery, ReferralProgramQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReferralProgramQuery, ReferralProgramQueryVariables>(ReferralProgramDocument, options);
}
export type ReferralProgramQueryHookResult = ReturnType<typeof useReferralProgramQuery>;
export type ReferralProgramLazyQueryHookResult = ReturnType<typeof useReferralProgramLazyQuery>;
export type ReferralProgramSuspenseQueryHookResult = ReturnType<typeof useReferralProgramSuspenseQuery>;
export type ReferralProgramQueryResult = Apollo.QueryResult<ReferralProgramQuery, ReferralProgramQueryVariables>;
export const RecapMetricsDocument = gql`
  query RecapMetrics($accountId: ID!) {
    me {
      id
      account(id: $accountId) {
        id
        accountUser {
          id
          firstname
          recapMetrics {
            id
            ...RecapMetricFragment
          }
        }
      }
    }
  }
  ${RecapMetricFragmentDoc}
`;

/**
 * __useRecapMetricsQuery__
 *
 * To run a query within a React component, call `useRecapMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecapMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecapMetricsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRecapMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<RecapMetricsQuery, RecapMetricsQueryVariables> &
    ({ skip?: boolean; variables: RecapMetricsQueryVariables } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecapMetricsQuery, RecapMetricsQueryVariables>(RecapMetricsDocument, options);
}
export function useRecapMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecapMetricsQuery, RecapMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecapMetricsQuery, RecapMetricsQueryVariables>(RecapMetricsDocument, options);
}
export function useRecapMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RecapMetricsQuery, RecapMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RecapMetricsQuery, RecapMetricsQueryVariables>(RecapMetricsDocument, options);
}
export type RecapMetricsQueryHookResult = ReturnType<typeof useRecapMetricsQuery>;
export type RecapMetricsLazyQueryHookResult = ReturnType<typeof useRecapMetricsLazyQuery>;
export type RecapMetricsSuspenseQueryHookResult = ReturnType<typeof useRecapMetricsSuspenseQuery>;
export type RecapMetricsQueryResult = Apollo.QueryResult<RecapMetricsQuery, RecapMetricsQueryVariables>;
