import { FieldReadFunction, Reference } from "@apollo/client";

import { ProAccountType } from "..";

import { TypedTypePolicies } from "./apollo-helpers";

const readFunctionToReferenceByTypeAndId = (__typename: string): FieldReadFunction<unknown, unknown> => {
  // type-coverage:ignore-next-line
  return (_, { args, toReference }) => toReference({ __typename, id: args?.id });
};

const typePolicies: TypedTypePolicies = {
  Account: {
    fields: {
      charges: {
        keyArgs: ["id"],
        merge(existing, incoming) {
          return {
            ...incoming,
            nodes: incoming.nodes ?? [],
            pageInfo: incoming.pageInfo,
          };
        },
      },
    },
  },
  Query: {
    fields: {
      remoteCharge: readFunctionToReferenceByTypeAndId("RemoteCharge"),
      station: readFunctionToReferenceByTypeAndId("Station"),
    },
  },
  RemoteCharge: {
    fields: {
      step: {
        merge: false, // We have no id on those types. Default merge behavior, but silence the warning.
      },
    },
  },
  Station: {
    fields: {
      availableSpotsCount: (_, { readField }) => {
        const spotRefs: readonly Reference[] | undefined = readField("spots");
        if (!spotRefs) return 0;

        return spotRefs.filter((spotRef) => {
          const publicAvailability = readField("publicAvailability", spotRef);

          return publicAvailability === "AVAILABLE";
        }).length;
      },

      publicStatus: (_, { readField }) => {
        const comingSoonLabel = readField<string | null>("comingSoonLabel");
        if (comingSoonLabel) {
          return "COMING_SOON";
        }

        const spotRefs: readonly Reference[] | undefined = readField("spots");
        if (!spotRefs) return "UNKNOWN";
        if (spotRefs.length === 0) return "OUT_OF_SERVICE";

        const hasAvailabilities = spotRefs.some((spotRef) => {
          const publicAvailability = readField("publicAvailability", spotRef);

          return publicAvailability === "AVAILABLE";
        });
        if (hasAvailabilities) return "AVAILABLE";

        const allUnavailable = spotRefs.every((spotRef) => {
          const publicAvailability = readField("publicAvailability", spotRef);

          return publicAvailability === "UNAVAILABLE";
        });

        return allUnavailable ? "OUT_OF_SERVICE" : "UNAVAILABLE";
      },
    },
  },
  Subscription: {
    fields: {
      remoteChargeChanged: readFunctionToReferenceByTypeAndId("RemoteChargeChanged"),
      spotChanged: readFunctionToReferenceByTypeAndId("SpotChanged"),
    },
  },
  User: {
    fields: {
      account: readFunctionToReferenceByTypeAndId("Account"),
      invitation: readFunctionToReferenceByTypeAndId("Invitation"),
      onboardingStatus: (data) => {
        const proType = data?.proType as ProAccountType;
        const proTypeLabel =
          proType === ProAccountType.Smb ? "fleet" : proType === ProAccountType.Independant ? "independant" : undefined;
        return { ...data, proTypeLabel };
      },
    },
  },
};

export default typePolicies;
