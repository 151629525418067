import { NotificationCategoryEnum } from "operations";

export const classNamesByTypeAndLevel = {
  [NotificationCategoryEnum.Confirmation]: {
    high: "bg-green-600 text-white",
    medium: "text-green-600 bg-white",
  },
  [NotificationCategoryEnum.Error]: {
    high: "bg-red-800 text-white",
    medium: "text-red-800 bg-white",
  },
  [NotificationCategoryEnum.Information]: {
    high: "bg-green-400 text-white",
    medium: "text-green-400 bg-white",
  },
  [NotificationCategoryEnum.Warning]: {
    high: "bg-orange-700 text-white",
    medium: "text-white text-orange-700 ",
  },
};
