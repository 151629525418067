import Plugs from "constants/Plugs";

import * as Factory from "factory.ts";
import { ConnectorPlug } from "operations";

import { remoteChargeSpotFactory } from "./spotFactory";

export const remoteChargeConnectorFactory = Factory.Sync.makeFactory<{
  __typename?: "Connector";
  id: string;
  plug: ConnectorPlug;
  spot: { __typename?: "Spot"; publicLabel: string };
}>({
  __typename: "Connector",
  id: "fake-connector-id",
  plug: Plugs[0],
  spot: remoteChargeSpotFactory.build(),
});
