import { NotificationCategoryEnum } from "operations";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type NotificationType = "confirmation" | "error" | "warning" | "information";

export type NotificationLevel = "high" | "medium";

export type NotificationFrom = "top" | "bottom";

export type Notification = {
  actionLabel?: string;
  autoHide?: boolean;
  badge?: boolean;
  duration?: number;
  from?: NotificationFrom;
  level?: NotificationLevel;
  loading?: boolean;
  message?: string;
  onActionClick?: () => void;
  onNotificationHidden?: () => void;
  title?: string | undefined;
  type?: NotificationCategoryEnum;
};

interface NotificationState {
  notification: Notification | undefined;
  resetNotification: () => void;
  setNotification: (notification: Notification) => void;
}

const defaultNotification: Notification = {
  autoHide: true,
  badge: false,
  duration: 5000,
  from: "top",
  level: "high",
  loading: false,
  type: NotificationCategoryEnum.Confirmation,
};

export const useNotification = create<NotificationState>()(
  devtools((set) => ({
    notification: undefined,
    resetNotification: () => set(() => ({ notification: undefined })),
    setNotification: (notification) => set(() => ({ notification: { ...defaultNotification, ...notification } })),
  }))
);
