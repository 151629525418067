import { SupportMethodEnum } from "operations";

const supportChildren = [
  {
    __typename: "SupportMethod" as const,
    deeplink: "/chat",
    label: "Chat",
    subLabel: "lundi - vendredi de 8h à 20h",
    type: SupportMethodEnum.Chat,
  },
  {
    __typename: "SupportMethod" as const,
    deeplink: "https://wa.me/33186659999",
    label: "WhatsApp",
    subLabel: "lundi - vendredi de 8h à 20h",
    type: SupportMethodEnum.Whatsapp,
  },
  {
    __typename: "SupportMethod" as const,
    deeplink: "mailto:help@go-electra.com",
    label: "E-mail",
    subLabel: "réponse en 24h à 48h",
    type: SupportMethodEnum.Mail,
  },
  {
    __typename: "SupportMethod" as const,
    deeplink: "tel:+33186659999",
    label: "Téléphone",
    subLabel: "01 86 65 99 99 - 7j/7 24h/24",
    type: SupportMethodEnum.Phone,
  },
];

const helpChildren = [
  {
    __typename: "SupportMethod" as const,
    deeplink: "https://intercom.help/go-electra/fr",
    label: "Questions fréquentes",
    type: SupportMethodEnum.Faq,
  },
];

export const supportSectionsFactory = [
  {
    __typename: "SupportSection" as const,
    children: supportChildren,
    label: "Contactez notre équipe support",
  },
  {
    __typename: "SupportSection" as const,
    children: helpChildren,
    label: "Consultez notre Centre d'Aide",
  },
];
