import { useMediaQuery } from "@react-hook/media-query";
import { LINK_TO_FACEBOOK, LINK_TO_INSTAGRAM, LINK_TO_LINKEDIN, LINK_TO_TWITTER } from "@webapps/constants";
import classNames from "classnames";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import FacebookIcon from "../../../images/icons/ri/facebook.svg?react";
import InstagramIcon from "../../../images/icons/ri/instagram.svg?react";
import LinkedinIcon from "../../../images/icons/ri/linkedin.svg?react";
import TwitterIcon from "../../../images/icons/ri/twitter.svg?react";

interface Props {
  lightMode?: boolean | undefined;
}

const Footer: FunctionComponent<Props> = ({ lightMode = false }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const mdScreen = useMediaQuery("only screen and (min-width: 768px)");
  const display = mdScreen || location.pathname === "/";
  const socialMediaPlatforms = [
    {
      icon: <InstagramIcon className={classNames("h-6 w-6", lightMode ? "text-neutral-700" : "text-white")} />,
      link: LINK_TO_INSTAGRAM,
      titleKey: "instagram",
    },
    {
      icon: <LinkedinIcon className={classNames("h-6 w-6", lightMode ? "text-neutral-700" : "text-white")} />,
      link: LINK_TO_LINKEDIN,
      titleKey: "linkedIn",
    },
    {
      icon: <FacebookIcon className={classNames("h-6 w-6", lightMode ? "text-neutral-700" : "text-white")} />,
      link: LINK_TO_FACEBOOK,
      titleKey: "facebook",
    },
    {
      icon: <TwitterIcon className={classNames("h-6 w-6", lightMode ? "text-neutral-700" : "text-white")} />,
      link: LINK_TO_TWITTER,
      titleKey: "twitter",
    },
  ];
  return display ? (
    <footer
      className={classNames(
        "md:h-footer text-neutra z-30 flex w-full flex-col-reverse items-center justify-around gap-4 py-6 sm:flex-row sm:justify-between sm:px-14",
        lightMode ? "bg-transparent text-neutral-700" : "bg-neutral-700 text-white"
      )}
    >
      <p className={"text-medium font-normal"}>{t("footer.text", { ns: "common", year: new Date().getFullYear() })}</p>
      <div className="flex gap-1.5">
        {socialMediaPlatforms.map(({ icon, link, titleKey }) => (
          <a
            href={link}
            key={titleKey}
            target="_blank"
            title={t(`footer.socialLinks.${titleKey}`, { ns: "common" })}
            rel="noreferrer"
          >
            {icon}
          </a>
        ))}
      </div>
    </footer>
  ) : null;
};

export default Footer;
