import { OperationTypeNode } from "graphql";
import { HttpResponse, http } from "msw";
import {
  ConfirmInstantChargeIntentMutation,
  ConfirmInstantChargeIntentMutationVariables,
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables,
  InquireInstantChargePaymentIntentMutation,
  InquireInstantChargePaymentIntentMutationVariables,
  PaymentMethodPageQuery,
  PaymentMethodPageQueryVariables,
} from "operations";
import { getQueryErrorHandler, getQueryLoadingHandler, handleAuthenticatedMockedOperation } from "utils";

import { paymentMethodAnonymousSessionFactory } from "../../mocks/factories/anonymousSessionFactory";
import { paymentMethodPageFactory } from "../../mocks/factories/paymentMethodFactory";
import {
  checkoutProcessoutApiPath,
  processOutCardTokenizeFactory,
  processOutCardsPath,
  processOutCustomersPath,
  processOutInvoicesPath,
  processoutCardsFactory,
  processoutCustomersFactory,
  processoutInvoicesFactory,
  sandboxApiPath,
} from "../../mocks/factories/processoutFactory";
import { remoteChargeFactoryActionStep } from "../../mocks/factories/remoteChargeFactory";

const paymentMethod = paymentMethodPageFactory.build();
const actionStepRemoteCharge = remoteChargeFactoryActionStep.build();

const mutationHandlers = [
  handleAuthenticatedMockedOperation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(
    "CreatePaymentMethod",
    {
      createPaymentMethod: { __typename: "CreatePaymentMethodPayload", errors: [], paymentMethod },
    },
    OperationTypeNode.MUTATION
  ),
  handleAuthenticatedMockedOperation<ConfirmInstantChargeIntentMutation, ConfirmInstantChargeIntentMutationVariables>(
    "ConfirmInstantChargeIntent",
    {
      confirmInstantChargeIntent: {
        __typename: "ConfirmInstantChargeIntentPayload",
        errors: [],
        remoteCharge: actionStepRemoteCharge,
      },
    },
    OperationTypeNode.MUTATION
  ),
  handleAuthenticatedMockedOperation<
    InquireInstantChargePaymentIntentMutation,
    InquireInstantChargePaymentIntentMutationVariables
  >(
    "InquireInstantChargePaymentIntent",
    {
      inquireInstantChargePaymentIntent: {
        __typename: "InquireInstantChargePaymentIntentPayload",
        errors: [],
        needed: false,
      },
    },
    OperationTypeNode.MUTATION
  ),
];

const paymentHandlers = [
  http.post(processOutCardsPath, () => {
    return HttpResponse.json({
      data: processoutCardsFactory.build(),
    });
  }),
  http.get(sandboxApiPath, () => {
    return new HttpResponse(null, { status: 200 });
  }),
  http.get(checkoutProcessoutApiPath, () => {
    return new HttpResponse(null, { status: 200 });
  }),
  http.put(processOutCustomersPath, () => {
    return HttpResponse.json({
      data: processoutCustomersFactory.build(),
    });
  }),
  http.put(
    processOutCustomersPath,
    () => {
      return HttpResponse.json({
        data: processOutCardTokenizeFactory.build(),
      });
    },
    { once: true }
  ),
  http.post(processOutInvoicesPath, () => {
    return HttpResponse.json({
      data: processoutInvoicesFactory.build(),
    });
  }),
];

const base = [
  ...mutationHandlers,
  ...paymentHandlers,
  handleAuthenticatedMockedOperation<PaymentMethodPageQuery, PaymentMethodPageQueryVariables>(
    "PaymentMethodPage",

    {
      anonymousSession: paymentMethodAnonymousSessionFactory.build(),
    }
  ),
];

const paymentMethodPageHandlers = {
  base,
  error: [getQueryErrorHandler("PaymentMethodPage")],
  loading: [getQueryLoadingHandler("PaymentMethodPage")],
  resumeSessionWithSessionStorage: base,
  resumeSessionWithoutSessionStorage: base,
};

export default paymentMethodPageHandlers;
