import { FunctionComponent, ReactNode } from "react";

import FieldError from "../../atoms/FieldError";
import FieldLabel from "../../atoms/FieldLabel";

interface FieldWrapperProps {
  charactersCount?: number | undefined;
  charactersMaxCount?: number | undefined;
  className?: string;
  disabled?: boolean;
  label?: ReactNode | string;
  name: string;
  variant?: "small" | "medium";
}

export { FieldWrapperProps };

interface Props extends FieldWrapperProps {
  children: ReactNode;
}

const FieldWrapper: FunctionComponent<Props> = ({
  charactersCount,
  charactersMaxCount,
  children,
  className,
  disabled = false,
  label,
  name,
  variant = "medium",
}) => {
  return (
    <div className={className}>
      {label ? (
        <FieldLabel
          label={label}
          disabled={disabled}
          charactersCount={charactersCount}
          charactersMaxCount={charactersMaxCount}
        />
      ) : null}

      {children}

      <FieldError name={name} variant={variant} />
    </div>
  );
};

export default FieldWrapper;
