import { ComponentProps, forwardRef, useMemo } from "react";

type Props = Omit<ComponentProps<"video">, "className" | "autoPlay" | "loop" | "playsInline"> & {
  autoPlay?: boolean;
  containerClassName?: string;
  loop?: boolean;
  muted?: boolean;
  playsInline?: boolean;
  sources: { type?: string; url: string } | { type?: string; url: string }[];
  videoClassName?: string;
};
const Video = forwardRef<HTMLVideoElement, Props>(
  (
    {
      sources,
      autoPlay = true,
      loop = true,
      muted = true,
      playsInline = true,
      containerClassName,
      videoClassName,
      ...rest
    },
    ref
  ) => {
    const children = useMemo(() => {
      if (Array.isArray(sources)) {
        return sources.map((source, index) => <source src={source.url} type={source.type} key={index}></source>);
      }
      return <source src={sources.url} type={sources.type}></source>;
    }, [sources]);
    return (
      <div className={containerClassName}>
        <video
          ref={ref}
          autoPlay={autoPlay}
          loop={loop}
          {...rest}
          muted={muted}
          playsInline={playsInline}
          className={videoClassName}
          key={Array.isArray(sources) ? sources[0].url : sources.url}
        >
          {children}
        </video>
      </div>
    );
  }
);

export default Video;
