import classNames from "classnames";
import { ChangeEventHandler, forwardRef, InputHTMLAttributes, MouseEventHandler, useCallback } from "react";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  children?: never;
  size?: "small" | "medium" | "large";
}

const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ checked = false, className, disabled = false, size = "medium", ...rest }, ref) => {
    return (
      <input
        ref={ref}
        checked={checked}
        className={classNames(
          "form-checkbox rounded-xs cursor-pointer select-none border-2 border-neutral-600 text-neutral-600 checked:border-0 checked:text-neutral-700 focus:shadow-none focus:ring-0 focus:ring-offset-0 disabled:cursor-auto disabled:border-neutral-100 disabled:text-neutral-600 checked:disabled:text-neutral-100",
          {
            "h-4 w-4": size === "small",
            "h-5 w-5": size === "medium",
            "w-5.5 h-5.5": size === "large",
          },
          className
        )}
        disabled={disabled}
        type="checkbox"
        {...rest}
      ></input>
    );
  }
);

export default Checkbox;

interface CheckboxActionProps extends Omit<Props, "onClick"> {
  onClick?: (value: boolean) => void;
}

export const CheckboxAction = forwardRef<HTMLInputElement, CheckboxActionProps>(
  ({ checked = false, disabled = false, onClick, ...rest }, ref) => {
    const handleClick: MouseEventHandler<HTMLInputElement> = useCallback((event) => {
      if (event) {
        // Stop propagation to avoid twice changes of value
        // (once from listitem and once from checkbox)
        event.stopPropagation();
      }
    }, []);

    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        if (onClick) onClick(event.target.checked);
      },
      [onClick]
    );

    return (
      <Checkbox
        ref={ref}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        onClick={handleClick}
        {...rest}
      />
    );
  }
);
