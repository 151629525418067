import i18n from "i18next";

import { DEFAULT_LANGUAGE, AppsSupportedLanguage } from "../../intl";

export const getCurrentLocale = (): string => {
  return (
    i18n.language ||
    // Lookup in localstorage when language switch will be implemented
    //(typeof window !== "undefined" && window.localStorage[LOCAL_STORAGE_KEY]) ||
    getLocaleFromLanguage(DEFAULT_LANGUAGE)
  );
};

export const getCurrentLanguage = () => getCurrentLocale().slice(0, 2) as AppsSupportedLanguage;

export const localesFromLanguage = {
  de: "de-DE",
  en: "en-GB",
  es: "es-ES",
  fr: "fr-FR",
  it: "it-IT",
  nl: "nl-NL",
};

export const dateFnsLocalesFromLanguage = {
  de: "de",
  en: "enUS",
  es: "es",
  fr: "fr",
  it: "it",
  nl: "nl",
};

export const getLocaleFromLanguage = (language: AppsSupportedLanguage) => localesFromLanguage[language];
